import React, { useContext } from "react";
import { ReportedPropsContext } from "../../../context/ReportedPropsContext";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoSearchSharp } from "react-icons/io5";
// import { reportedProperties } from "../../data";
import Calendar from "react-calendar";
import { IoMdRefresh } from "react-icons/io";
const Filters = () => {
  const {
    showDropDown,
    setShowDropDown,
    handlePropTypeFilter,
    selectedDateForFilter,
    handleSearchInput,
    handleSearch,
    handleDateChange,
    handleDateFilter,
    handleReset,
    selectedPropertyType,
  } = useContext(ReportedPropsContext);
  

  return (
    <div className="py-2">
      <div className="flex flex-col xl:flex-row relative gap-4 my-2 text-xs">
        <div className="flex items-center gap-4">
          <IoMdRefresh
            onClick={handleReset}
              className="refresh-icon text-3xl cursor-pointer bg-white rounded-md p-1 hover:text-[#02a7e4] transition-all"
          />
          <div
            onClick={() =>
              showDropDown === "DateDropDown"
                ? setShowDropDown("")
                : setShowDropDown("DateDropDown")
            }
            className="cursor-pointer  bg-[#FFFFFF] w-[170px] px-2 py-2 rounded-[8px] shadow-sm flex items-center justify-between"
          >
            {`Posted Date ${
              selectedDateForFilter !== null || undefined || "" ? " - " : ""
            } ${
              selectedDateForFilter !== null || undefined
                ? selectedDateForFilter
                : ""
            }`}
            <span className="text-lg">
              <IoMdArrowDropdown />
            </span>
          </div>
          <div
            className={`absolute ${
              showDropDown === "DateDropDown" ? "block" : "hidden"
            } left-11 top-10    w-[250px] z-10 bg-white rounded-[8px] shadow-md transition-all duration-500`}
          >
            <Calendar onChange={(e) => handleDateChange(e)} />
            <div className="flex justify-end items-center px-4 py-2 gap-4 text-[#02a7e4] font-medium">
              <button
                onClick={() => setShowDropDown("")}
                className="hover:font-bold"
              >
                Cancel
              </button>
              <button onClick={handleDateFilter} className="hover:font-bold">
                Ok
              </button>
            </div>
          </div>
          <div
            onClick={() =>
              showDropDown === "PropertyTypeDropDown"
                ? setShowDropDown("")
                : setShowDropDown("PropertyTypeDropDown")
            }
            className="cursor-pointer relative bg-[#FFFFFF] w-[150px] pl-6 pr-4 py-2 rounded-[8px] shadow-sm flex items-center justify-between"
          >
            {selectedPropertyType ? selectedPropertyType : "Property Type"}
            <span className="text-lg">
              <IoMdArrowDropdown />
            </span>
            <div
              className={`absolute ${
                showDropDown === "PropertyTypeDropDown"
                  ? "top-10 z-10"
                  : "-top-0 -z-10"
              }  left-0 flex flex-col w-full items-start justify-center px-[33px] bg-white rounded-[8px] shadow-md transition-all duration-500`}
            >
              <div
                onClick={() => handlePropTypeFilter("Residential")}
                className="cursor-pointer  hover:underline flex items-center gap-2 py-2"
              >
                Residential
              </div>
              <div
                onClick={() => handlePropTypeFilter("Commercial")}
                className="cursor-pointer  hover:underline flex items-center gap-2 py-2"
              >
                Commercial
              </div>
            </div>
          </div>

          {/* <div
            onClick={() =>
              showDropDown === "ReportStatusDropDown"
                ? setShowDropDown("")
                : setShowDropDown("ReportStatusDropDown")
            }
            className="cursor-pointer relative bg-[#FFFFFF] w-[150px] pl-6 pr-4 py-2 rounded-[8px] shadow-sm flex items-center justify-between"
          >
            Report Status{" "}
            <span className="text-lg">
              <IoMdArrowDropdown />
            </span>
            <div
              className={`absolute ${
                showDropDown === "ReportStatusDropDown"
                  ? "top-10 z-10"
                  : "-top-0 -z-10"
              }  left-0 flex flex-col items-start justify-center w-full px-[33px] bg-white rounded-[8px] shadow-md transition-all duration-500`}
            >
              <div
                onClick={() => handleReportStatusFilter("Pending")}
                className="cursor-pointer text-[#D28A29] hover:underline flex items-center gap-2 py-2"
              >
                Pending
              </div>
              <div
                onClick={() => handleReportStatusFilter("Reviewed")}
                className="cursor-pointer text-[#8BC83F] hover:underline flex items-center gap-2 py-2"
              >
                Reviewed
              </div>
            </div>
          </div> */}
        </div>
        <div className="bg-[#FFFFFF] w-fit rounded-[8px] shadow-sm flex items-center text-[#84919A]">
          <div className="text-xs w-[350px]  flex items-center gap-2 px-2 tracking-tighter">
            <span className="text-lg">
              <IoSearchSharp />
            </span>
            <input
              onChange={(e) => handleSearchInput(e)}
              className="p-2 w-full focus:outline-none"
              type="search"
              placeholder="Search by property ID, reporter name, property name"
            />
          </div>
          {/* <button
            onClick={handleSearch}
            className="px-4 py-1 mr-2 border-[1px] border-[#DDE2E4] rounded-[6px] text-[#252C32]"
          >
            Search
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Filters;
