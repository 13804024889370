import { Button } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AdContext } from "../../../context/AdContext";
import AWS from "aws-sdk";
import Resizer from "react-image-file-resizer";
import CircularProgress from "@mui/material/CircularProgress";
import uploadCloudImg from "../../../assets/Group.png";
import { useSelector } from "react-redux";
import { selectUserPermissions } from "../../../store/authSlice";
import axiosInstance from "../../../Interceptor/axiosInstance";

AWS.config.update({
  region: "us-east-1",
  accessKeyId: "AKIAXKPUZW5AH27B7EGM",
  secretAccessKey: "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",
});

const S3 = new AWS.S3();

const UploadAd = () => {
  const [showUpload, setShowUpload] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const [createAccess, setCreateAccess] = useState();
  const expectedPermission = "Ad Posting";
  const userRolePermission = useSelector(selectUserPermissions);

  useEffect(() => {
    const hasCreateAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.create === "true"
    );

    setCreateAccess(hasCreateAccess);
  }, [userRolePermission]);

  const {
    adList,
    setAdList,
    viewTab,
    currentAdUpload,
    setCurrentAdUpload,
    fetchData,
  } = useContext(AdContext);
  const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/i;

  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const uploadFileToS3 = async (bucketName, file) => {
    const fileName = file.name
      ? `images/${Date.now()}_${file.name}`
      : `images/${Date.now()}_default.png`; // Unique file name

    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };

    try {
      const uploadResponse = await S3.upload(params).promise();
      return uploadResponse.Location; // URL of the uploaded file
    } catch (error) {
      throw new Error(`Error uploading file to S3: ${error.message}`);
    }
  };

  useEffect(() => {
    if (
      currentAdUpload?.Title &&
      urlPattern.test(currentAdUpload?.link) &&
      currentAdUpload?.image
    ) {
      setShowUpload(true);
    } else {
      setShowUpload(false);
    }
  }, [currentAdUpload]);

  const resizeImage = (file, fileName) => {
    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        2000, // New width
        1200, // New height
        "JPEG", // Format
        90, // Quality
        0, // Rotation
        (uri) => {
          resolve({
            blob: uri,
            name: fileName,
          });
        },
        "blob"
      );
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const { blob: resizedImageBlob, name: fileName } = await resizeImage(
          file,
          file.name
        );

        const resizedFile = new File([resizedImageBlob], fileName, {
          type: resizedImageBlob.type,
        });
        setUploading(true);
        const fileUrl = await uploadFileToS3("vattara-img", resizedFile);
        setCurrentAdUpload((prev) => ({ ...prev, image: fileUrl }));
        setUploading(false);
      } catch (error) {
      } finally {
        e.target.value = null;
      }
    }
  };

  const handleTitleAndUrlChange = (e) => {
    const { name, value } = e.target;
    if (name === "link") {
      if (!urlPattern.test(value) && value.length > 0) {
        setError("Please enter a valid URL");
      } else {
        setError("");
      }
    }
    setCurrentAdUpload((prev) => ({ ...prev, [name]: value }));
  };

  const hanldeUpload = async () => {
    const exists = adList.find((ad) => ad.id === currentAdUpload.id);

    setAdList((prev) => [...prev, currentAdUpload]);

    const dataToSend = exists
      ? currentAdUpload
      : {
          image: currentAdUpload.image,
          link: currentAdUpload.link,
          Title: currentAdUpload.Title,
          list_ads:
            viewTab === "Property List View Ads"
              ? "Property List View Ads"
              : "Property Contact View Ads",
        };

    try {
      const response = await axiosInstance.post(
        "/api/ads/createAds",
        dataToSend
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const result = await response.json();
    } catch (error) {}

    setCurrentAdUpload({ title: "", url: "", image: "" });
    setShowUpload(false);
    fetchData(viewTab);
  };

  return (
    <div className="font-Roboto w-[60%] mt-4 flex items-start gap-10">
      <div className="w-[55%] relative flex items-center justify-start rounded-[12px]">
        <div className="bg-white rounded-lg p-4 text-sm w-full">
          <h4 className="font-bold">Upload Photo</h4>
          {currentAdUpload.image ? (
            <div className="mt-4 rounded-[4px] relative">
              <img
                className="object-cover w-full h-[170px] rounded-[4px]"
                src={currentAdUpload.image}
                alt=""
              />
              <button
                onClick={triggerFileInput}
                className="absolute bottom-5 right-5 text-2xl text-white bg-[#047857] px-[10px] rounded-[4px]"
              >
                +
              </button>
              {uploading && (
                <div className="absolute inset-0 rounded-[4px] flex items-center justify-center w-full h-full bg-black opacity-50">
                  {<CircularProgress />}
                </div>
              )}
            </div>
          ) : (
            <div className="relative mt-4 border-[1px] border-[#D0D5DD] border-dashed flex items-center justify-center w-full h-[170px] rounded-[4px]">
              <div className="flex flex-col items-center justify-center gap-2 px-2">
                <img src={uploadCloudImg} alt="upload" />

                {createAccess ? (
                  <>
                    <h5 className="text-[#404040] text-xs text-center">
                      Browse and chose the files you want to upload from your
                      computer
                    </h5>
                    <button
                      onClick={triggerFileInput}
                      className="text-2xl text-white bg-[#047857] px-[10px] rounded-[4px]"
                    >
                      +
                    </button>
                  </>
                ) : null}
              </div>
              {uploading && (
                <div className="absolute inset-0 rounded-[4px] flex items-center justify-center w-full h-full bg-black opacity-50">
                  {<CircularProgress />}
                </div>
              )}
            </div>
          )}
          <input
            onChange={(e) => handleImageChange(e)}
            ref={fileInputRef}
            type="file"
            accept="image/*"
            className="hidden"
          />
        </div>
      </div>
      <div className="w-[45%] flex flex-col gap-4">
        <div className="flex flex-col">
          <h4 className="font-Roboto font-medium focus:outline-none text-[#000000] py-2 text-sm">
            Title
          </h4>
          <input
            value={currentAdUpload.Title || ""}
            required
            onChange={(e) => handleTitleAndUrlChange(e)}
            type="text"
            placeholder="(ex. Casagrand New Year Offer)"
            name="Title"
            className="border-[1px] drop-shadow-sm border-[#D7D7D7] focus:outline-none text-[#2B2B2B] text-[13px] rounded-md shadow-sm px-2 py-1"
          />
        </div>
        <div className="flex flex-col">
          <h4 className="font-Roboto font-medium text-[#000000] py-2 text-[13px]">
            Link
          </h4>
          <input
            required
            value={currentAdUpload.link || ""}
            onChange={(e) => handleTitleAndUrlChange(e)}
            type="url"
            placeholder="https://639045120/ref=sr_1_9?dib=eyJ2h9.LCjf"
            name="link"
            className="border-[1px] drop-shadow-sm border-[#D7D7D7] focus:outline-none text-[#2B2B2B] text-[13px] rounded-md shadow-sm px-2 py-1"
          />
          <p
            className={`transition-opacity duration-300 text-red-500 text-xs mt-2 font-semibold ${
              error ? "opacity-100" : "opacity-0"
            }`}
          >
            {error}
          </p>
        </div>
        <div className="flex justify-end items-center gap-5 font-bold text-[13px] mt-4">
          <button
            onClick={() =>
              setCurrentAdUpload({ title: "", url: "", image: "" })
            }
            className="hover:underline"
          >
            Reset
          </button>
          <Button
            onClick={hanldeUpload}
            disabled={!showUpload}
            variant="contained"
            sx={{
              bgcolor: "#5E9618",
              ":hover": { bgcolor: "#5E9618" },
              borderRadius: "10px",
              padding: "6px 36px",
            }}
          >
            Post
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UploadAd;
