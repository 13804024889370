import React, { useContext, useEffect, useState } from "react";
import { UserReviewContext } from "../../../context/UserReviewContext";
import { Button } from "@mui/material";
import axiosInstance from "../../../Interceptor/axiosInstance";
import { useSelector } from "react-redux";
import { selectEmpId, selectUserPermissions } from "../../../store/authSlice";

const UserDetails = () => {
  const { currentUser, setCurrentUser, fetchData } =
    useContext(UserReviewContext);
  const empId = useSelector(selectEmpId);

  const [access, setAccess] = useState();

  const expectedPermission = "Property Management";
  const userRolePermission = useSelector(selectUserPermissions);
  useEffect(() => {
    const hasAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.edit === "true"
    );

    setAccess(hasAccess);
  }, [userRolePermission]);

  const handleReviewStatus = async (currentUser) => {
    try {
      const response = await axiosInstance.put(
        `/api/admin/update/review/status`,
        {
          review_status: "Reviewed",
          id: currentUser?.id,
          emp_id: empId,
        }
      );
      setCurrentUser(null);
    } catch (error) {
      console.error("Error updating review status:", error);
    }
  };

  return (
    <>
      {currentUser && (
        <div className="bg-[#E4E0EE] mt-[90px] rounded-[15px] mx-1 p-6 font-bold text-[13px]">
          <h3 className="border-b-[1px] border-[#404040] border-opacity-65 text-xs">
            User Details
          </h3>
          <div className="flex flex-col gap-2 mt-2">
            <div>
              Name : <span>{currentUser?.tbl_user_reg?.name}</span>
            </div>
            <div>
              Company Name :{" "}
              <span>
                {currentUser?.tbl_user_reg &&
                  currentUser?.tbl_user_reg?.company_name}
              </span>
            </div>
            <div>
              Mobile Number :{" "}
              <span>{currentUser?.tbl_user_reg?.mobile_no}</span>
            </div>
            <div>
              E-mail : <span>{currentUser?.tbl_user_reg?.email}</span>
            </div>
            <div>
              User Type : <span>{currentUser?.tbl_user_reg?.category}</span>
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-6">
            <h3 className="border-b-[1px] border-[#404040] border-opacity-65 text-xs">
              Employee Details
            </h3>
            <div>
              Emp Name :{" "}
              <span>{currentUser?.tbl_admin_register?.username}</span>
            </div>
            <div>
              Emp Id : <span>{currentUser?.tbl_admin_register?.emp_id}</span>
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-6">
            <h3 className="border-b-[1px] border-[#404040] border-opacity-65 text-xs">
              Review Status
            </h3>
            <div
              className={`${
                currentUser?.status === "Reviewed"
                  ? "text-[#23A55A]"
                  : "text-[#D28A29]"
              }`}
            >
              Status : <span>{currentUser?.status}</span>
            </div>
          </div>
          <div className="flex flex-col gap-8 mt-6">
            <div>
              <h4 className="border-b-[1px] border-b-[#404040] border-opacity-65 text-xs">
                Posted Date
              </h4>
              <p>{currentUser?.createdAt}</p>
            </div>
            <div>
              <h4 className="border-b-[1px] border-b-[#404040] border-opacity-65 text-xs">
                Ratings
              </h4>
              <p>{currentUser?.Rating}</p>
            </div>
            <div>
              <h4 className="border-b-[1px] border-b-[#404040] border-opacity-65 text-xs">
                Feedback
              </h4>
              <p>{currentUser?.describe_issuse}</p>
            </div>
          </div>
          {access ? (
            <Button
              size="small"
              sx={{
                borderRadius: "8px",
                marginTop: "20px",
                backgroundColor: "#23A55A",
                ":hover": {
                  backgroundColor: "#23A55A",
                },
                color: "#202020",
              }}
              variant="contained"
              disabled={currentUser?.status === "Reviewed"}
              onClick={() => {
                handleReviewStatus(currentUser);
              }}
              // className="bg-[#23A55A] text-xs font-medium px-4 py-2 rounded-[8px]"
            >
              Mark as Reviewed
            </Button>
          ) : null}
        </div>
      )}
    </>
  );
};

export default UserDetails;
