import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid,
  Divider,
  CardContent,
  Card,
  Alert,
} from "@mui/material";
import { FormContext, resetState } from "../../../context/formContext";
import { setSelectedOptions } from "../../../redux/actions";
import { resetForm } from "../../../redux/actions";
import axiosInstance from "../../../Interceptor/axiosInstance";
import { resetBasicDetails } from "../../../redux/formSlice";

const Review = ({
  generatedParagraph,
  selectedValues,
  textFieldValues,
  setTextFieldValues,
  images,
  customValues,
  selectedFloor,
  totalFloors,
  selectedAmenities,
  pricePerSqFt,
  builtUpArea,
  totalPrice,
  handleUnitChange,
  securitydeposit,
  taggedImages,
  setOpen,
}) => {
  const dispatch = useDispatch();

  const handleReset = () => {
    dispatch(resetForm());
  };

  const {
    showPreview,
    setShowPreview,
    resetState,
    resetShowDetails,
    plotFieldValue,
  } = useContext(FormContext);
  console.log(plotFieldValue);
  console.log(builtUpArea);

  const pickerResponse = useSelector(
    (state) => state.imageReducer.pickerResponse
  );

  const selectedOptions = useSelector((state) => state.form.selectedOptions);

  // Assuming coverImageIndex and selectedTags are defined somewhere in your component's state or props
  const selectedTags = []; // Replace with your logic to get selected tags

  // Find index of cover image
  const coverImageIndex = pickerResponse.findIndex(
    (image) => image.isCover === true
  );

  // Separate cover image from remaining images
  const coverImage =
    coverImageIndex !== -1 ? pickerResponse[coverImageIndex] : null;
  const remainingImages = pickerResponse.filter(
    (image, index) => index !== coverImageIndex
  );

  // Ensure selectedAmenities is an array before mapping over it
  const amenityItems = selectedAmenities
    ? Object.entries(selectedAmenities).map(([key, value]) => (
        <ListItem key={key}>
          <ListItemText
            primary={
              <div className="flex gap-10 flex-animation">
                <p style={{ minWidth: "150px" }}>{key}</p>
                <p>{value.join(", ")}</p>
              </div>
            }
          />
        </ListItem>
      ))
    : null;

  const defaultValues = {
    user_id: "74d77ccb-5d43-4008-8200-d5c7b64f074f",
    kbl_property: true,
  };

  const amenities = selectedAmenities?.AMENITIES ?? [];
  const furnishing = selectedAmenities?.["Furnishing"] ?? [];
  const suitablefor = selectedAmenities?.["Suitable For"] ?? [];

  const filterMergedValues = (obj) => {
    // Create an object for additional details
    const additionalDetails = {};

    // Define keys that should be moved to additional details
    const additionalKeys = [
      "Bathroom",
      "Balcony",
      "Servant Room",
      "Eligible for Loan",
      "Washroom",
      "Carpet Area",
      "Lifts",
      "Meeting Room",
      "min  number of seats",
      "max  number of seats",
      "Preferred Tenant Type",
      "Maintenance Charges",
      "Facing",
      "Parking",
      "property_description",
    ];

    // Filter and transform the object
    const transformKey = (key) => key.toLowerCase().replace(/ /g, "_");

    // Filter and transform the object
    const filtered = Object.entries(obj)
      .filter(
        ([key, value]) => value !== null && value !== undefined && value !== ""
      )
      .reduce((acc, [key, value]) => {
        if (additionalKeys.includes(key)) {
          additionalDetails[transformKey(key)] = value;
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});

    // Add the additional details object to the filtered result
    if (Object.keys(additionalDetails).length > 0) {
      filtered.additional_details = additionalDetails;
    }

    return filtered;
  };

  const comfilterMergedValues = (obj) => {
    // Create an object for additional details
    const additionalDetails = {};

    // Define keys that should be moved to additional details
    const additionalKeys = ["society_amenities"];

    // Filter and transform the object
    const transformKey = (key) => key.toLowerCase().replace(/ /g, "_");

    // Filter and transform the object
    const filtered = Object.entries(obj)
      .filter(
        ([key, value]) => value !== null && value !== undefined && value !== ""
      )
      .reduce((acc, [key, value]) => {
        if (additionalKeys.includes(key)) {
          additionalDetails[transformKey(key)] = value;
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});

    // Add the additional details object to the filtered result
    if (Object.keys(additionalDetails).length > 0) {
      filtered.additional_details = additionalDetails;
    }

    return filtered;
  };

  const mergedValues = {
    ...defaultValues,
    ...selectedValues,
    ...textFieldValues,
    ...(generatedParagraph && { property_description: generatedParagraph }),
    ...customValues,
    ...(amenities.length > 0 && { society_amenities: amenities.join(",") }),
    ...(furnishing.length > 0 && { flat_furnishing: furnishing.join(",") }),
    ...(suitablefor.length > 0 && { suitable_for: suitablefor.join(",") }),
    ...(totalFloors && { "Total Floors": totalFloors }),
    ...(selectedOptions?.propertyType === "Commercial" &&
      (selectedOptions?.subType !== "Plot / Land" ||
        selectedOptions?.subType !== "Industrial Space" ||
        selectedOptions?.subType !== "Warehouse") &&
      selectedFloor && { "select your floor": selectedFloor.join(",") }),
    ...(pricePerSqFt && { "Price per SqFt": pricePerSqFt }),
    ...(builtUpArea && { "Built Up Area": builtUpArea }),
    ...(totalPrice && { Price: "₹ " + totalPrice }),
    ...(selectedOptions.subType === "Plot / Land" && {
      plot_area: plotFieldValue,
    }),
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      // property_available_for: selectedOptions.transactionType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),

    ...(selectedValues["Construction Status"] === "Under Construction" && {
      "Construction Status":
        textFieldValues["Available Date"] || "Under Construction",
    }),
    ...(selectedValues["Possession Status"] === "Under Construction" && {
      "Possession Status":
        textFieldValues["Available From"] || "Under Construction",
    }),
    ...(selectedValues && { furnish_type: selectedValues["Flat furnishing"] }),
  };

  const filteredMergedValues = filterMergedValues(mergedValues);

  const comfilteredMergedValues = comfilterMergedValues(mergedValues);

  // Labels to be displayed
  const displayLabels = [
    "Building / Project Name  Optional ",
    "Carpet Area",
    "Property Condition",
    // "Available Date",
    "Construction Status",
    "Built Up Area",
    "Facing",
    "Parking",
    "Flat furnishing",
    "Total Floors",
    "select your floor",
    "Possession Status",
    "Plot Area",
    "Approval Status",
    "Patta Available",
    "Length",
    "Width",
  ];

  // Filter mergedValues based on displayLabels
  // const filteredValues = Object.entries(mergedValues).filter(([key]) =>
  //   displayLabels.includes(key)
  // );
// Check if property_subtype is Warehouse or Industrial Space
console.log("Selected Property Subtype:", selectedOptions.subType);

const shouldExcludeFloorSelection =
selectedOptions.subType === "Warehouse" ||
selectedOptions.subType === "Industrial Space";

// Filter mergedValues based on displayLabels and conditionally exclude "select your floor"
const filteredValues = Object.entries(mergedValues).filter(([key]) => {
  if (shouldExcludeFloorSelection && key === "select your floor") {
    return false; // Exclude "select your floor"
  }
  return displayLabels.includes(key);
});

console.log("Filtered Values:", filteredValues);





  const priceLabels = [
    "Price",
    "Price per SqFt ",
    "Negotiable",
    "Property available for",
    "Monthly Rent",
    "Lease Amount",
    "Available from",

    // "Available From",
    "Security Deposit",
    "Enter  Amount",
    "Property Description",
  ];

  const priceValues = Object.entries(mergedValues).filter(([key]) =>
    priceLabels.includes(key)
  );

  const Labels = [
    "Bathroom",
    "Balcony",
    "Servant Room",
    "Eligible for Loan",
    "BHK",
    "Washroom",
    "Lifts",
    "Meeting Room",
    "min  number of seats",
    "max  number of seats",
    "Preferred Tenant Type",
    "Maintenance Charges",
  ];

  const additional = Object.entries(mergedValues).filter(([key]) =>
    Labels.includes(key)
  );

  const createSelectObject = (selectedOptions) => {
    return {
      property_type: selectedOptions.propertyType,
      transaction_type: selectedOptions.transactionType,
      property_subtype: selectedOptions.subType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
      sublocality: selectedOptions.sublocality,
    };
  };

  const select = createSelectObject(selectedOptions);

  // Function to post data to the specified endpoint
  const postData = async (url, data) => {
    try {
      const response = await axiosInstance.post(url, data);

      setOpen(true);
      dispatch(setSelectedOptions(""));

      resetState("");

      // Optionally handle success state or notification
    } catch (error) {
      console.error("Error posting data:", error);
      // Optionally handle error state or notification
    }
  };

  const transformKeysToLowerCaseWithUnderscores = (data) => {
    return Object.keys(data).reduce((acc, key) => {
      const modifiedKey = key
        .toLowerCase()
        .replace(/\s{2,}/g, "__")
        .replace(/\s+/g, "_")
        .replace(/\//g, "_"); // Replace forward slash with underscore

      let modifiedValue = data[key];

      // if (Array.isArray(modifiedValue)) {
      //   if (typeof modifiedValue[0] === "object") {
      //     // Keep arrays of objects as they are
      //     modifiedValue = modifiedValue.map((item) => {
      //       return Object.keys(item).reduce((obj, itemKey) => {
      //         const itemModifiedKey = itemKey
      //           .toLowerCase()
      //           .replace(/\s{2,}/g, "__")
      //           .replace(/\s+/g, "_")
      //           .replace(/\//g, "_"); // Replace forward slash with underscore
      //         obj[itemModifiedKey] = item[itemKey];
      //         return obj;
      //       }, {});
      //     });
      //   } else {
      //     // Convert array to comma-separated string if it's not an array of objects
      //     modifiedValue = modifiedValue.join(",");
      //   }
      // } else if (typeof modifiedValue === "string") {
      //   // Remove double quotes from strings
      //   modifiedValue = modifiedValue.replace(/"/g, "");
      // }
      acc[modifiedKey] = modifiedValue;
      return acc;
    }, {});
  };
  const unitMatch = plotFieldValue.match(/\b(Sq\.ft|Cent|Acre)\b/i); // Match Sq.ft, cent, acre (case insensitive)
  const unit = unitMatch ? unitMatch[0] : null; // Get the unit or null
  const areaValue = plotFieldValue.replace(/\s*(Sq\.ft|Cent|Acre)$/i, ""); // Remove the unit from the value

  const Residentalsell = {
    ...defaultValues,
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),
    building___project_name__optional_:
      textFieldValues["Building / Project Name  Optional "],
    ...(builtUpArea && { built_up_area: builtUpArea }),

    negotiable: selectedValues["Negotiable"],
    additional_details: {
      balcony: selectedValues["Balcony"],
      bathroom: selectedValues["Bathroom"],

      eligible_for_loan: selectedValues["Eligible for Loan"],

      parking: selectedValues["Parking"],
      facing: selectedValues["Facing"],
      carpet_area: textFieldValues["Carpet Area"],
      ...(generatedParagraph && { property_description: generatedParagraph }),

      servant_room: selectedValues["Servant Room"],
    },
    bhk: selectedValues["BHK"],
    construction_status: selectedValues["Construction Status"],
    ...(selectedValues["Construction Status"] === "Under Construction" && {
      construction_status:
        textFieldValues["Available Date"] || "Under Construction",
    }),
    ...(selectedValues && { furnish_type: selectedValues["Flat furnishing"] }),
    ...(totalPrice && {
      price:
        "₹ " +
        new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 21 }).format(
          totalPrice
        ),
    }),
    plot_area_house: textFieldValues["Plot Area"],
    amenities: amenities.join(","),
    ...(amenities.length > 0 && { society_amenities: amenities.join(",") }),

    ...(furnishing.length > 0 && { flat_furnishing: furnishing.join(",") }),

    // ...(suitablefor.length > 0 && { suitable_for: suitablefor.join(",") }),
  };
  const ResidentalsellApartment = {
    ...defaultValues,
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),
    building___project_name__optional_:
      textFieldValues["Building / Project Name  Optional "],
    ...(builtUpArea && { built_up_area: builtUpArea }),

    negotiable: selectedValues["Negotiable"],
    additional_details: {
      balcony: selectedValues["Balcony"],
      bathroom: selectedValues["Bathroom"],

      eligible_for_loan: selectedValues["Eligible for Loan"],

      parking: selectedValues["Parking"],
      facing: selectedValues["Facing"],
      carpet_area: textFieldValues["Carpet Area"],
      ...(generatedParagraph && { property_description: generatedParagraph }),

      servant_room: selectedValues["Servant Room"],
    },
    bhk: selectedValues["BHK"],
    construction_status: selectedValues["Construction Status"],

    ...(selectedValues["Construction Status"] === "Under Construction" && {
      construction_status:
        textFieldValues["Available Date"] || "Under Construction",
    }),
    ...(selectedValues && { furnish_type: selectedValues["Flat furnishing"] }),
    ...(totalPrice && {
      price:
        "₹ " +
        new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 21 }).format(
          totalPrice
        ),
    }),
    amenities: amenities.join(","),
    ...(amenities.length > 0 && { society_amenities: amenities.join(",") }),

    ...(furnishing.length > 0 && { flat_furnishing: furnishing.join(",") }),

    // ...(suitablefor.length > 0 && { suitable_for: suitablefor.join(",") }),
  };
  const Residentalsellland = {
    ...defaultValues,
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),
    building___project_name__optional_:
      textFieldValues["Building / Project Name  Optional "],

    plot_area: areaValue.trim(), // Store the numeric value
    measure: unit || "",
    negotiable: selectedValues["Negotiable"],
    ...(totalPrice && {
      price:
        "₹ " +
        new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 21 }).format(
          totalPrice
        ),
    }),
    ...(generatedParagraph && { property_description: generatedParagraph }),

    patta_available: selectedValues["Patta Available"],
    width: textFieldValues["Width"],
    length: textFieldValues["Length"],
    property_status: selectedValues["Approval Status"],
    facing: selectedValues["Facing"],
  };
  const Residentalrent = {
    ...defaultValues,
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),
    building___project_name__optional_:
      textFieldValues["Building / Project Name  Optional "],
    ...(builtUpArea && { built_up_area: builtUpArea }),

    negotiable: selectedValues["Negotiable"],
    additional_details: {
      balcony: selectedValues["Balcony"],
      bathroom: selectedValues["Bathroom"],

      maintenance_charges: textFieldValues["Maintenance Charges"],
      preferred_tenant_type: selectedAmenities["Preferred Tenant Type"]
        ? selectedAmenities["Preferred Tenant Type"]
            .map((type) => type.toLowerCase())
            .join(",")
        : "",
      parking: selectedValues["Parking"],
      facing: selectedValues["Facing"],
      carpet_area: textFieldValues["Carpet Area"],
      ...(generatedParagraph && { property_description: generatedParagraph }),

      servant_room: selectedValues["Servant Room"],
    },
    available_from: textFieldValues["Available from"],

    bhk: selectedValues["BHK"],
    ...(selectedValues["Construction Status"] === "Under Construction" && {
      construction_status:
        textFieldValues["Available Date"] || "Under Construction",
    }),
    ...(selectedValues && { furnish_type: selectedValues["Flat furnishing"] }),
    monthly_rent: textFieldValues["Monthly Rent"],
    security_deposit:
      selectedValues["Security Deposit"] === "Custom"
        ? "₹ " +
          new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 21,
          }).format(customValues["Security Deposit"])
        : selectedValues["Security Deposit"],
    // selectedValues["Security Deposit"] === "Custom"
    // ? customValues["Security Deposit"]
    // : selectedValues["Security Deposit"],

    lease_amount: textFieldValues["Lease Amount"],

    // security_deposit: (selectedValues["Security Deposit"]),
    property_available_for: selectedValues["Property available for"],
    // amenities : amenities.join(","),
    ...(amenities.length > 0 && { society_amenities: amenities.join(",") }),

    ...(furnishing.length > 0 && { flat_furnishing: furnishing.join(",") }),

    // ...(suitablefor.length > 0 && { suitable_for: suitablefor.join(",") }),
  };
  const Commericialrentoffice = {
    ...defaultValues,
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),
    ...(builtUpArea && { built_up_area: builtUpArea }),

    building___project_name__optional_:
      textFieldValues["Building / Project Name  Optional "],
    additional_details: { amenities: amenities.join(",") },
    carpet_area: textFieldValues["Carpet Area"],

    negotiable: selectedValues["Negotiable"],
    security_deposit:
      selectedValues["Security Deposit"] === "Custom"
        ? "₹ " +
          new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 21,
          }).format(customValues["Security Deposit"])
        : selectedValues["Security Deposit"],
    // security_deposit: (selectedValues["Security Deposit"]),
    property_available_for: selectedValues["Property available for"],
    // ...(totalPrice && { Price: "₹ " + totalPrice }),
    min__number_of_seats: textFieldValues["min  number of seats"],
    max__number_of_seats: textFieldValues["max  number of seats"],
    // available_from: (textFieldValues["Available From"]),

    monthly_rent: textFieldValues["Monthly Rent"],

    ...(totalFloors && { total_floors: totalFloors }),
    // ...(selectedFloor && { select_your_floor : selectedFloor }),
    ...(selectedFloor && { select_your_floor: selectedFloor.join(",") }),

    lifts: selectedValues["Lifts"],
    parking: selectedValues["Parking"],
    facing: selectedValues["Facing"],
    property_condition: selectedValues["Property Condition"],
    lease_amount: textFieldValues["Lease Amount"],

    meeting_room: selectedValues["Meeting Room"],
    washroom: selectedValues["Washroom"],
    // suitable_for:(suitablefor["Suitable For"]),
    ...(suitablefor.length > 0 && { suitable_for: suitablefor.join(",") }),
    state: "TAMILNADU",

    ...(generatedParagraph && { property_description: generatedParagraph }),

    ...(selectedValues["Construction Status"] === "Under Construction" && {
      "Construction Status":
        textFieldValues["Available Date"] || "Under Construction",
    }),
    possession_status: selectedValues["Possession Status"],

    ...(selectedValues["Possession Status"] === "Under Construction" && {
      possession_status:
        textFieldValues["Available From"] || "Under Construction",
    }),
    // ...(selectedValues && {furnish_type:selectedValues["Flat furnishing"]})
  };
  const Commericialrentretailshopshowroom = {
    ...defaultValues,
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),
    ...(builtUpArea && { built_up_area: builtUpArea }),

    building___project_name__optional_:
      textFieldValues["Building / Project Name  Optional "],
    // additional_details: {
    //   amenities: Array.isArray(amenities) ? amenities.join(", ") : amenities,
    // },
    additional_details: { amenities: amenities.join(",") },
    carpet_area: textFieldValues["Carpet Area"],
    negotiable: selectedValues["Negotiable"],
    price: textFieldValues["Price"],
    lease_amount: textFieldValues["Lease Amount"],

    //     min_number_of_seats: (textFieldValues["min  number of seats"]),
    // max_number_of_seats: (textFieldValues["max  number of seats"]),
    monthly_rent: textFieldValues["Monthly Rent"],
    security_deposit:
      selectedValues["Security Deposit"] === "Custom"
        ? "₹ " +
          new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 21,
          }).format(customValues["Security Deposit"])
        : selectedValues["Security Deposit"],
    // security_deposit: (selectedValues["Security Deposit"]),
    property_available_for: selectedValues["Property available for"],
    ...(totalFloors && { total_floors: totalFloors }),
    // ...(selectedFloor && { select_your_floor : selectedFloor }),
    ...(selectedFloor && { select_your_floor: selectedFloor.join(",") }),

    lifts: selectedValues["Lifts"],
    parking: selectedValues["Parking"],
    facing: selectedValues["Facing"],
    meeting_room: selectedValues["Meeting Room"],
    washroom: selectedValues["Washroom"],
    available_from: textFieldValues["Available From"],

    // suitable_for:(suitablefor["Suitable For"]),
    ...(suitablefor.length > 0 && { suitable_for: suitablefor.join(",") }),
    state: "TAMILNADU",

    ...(generatedParagraph && { property_description: generatedParagraph }),

    ...(selectedValues["Construction Status"] === "Under Construction" && {
      "Construction Status":
        textFieldValues["Available Date"] || "Under Construction",
    }),
    possession_status: selectedValues["Possession Status"],

    ...(selectedValues["Possession Status"] === "Under Construction" && {
      possession_status:
        textFieldValues["Available From"] || "Under Construction",
    }),
    // ...(selectedValues && {furnish_type:selectedValues["Flat furnishing"]})
  };
  const Commericialrentwarehouse = {
    ...defaultValues,
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),
    ...(builtUpArea && { built_up_area: builtUpArea }),
    // built_up_area: (textFieldValues["Built Up Area"]),

    building___project_name__optional_:
      textFieldValues["Building / Project Name  Optional "],
    additional_details: { amenities: amenities.join(",") },
    carpet_area: textFieldValues["Carpet Area"],

    negotiable: selectedValues["Negotiable"],
    // ...(totalPrice && { Price: "₹ " + totalPrice }),
    //   min_number_of_seats: (textFieldValues["min  number of seats"]),
    // max_number_of_seats: (textFieldValues["max  number of seats"]),
    lease_amount: textFieldValues["Lease Amount"],
    monthly_rent: textFieldValues["Monthly Rent"],
    security_deposit:
      selectedValues["Security Deposit"] === "Custom"
        ? "₹ " +
          new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 21,
          }).format(customValues["Security Deposit"])
        : selectedValues["Security Deposit"],
    // security_deposit: (selectedValues["Security Deposit"]),
    property_available_for: selectedValues["Property available for"],
    ...(totalFloors && { total_floors: totalFloors }),
    ...(selectedFloor && { select_your_floor: selectedFloor }),
    lifts: selectedValues["Lifts"],
    parking: selectedValues["Parking"],
    facing: selectedValues["Facing"],
    property_condition: selectedValues["Property Condition"],
    // available_from: (textFieldValues["Available From"]),

    meeting_room: selectedValues["Meeting Room"],
    washroom: selectedValues["Washroom"],
    // suitable_for:(suitablefor["Suitable For"]),
    ...(suitablefor.length > 0 && { suitable_for: suitablefor.join(",") }),
    state: "TAMILNADU",

    ...(generatedParagraph && { property_description: generatedParagraph }),

    ...(selectedValues["Construction Status"] === "Under Construction" && {
      "Construction Status":
        textFieldValues["Available Date"] || "Under Construction",
    }),
    possession_status: selectedValues["Possession Status"],

    ...(selectedValues["Possession Status"] === "Under Construction" && {
      possession_status:
        textFieldValues["Available From"] || "Under Construction",
    }),
    // ...(selectedValues && {furnish_type:selectedValues["Flat furnishing"]})
  };
  const Commericialrentindspace = {
    ...defaultValues,
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),

    building___project_name__optional_:
      textFieldValues["Building / Project Name  Optional "],
    additional_details: { amenities: amenities.join(",") },

    //     plot_area: areaValue.trim(),  // Store the numeric value
    //     measure: unit || "",
    // negotiable: (selectedValues["Negotiable"]),
    // ...(totalPrice && { Price: "₹ " + totalPrice }),
    plot_area: textFieldValues["Plot Area"],

    facing: selectedValues["Facing"],
    lease_amount: textFieldValues["Lease Amount"],
    monthly_rent: textFieldValues["Monthly Rent"],

    security_deposit:
      selectedValues["Security Deposit"] === "Custom"
        ? "₹ " +
          new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 21,
          }).format(customValues["Security Deposit"])
        : selectedValues["Security Deposit"],
    // security_deposit: (selectedValues["Security Deposit"]),
    property_available_for: selectedValues["Property available for"],
    // suitable_for:(suitablefor["Suitable For"]),
    state: "TAMILNADU",
    negotiable: selectedValues["Negotiable"],
    available_from: textFieldValues["Available From"],

    ...(generatedParagraph && { property_description: generatedParagraph }),
    possession_status: selectedValues["Possession Status"],

    // ...(selectedValues["Construction Status"] === "Under Construction" && {
    // "Construction Status": textFieldValues["Available Date"] || "Under Construction",
    // }),
    ...(selectedValues["Possession Status"] === "Under Construction" && {
      possession_status:
        textFieldValues["Available From"] || "Under Construction",
    }),
    // ...(selectedValues && {furnish_type:selectedValues["Flat furnishing"]})
  };
  const Commericialsellretailshopshowroom = {
    ...defaultValues,
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),
    ...(builtUpArea && { built_up_area: builtUpArea }),

    building___project_name__optional_:
      textFieldValues["Building / Project Name  Optional "],
    // additional_details: {
    //   amenities: Array.isArray(amenities) ? amenities.join(", ") : amenities,
    // },
    additional_details: { amenities: amenities.join(",") },
    carpet_area: textFieldValues["Carpet Area"],
    negotiable: selectedValues["Negotiable"],
    price: textFieldValues["Price"],
    //     min_number_of_seats: (textFieldValues["min  number of seats"]),
    // max_number_of_seats: (textFieldValues["max  number of seats"]),

    ...(totalFloors && { total_floors: totalFloors }),
    // ...(selectedFloor && { select_your_floor : selectedFloor }),
    ...(selectedFloor && { select_your_floor: selectedFloor.join(",") }),
    lifts: selectedValues["Lifts"],
    parking: selectedValues["Parking"],
    facing: selectedValues["Facing"],
    meeting_room: selectedValues["Meeting Room"],
    washroom: selectedValues["Washroom"],
    // suitable_for:(suitablefor["Suitable For"]),
    ...(suitablefor.length > 0 && { suitable_for: suitablefor.join(",") }),
    state: "TAMILNADU",
    ...(totalPrice && {
      price:
        "₹ " +
        new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 21 }).format(
          totalPrice
        ),
    }),
    ...(generatedParagraph && { property_description: generatedParagraph }),

    ...(selectedValues["Construction Status"] === "Under Construction" && {
      "Construction Status":
        textFieldValues["Available Date"] || "Under Construction",
    }),
    possession_status: selectedValues["Possession Status"],

    ...(selectedValues["Possession Status"] === "Under Construction" && {
      possession_status:
        textFieldValues["Available From"] || "Under Construction",
    }),
    ...(selectedValues && { furnish_type: selectedValues["Flat furnishing"] }),
  };
  // Merging all objects into one
  const Commericialselloffice = {
    ...defaultValues,
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),
    ...(builtUpArea && { built_up_area: builtUpArea }),
    // built_up_area: (textFieldValues["Built Up Area"]),

    building___project_name__optional_:
      textFieldValues["Building / Project Name  Optional "],
    additional_details: { amenities: amenities.join(",") },
    carpet_area: textFieldValues["Carpet Area"],

    negotiable: selectedValues["Negotiable"],
    ...(totalPrice && { Price: "₹ " + totalPrice }),
    min_number_of_seats: textFieldValues["min  number of seats"],
    max_number_of_seats: textFieldValues["max  number of seats"],

    ...(totalPrice && {
      price:
        "₹ " +
        new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 21 }).format(
          totalPrice
        ),
    }),
    ...(totalFloors && { total_floors: totalFloors }),

    ...(selectedFloor && { select_your_floor: selectedFloor.join(",") }),
    lifts: selectedValues["Lifts"],
    parking: selectedValues["Parking"],
    facing: selectedValues["Facing"],
    property_condition: selectedValues["Property Condition"],

    meeting_room: selectedValues["Meeting Room"],
    washroom: selectedValues["Washroom"],
    // suitable_for:(suitablefor["Suitable For"]),
    ...(suitablefor.length > 0 && { suitable_for: suitablefor.join(",") }),
    state: "TAMILNADU",

    ...(generatedParagraph && { property_description: generatedParagraph }),

    ...(selectedValues["Construction Status"] === "Under Construction" && {
      "Construction Status":
        textFieldValues["Available Date"] || "Under Construction",
    }),
    possession_status: selectedValues["Possession Status"],

    ...(selectedValues["Possession Status"] === "Under Construction" && {
      possession_status:
        textFieldValues["Available From"] || "Under Construction",
    }),
    // ...(selectedValues && {furnish_type:selectedValues["Flat furnishing"]})
  };
  const Commericialsellwarehouse = {
    ...defaultValues,
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),
    ...(builtUpArea && { built_up_area: builtUpArea }),
    // built_up_area: (textFieldValues["Built Up Area"]),

    building___project_name__optional_:
      textFieldValues["Building / Project Name  Optional "],
    additional_details: { amenities: amenities.join(",") },
    carpet_area: textFieldValues["Carpet Area"],

    negotiable: selectedValues["Negotiable"],
    ...(totalPrice && {
      price:
        "₹ " +
        new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 21 }).format(
          totalPrice
        ),
    }),

    ...(totalFloors && { total_floors: totalFloors }),
    ...(selectedFloor && { select_your_floor: selectedFloor }),
    lifts: selectedValues["Lifts"],
    parking: selectedValues["Parking"],
    facing: selectedValues["Facing"],
    property_condition: selectedValues["Property Condition"],

    meeting_room: selectedValues["Meeting Room"],
    washroom: selectedValues["Washroom"],
    // suitable_for:(suitablefor["Suitable For"]),
    ...(suitablefor.length > 0 && { suitable_for: suitablefor.join(",") }),
    state: "TAMILNADU",

    ...(generatedParagraph && { property_description: generatedParagraph }),

    ...(selectedValues["Construction Status"] === "Under Construction" && {
      "Construction Status":
        textFieldValues["Available Date"] || "Under Construction",
    }),
    possession_status: selectedValues["Possession Status"],

    ...(selectedValues["Possession Status"] === "Under Construction" && {
      possession_status:
        textFieldValues["Available From"] || "Under Construction",
    }),
    // ...(selectedValues && {furnish_type:selectedValues["Flat furnishing"]})
  };

  const Commericialsellindspace = {
    ...defaultValues,
    ...(selectedOptions && {
      property_subtype: selectedOptions.subType,
      property_type: selectedOptions.propertyType,
      city: selectedOptions.city,
      locality: selectedOptions.locality,
      landmark_street: selectedOptions.landmark,
      sublocality: selectedOptions.sublocality,
      latitude: selectedOptions.latitude,
      longitude: selectedOptions.longitude,
    }),
    images: pickerResponse.map((image) => ({
      id: image.id,
      isCover: image.isCover,
      isFirst: image.isFirst,
      isVisible: image.isVisible,
      name: image.name,
      uri: image.uri,
      type: image.type,
      filename: image.filename,
      ImageTag: image.ImageTag,
    })),

    building___project_name__optional_:
      textFieldValues["Building / Project Name  Optional "],
    additional_details: { amenities: amenities.join(",") },

    plot_area: areaValue.trim(), // Store the numeric value
    measure: unit || "",
    negotiable: selectedValues["Negotiable"],
    ...(totalPrice && {
      price:
        "₹ " +
        new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 21 }).format(
          totalPrice
        ),
    }),
    facing: selectedValues["Facing"],

    // suitable_for:(suitablefor["Suitable For"]),
    state: "TAMILNADU",

    ...(generatedParagraph && { property_description: generatedParagraph }),
    possession_status: selectedValues["Possession Status"],

    // ...(selectedValues["Construction Status"] === "Under Construction" && {
    // "Construction Status": textFieldValues["Available Date"] || "Under Construction",
    // }),
    ...(selectedValues["Possession Status"] === "Under Construction" && {
      possession_status:
        textFieldValues["Available From"] || "Under Construction",
    }),
    // ...(selectedValues && {furnish_type:selectedValues["Flat furnishing"]})
  };

  const handlePostData = () => {
    const { propertyType, transactionType, subType } = selectedOptions;

    const fieldsToRemove = ["Available Date", "Available From"];

    // Remove fields from filteredMergedValues
    const filterOutFields = (data) => {
      return Object.keys(data).reduce((acc, key) => {
        if (!fieldsToRemove.includes(key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});
    };

    const transformedValues = transformKeysToLowerCaseWithUnderscores(
      filterOutFields(filteredMergedValues)
    );
    const transformValues = transformKeysToLowerCaseWithUnderscores(
      filterOutFields(comfilteredMergedValues)
    );
    const coverImage = pickerResponse.find((img) => img?.isCover === true);
    if (pickerResponse?.length > 0) {
      if (!coverImage) {
        alert("Choose a cover image.");
        return;
      }
    }
    if (propertyType === "Residential") {
      if (transactionType === "Sell") {
        // Check if subType is one of the specified types for Residential Sell
        if (["Villa", "Independent House"].includes(subType)) {
          postData("/sell/resSellDetail", Residentalsell);
        } else if (subType === "Plot / Land") {
          postData("/sell/resSellDetail", Residentalsellland);
        } else if (subType === "Apartment") {
          postData("/sell/resSellDetail", ResidentalsellApartment);
        } else {
          console.error("Unsupported subtype for Residential Sell:", subType);
        }
      } else if (transactionType === "Rent") {
        // Check if subType is one of the specified types for Residential Rent/Lease
        if (
          ["Apartment", "Villa", "Independent House", "Plot / Land"].includes(
            subType
          )
        ) {
          postData("/rent/resRentDetail", Residentalrent);
        } else {
          console.error(
            "Unsupported subtype for Residential Rent/Lease:",
            subType
          );
        }
      } else {
        console.error(
          "Unknown transaction type for Residential:",
          transactionType
        );
      }
    } else if (propertyType === "Commercial") {
      if (transactionType === "Rent") {
        // Check if subType is one of the specified types for Commercial Rent/Lease
        switch (subType) {
          case "Office Space":
            postData("/rent/comOffRent", Commericialrentoffice);
            break;
          case "Retail Shop":
          case "Showroom":
            postData("/rent/comShopRent", Commericialrentretailshopshowroom);
            break;
          case "Warehouse":
            postData("/rent/comWareHouseRent", Commericialrentwarehouse);
            break;
          case "Industrial Space":
            postData("/rent/comlandRent", Commericialrentindspace);
            break;
          default:
            console.error(
              "Unsupported subtype for Commercial Rent/Lease:",
              subType
            );
        }
      } else if (transactionType === "Sell") {
        // Check if subType is one of the specified types for Commercial Sell
        switch (subType) {
          case "Office Space":
            postData("/sell/comOfficeSellPost", Commericialselloffice);
            break;
          case "Retail Shop":
          case "Showroom":
            postData(
              "/sell/comShopSellPost",
              Commericialsellretailshopshowroom
            );
            break;
          case "Warehouse":
            postData("/sell/comWarehouseSellPost", Commericialsellwarehouse);
            break;
          case "Industrial Space":
            postData("/sell/comLandSellPost", Commericialsellindspace);
            break;
          default:
            console.error("Unsupported subtype for Commercial Sell:", subType);
        }
      } else {
        console.error(
          "Unknown transaction type for Commercial:",
          transactionType
        );
      }
    } else {
      console.error("Unknown property type:", propertyType);
    }
    resetState();
    handleReset();
    resetShowDetails();
    dispatch(resetBasicDetails());
  };

  return (
    <section className={`${showPreview ? "block" : "hidden"} mx-2 mt-12`}>
      <h2 className="text-[17px] font-semibold mb-2">Preview</h2>
      <div className="bg-white  p-2 rounded-[8px]">
        <Box className="relative">
          <table className="w-full border-separate text-[13px] table-fixed border-spacing-2">
            <thead>
              <tr>
                <td colSpan={2}>
                  <h2 className="text-[17px] my-2 font-bold text-[#02a7e4]">
                    Basic Details
                  </h2>
                </td>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <td colSpan={2}>
                  <div className="h-[1px] w-full bg-[#8E8E8E]"></div>
                </td>
              </tr>
            </tfoot>
            <tbody className="leading-7  ">
              <tr>
                <td>Property Type</td>
                <td>{select.property_type}</td>
              </tr>
              <tr>
                <td>Looking to</td>
                <td>{select.transaction_type}</td>
              </tr>
              <tr>
                <td>Property Subtype</td>
                <td>{select.property_subtype}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{select.city}</td>
              </tr>
              <tr>
                <td>Locality</td>
                <td>{select.locality}</td>
              </tr>
              <tr>
                <td>Landmark</td>
                <td>{select.landmark_street}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2}>
                  <div className="h-[1px] w-full bg-[#8E8E8E]"></div>
                </td>
              </tr>
            </tfoot>
            <thead>
              <tr>
                <td colSpan={2}>
                  <h2 className="text-[17px] my-2 font-bold text-[#02a7e4]">
                    Property Details
                  </h2>
                </td>
              </tr>
            </thead>
            <tbody className="">
              {filteredValues.map(([key, value]) => (
                <tr key={key}>
                  <td className="align-top py-2">{key}</td>
                  <td className="align-top py-2">
                    {value && typeof value === "object"
                      ? `${value.p || ""}: ${value.title || ""}`
                      : value}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2}>
                  <div className="h-[1px] w-full bg-[#8E8E8E]"></div>
                </td>
              </tr>
            </tfoot>
            <thead>
              <tr>
                <td colSpan={2}>
                  <h2 className="text-[17px] my-2 font-bold text-[#02a7e4]">
                    Price Details
                  </h2>
                </td>
              </tr>
            </thead>
            <tbody className="">
              {priceValues.map(([key, value]) => (
                <tr key={key}>
                  <td className="align-top py-2">{key}</td>
                  <td className="align-top py-2">
                    {value && typeof value === "object"
                      ? `${value.p || ""}: ${value.title || ""}`
                      : value}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2}>
                  <div className="h-[1px] w-full bg-[#8E8E8E]"></div>
                </td>
              </tr>
            </tfoot>
            <thead>
              <tr>
                <td colSpan={2}>
                  <h2 className="text-[17px] my-2 font-bold text-[#02a7e4]">
                    Additional Details
                  </h2>
                </td>
              </tr>
            </thead>
            <tbody className="">
              {additional.map(([key, value]) => (
                <tr key={key}>
                  <td className="align-top py-2">{key}</td>
                  <td className="align-top py-2">
                    {value && typeof value === "object"
                      ? `${value.p || ""}: ${value.title || ""}`
                      : value}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2}>
                  <div className="h-[1px] w-full bg-[#8E8E8E]"></div>
                </td>
              </tr>
            </tfoot>
            <thead>
              <tr>
                <td colSpan={2}>
                  <h2 className="text-[17px] my-2 font-bold text-[#02a7e4]">
                    Amenities Details
                  </h2>
                </td>
              </tr>
            </thead>
            <tbody className="">
              {selectedAmenities
                ? Object.entries(selectedAmenities).map(([key, value]) => (
                    <tr>
                      <td className="align-top py-2">{key}</td>
                      <td className="align-top py-2">{value.join(", ")}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>

          <div className="mx-2 pb-4 border-b-[1px] border-[#02a7e4]">
            <h2 className="text-[17px] my-2 font-bold text-[#02a7e4]">
              Property Description
            </h2>{" "}
            {generatedParagraph && (
              <p className=" text-[13px]">{generatedParagraph}</p>
            )}
          </div>

          <Box sx={{ mx: 1 }}>
            <h2 className="text-[17px] my-2 font-bold text-[#02a7e4]">
              Photos
            </h2>
            {coverImage && (
              <Box mt={2}>
                <h2 className="text-[17px] my-2 font-semibold ">Cover Image</h2>
                <img
                  src={coverImage.uri}
                  alt="Cover"
                  style={{
                    width: "90px",
                    height: "90px",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
                {selectedTags[coverImageIndex] && (
                  <h2 className="text-[17px] my-2 font-bold text-[#02a7e4] mt-1">
                    Tags: {selectedTags[coverImageIndex]}
                  </h2>
                )}
              </Box>
            )}

            {/* Grid to display remaining images */}
            <Grid container spacing={2} mt={2}>
              {remainingImages.map((image, index) => (
                <Grid item key={index} xs={4}>
                  <img
                    src={image.uri}
                    alt={`Image ${index}`}
                    style={{
                      width: "90px",
                      height: "90px",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                  {selectedTags[index] && (
                    <h2 className="text-[17px] my-2 font-bold text-[#02a7e4] mt-1">
                      Tags: {selectedTags[index]}
                    </h2>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box className="my-4 flex justify-end">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#7DAE3F",
                ":hover": { backgroundColor: "#7DAE3F" },
              }}
              onClick={handlePostData}
              className="rounded-md"
            >
              Post
            </Button>
          </Box>
        </Box>
      </div>
    </section>
  );
};

export default Review;
