import { useState } from "react";
import { IoClose } from "react-icons/io5";

const Details = ({ details }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <section className={`relative`}>
      {details?.map((section, i) => (
        <div key={i} className="border-b-[1px] border-b-[#8E8E8E] mb-2 py-2">
          <h2 className="text-[#02a7e4] text-sm font-semibold flex items-center justify-between">
            {section?.title}
          </h2>
          {section?.title === "Photos" && (
            <div className="mt-4 flex flex-wrap justify-start gap-2">
              {section.details.map((item, i) => (
                <div
                  className="w-[90px] text-center relative overflow-hidden"
                  key={i}
                >
                  <div
                    className={`${
                      item?.isCover ? "opacity-100" : "opacity-0"
                    } bg-green-500 px-8 -rotate-45 top-3 -left-7  absolute z-10 text-white font-medium`}
                  >
                    Cover
                  </div>
                  <img
                    className="cursor-pointer w-full h-[90px] rounded-[8px]"
                    src={item?.uri}
                    alt=""
                    onClick={() => openModal(item?.uri)}
                  />
                  <p className="font-medium">
                    {item?.selectedTag || item?.ImageTag}
                  </p>
                </div>
              ))}
            </div>
          )}

          <ul className="mt-3">
            {section.details.map((item, i) => (
              <li className="flex py-2 " key={i}>
                <div className="w-1/2">{item.label}</div>
                <div className="w-1/2 break-all">
                  {section?.title === "Photos"
                    ? null
                    : item.value
                    ? item.value
                    : " - "}
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative w-[80vw] h-[80vh] rounded-lg">
            <img
              src={selectedImage}
              alt="Preview"
              className="w-full h-full object-contain"
            />
            <div
              className="absolute top-2 right-2 text-white bg-black rounded-full bg-opacity-50 cursor-pointer text-2xl p-1"
              onClick={closeModal}
            >
              <IoClose />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Details;
