function formatToINR(amount) {
  if (amount == null) return;
  if (amount?.includes("₹")) return amount;

  let amountStr = amount.toString().split("").reverse().join("");

  let formattedAmount = amountStr.replace(/(\d{3})(?=\d)/g, "$1,");

  formattedAmount = formattedAmount.split("").reverse().join("");

  return "₹" + formattedAmount;
}

export default formatToINR;
