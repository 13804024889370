import { createContext, useEffect, useState } from "react";
import axiosInstance from "../Interceptor/axiosInstance";

const AssRequestContext = createContext();

const AssRequestProvider = ({ children }) => {
  const [requestsList, setRequestsList] = useState();
  const [originalList, setOriginalList] = useState();
  const [showDropDown, setShowDropDown] = useState("");
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedDateForFilter, setSelectedDateForFilter] = useState(null);
  const [viewTab, setViewTab] = useState("Pending");
  const [requestCount, setRequestCount] = useState(0);
  const [redener, setRedender] = useState(true);

  const fetchData = async () => {
    const response = await axiosInstance.get("/api/admin/user/helpRequest/get");
    const data = response?.data;

    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split("-");
      return new Date(`20${year}-${month}-${day}`);
    };

    let requestsList = [];
    if (viewTab === "Pending") {
      requestsList = data?.Data?.filter((item) => item.status === "Pending");
    } else if (viewTab === "Completed") {
      requestsList = data?.Data?.filter((item) => item.status === "Completed");
    }

    requestsList.sort((a, b) => {
      const dateA = parseDate(a.createdAt);
      const dateB = parseDate(b.createdAt);
      return dateB - dateA;
    });

    setRequestsList(requestsList);
    setOriginalList(requestsList);
    setRequestCount(requestsList.length);
    setSelectedDateForFilter(null);
  };

  useEffect(() => {
    fetchData();
  }, [viewTab, redener]);

  const handleSearchInput = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    const filteredList = originalList.filter(
      (item) =>
        (item.tbl_user_reg &&
          item.tbl_user_reg.name
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        item.id.toString() === searchText
    );
    setRequestsList(filteredList);
    setRequestCount(filteredList.length);
  };

  const handleDateChange = (e) => {
    const date = new Date(e);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    setSelectedDateForFilter(formattedDate);
  };

  const handleDateFilter = () => {
    const filteredData = originalList.filter((data) =>
      data.createdAt.includes(selectedDateForFilter)
    );
    setRequestsList(filteredData);
    setRequestCount(filteredData.length);
    setShowDropDown("");
  };

  // const handleMarkAsReviewed = (property) => {
  //   property.reportStatus = "Reviewed";
  //   const updatedList = requestsList.map((item) => {
  //     if (item.id === property.id) {
  //       return property;
  //     }
  //     return item;
  //   });
  //   setRequestsList(updatedList);
  // };

  return (
    <AssRequestContext.Provider
      value={{
        showDropDown,
        setShowDropDown,
        selectedRequest,
        setSelectedRequest,
        showDetails,
        setShowDetails,
        requestsList,
        setRequestsList,
        requestCount,
        setRequestCount,
        handleSearchInput,
        handleSearch,
        handleDateChange,
        handleDateFilter,
        selectedDateForFilter,
        fetchData,
        viewTab,
        setViewTab,
        redener,
        setRedender,
        setRequestsList,
        setOriginalList,
        setRequestCount,
        setSelectedRequest
      }}
    >
      {children}
    </AssRequestContext.Provider>
  );
};

export { AssRequestContext, AssRequestProvider };
