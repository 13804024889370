import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
  email: null,
  password: null,
  role: null,
  empId: null,
  profilePic: null,
  error: null,
  userRolePermission: [],
  userData: null,
  accessToken: localStorage.getItem("accessToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      const {
        user,
        email,
        password,
        role,
        empId,
        profilePic,
        userRolePermission,
        userData,
        refreshToken,
        accessToken,
      } = action.payload;
      state.isAuthenticated = true;
      state.user = user;
      state.email = email;
      state.password = password;
      state.role = role;
      state.empId = empId;
      state.profilePic = profilePic;
      state.userRolePermission = userRolePermission;
      state.userData = userData;
      state.refreshToken = refreshToken;
      state.accessToken = accessToken;
      state.error = null;
    },

    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.email = null;
      state.password = null;
      state.role = null;
      state.empId = null;
      state.profilePic = null;
      state.userRolePermission = [];
      state.userData = null;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.email = null;
      state.password = null;
      state.role = null;
      state.empId = null;
      state.profilePic = null;
      state.userRolePermission = [];
      state.userData = null;
      state.error = null;
      state.accessToken = null;
      state.refreshToken = null;
    },
    refreshTokenSuccess: (state, action) => {
      state.accessToken = action.payload;
      localStorage.setItem("accessToken", action.payload);
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setUserData: (state, action) => {
      state.user = action.payload.user;
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.role = action.payload.role;
      state.empId = action.payload.empId;
      state.profilePic = action.payload.profilePic;
      state.userRolePermission = action.payload.userRolePermission;
      state.userData = action.payload.userData;
      state.refreshToken = action.payload.refreshToken;
      state.accessToken = action.payload.accessToken;
      state.isAuthenticated = true; // Ensure isAuthenticated is set to true
      state.error = null;
    },
  },
});

export const {
  loginSuccess,
  loginFailure,
  logout,
  refreshTokenSuccess,
  setAccessToken,
  setRefreshToken,
  setUserData,
} = authSlice.actions;

// Selector functions
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectUserData = (state) => state.auth.userData;
export const selectUserPermissions = (state) => state.auth.userRolePermission;
export const selectAccessToken = (state) => state.auth.accessToken;
export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectEmpId = (state) => state.auth.empId;
export default authSlice.reducer;
