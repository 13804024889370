import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectEmpId } from "../store/authSlice";
import axiosInstance from "../Interceptor/axiosInstance";
// import { reportedProperties } from "../data";

const ReportedPropsContext = createContext();

const ReportedPropsProvider = ({ children }) => {
  const [reportsList, setReportsList] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showDropDown, setShowDropDown] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [wholeData, setWholeData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedDateForFilter, setSelectedDateForFilter] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [reportDetails, setReportDetails] = useState(null);
  const [viewTab, setViewTab] = useState("Pending");
  const [shimmerState, setShimmerState] = useState(false);
  const [listCount, setListCount] = useState(0);
  const [loader, setLoader] = useState("");
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);

  const empId = useSelector(selectEmpId);

  const fetchData = async () => {
    setShimmerState(true);
    try {
      const response = await axiosInstance.get(
        "/api/admin/getAll/reported/property"
      );
      const data = response?.data;

      const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split("-");
        return new Date(`20${year}-${month}-${day}`);
      };

      setWholeData(data?.flattenedResults);
      let propertiesList = [];

      if (viewTab === "Pending") {
        propertiesList = data?.flattenedResults?.filter(
          (item) => item?.status === "Pending"
        );
      } else if (viewTab === "Reviewed") {
        propertiesList = data?.flattenedResults?.filter(
          (item) => item?.status === "Reviewed"
        );
      }

      // Sort the properties based on Reported_Date
      propertiesList.sort((a, b) => {
        const dateA = new Date(a?.withTime);
        const dateB = new Date(b?.withTime);
        return dateB - dateA; // Sorting in descending order
      });
      setReportsList(propertiesList);
      setOriginalList(propertiesList || []);
      setFilteredData(propertiesList || []);
      setListCount((propertiesList || []).length);

      setShimmerState(false);
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
    setSelectedProperty();
    setReportDetails();
    setSelectedDateForFilter(null);
    setSelectedPropertyType(null);
  }, [viewTab]);

  const handlePropTypeFilter = (type) => {
    setSelectedPropertyType(type);
    const filteredList = filteredData.filter(
      (property) => property?.res_or_com === type
    );
    setReportsList(filteredList);
    setListCount(filteredList.length);
  };

  const handleSearchInput = (e) => {
    const searchText = e.target.value;
    const filteredList = originalList.filter(
      (property) =>
        (property?.building_name &&
          property?.building_name
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        property?.propertyID
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase()) ||
        property?.userData?.name
          .toLowerCase()
          .includes(searchText.toLowerCase())
    );
    setReportsList(filteredList);
    setListCount(filteredList.length);
  };

  const handleReset = () => {
    setReportsList(originalList);
    setListCount(originalList.length || 0);
    setSelectedDateForFilter(null);
    setSelectedPropertyType(null);
  };

  const handleSearch = () => {
    const filteredList = originalList.filter(
      (property) =>
        (property?.building_name &&
          property?.building_name
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        property?.propertyID.toString().toLowerCase() ===
          searchText.toLowerCase() ||
        property?.userData?.name
          .toLowerCase()
          .includes(searchText.toLowerCase())
    );
    setReportsList(filteredList);
    setListCount(filteredList.length);
  };

  const handleDateChange = (e) => {
    const date = new Date(e);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    setSelectedDateForFilter(formattedDate);
  };

  const handleDateFilter = () => {
    const filteredData = originalList.filter((data) =>
      data.Reported_Date.includes(selectedDateForFilter)
    );
    setReportsList(filteredData);
    setFilteredData(filteredData);
    setShowDropDown("");
    setListCount(filteredData.length);
  };

  // const handleMarkAsReviewed = async (property, index) => {
  //   setLoader(index);
  //   const bodyToSend = {
  //     emp_id: empId,
  //     reviewed_status: "Reviewed",
  //     post_id: property?.post_id,
  //     user_id: property?.user_id,
  //   };

  //   try {
  //     const response = await axiosInstance.put(
  //       "/api/admin/update/reviewedStatus",
  //       bodyToSend
  //     );

  //     const result = await response.json();
  //     setShowDetails(false);
  //     setSelectedProperty();
  //   } catch (error) {
  //     console.error("Failed to update property status", error);
  //   }
  //   setLoader("");
  // };


  const handleMarkAsReviewed = async (property, index) => {
    setLoader(index);
    const bodyToSend = {
      emp_id: empId,
      reviewed_status: "Reviewed",
      post_id: property?.post_id,
      user_id: property?.user_id,
    };
  
    try {
      await axiosInstance.put("/api/admin/update/reviewedStatus", bodyToSend);
  
      // Update the reportsList state instantly
      setReportsList((prevList) =>
        prevList.filter((item) => item?.post_id !== property?.post_id)
      );
  
      // If in "Reviewed" tab, add it to the list
      if (viewTab === "Reviewed") {
        setReportsList((prevList) => [...prevList, { ...property, status: "Reviewed" }]);
      }
  
      setShowDetails(false);
      setSelectedProperty(null);
    } catch (error) {
      console.error("Failed to update property status", error);
    }
  
    setLoader("");
  };
  
  return (
    <ReportedPropsContext.Provider
      value={{
        showDropDown,
        setShowDropDown,
        selectedProperty,
        setSelectedProperty,
        showDetails,
        setShowDetails,
        reportsList,
        setReportsList,
        handlePropTypeFilter,
        handleSearchInput,
        handleSearch,
        handleDateChange,
        handleDateFilter,
        handleMarkAsReviewed,
        handleReset,
        reportDetails,
        setReportDetails,
        viewTab,
        setViewTab,
        shimmerState,
        setShimmerState,
        listCount,
        setListCount,
        selectedDateForFilter,
        loader,
        setLoader,
        selectedPropertyType,
        setReportsList,
        setOriginalList,
        setFilteredData,
        setListCount,
        wholeData,
        setWholeData,
      }}
    >
      {children}
    </ReportedPropsContext.Provider>
  );
};

export { ReportedPropsContext, ReportedPropsProvider };
