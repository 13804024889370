import React, { useState, useRef, useEffect } from "react";
import { TextField, IconButton, Typography, Box } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useSelector } from "react-redux";

const TextArea = ({
  title,
  label,
  initialValue = "",
  value,
  onBlur,
  onFocus,
  onChangeText,
  errorText,
  selectedValues,
  textFieldValues,
  customValues,
  selectedFloor,
  totalFloors,
  pricePerSqFt,
  builtUpArea,
  totalPrice,
  onGeneratedParagraph, // Add this prop
  ...restOfProps
}) => {
  const [autopara, setAutopara] = useState("");
  const [charCount, setCharCount] = useState(value?.length || 0);
  const [textValue, setTextValue] = useState(initialValue || "");
  const [isFocused, setIsFocused] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);

  const MAX_CHAR_COUNT = 2000;
  const inputRef = useRef(null);
  const selectedOptions = useSelector((state) => state.form.selectedOptions);

  const mergedValues = {
    ...selectedValues,
    ...textFieldValues,
    ...customValues,
    ...(totalFloors && { "Total Floors": totalFloors }),
    ...(selectedFloor && { "select your floor": selectedFloor }),
    ...(pricePerSqFt && { "Price per SqFt ": pricePerSqFt }),
    ...(builtUpArea && { "Built Up Area": builtUpArea }),
    ...(totalPrice && { "Total Price": "₹ " + totalPrice }),
  };

  useEffect(() => {
    // Log mergedValues to the console

    const generatedPara = generateParagraph({
      ...selectedOptions,
      ...mergedValues,
    });
    setAutopara(generatedPara);
    if (onGeneratedParagraph && isGenerated) {
      onGeneratedParagraph(generatedPara); // Send the generated paragraph to the parent component
    }
  }, [selectedOptions, mergedValues, isGenerated]);

  const generateParagraph = (details) => {
    const {
      propertyType,
      transactionType,
      subType,
      locality,
      city,
      "Built Up Area": builtUpArea,
      Facing: facing,
      Price: price,
      "Monthly Rent": monthlyRent,
      "Lease Amount": leaseAmount,
      "Property available for": propertyAvailableFor,
      "Property SubType": propertySubType,
      "Plot Area": plotArea,
      "Plot Price": plotPrice,
      "Security Deposit": securityDeposit,
      "Available From": availableFrom,
      BHK,
      "suitable for": suitablefor,
    } = details;

    let template = "";

    if (propertyType === "Residential") {
      if (transactionType === "Sell" && subType === "Plot / Land") {
        template = `This Plot / Land in ${locality || ""}, ${
          city || ""
        } is available for sale. It spans ${
          mergedValues["Built Up Area"] || ""
        } Sq.ft and is priced at ${mergedValues["Total Price"] || ""}.`;
      } else if (transactionType === "Sell") {
        template = `This ${
          mergedValues.BHK || ""
        } ${subType} is available for sale in a prestigious ${subType} complex in ${locality}, ${city}. The ${subType} offers a spacious living area of ${
          mergedValues["Built Up Area"]
        } Sq.ft. Experience a vibrant community lifestyle, complete with optional facilities. Take this chance to own a premium property at an attractive price. The ${subType} faces ${
          mergedValues.Facing
        }, ensuring abundant natural light and a pleasant living environment. It is attractively priced at ${
          mergedValues["Total Price"]
        }, with the option to negotiate the amount, making it an excellent opportunity for potential buyers to secure a desirable property in a prime location.`;
      } else if (transactionType === "Rent") {
        template = `This ${
          mergedValues.BHK || ""
        } ${subType} is available for ${
          mergedValues["Property available for"]
        } in ${locality}, ${city}. It offers a built-up area of ${
          mergedValues["Built Up Area"]
        } Sq.ft and faces ${mergedValues.Facing}, and then ${
          mergedValues["Property available for"]
        } amount as ${mergedValues["Monthly Rent"]}${
          mergedValues["Lease amount"]
        }.`;
      }
    } else if (propertyType === "Commercial") {
      if (transactionType === "Sell") {
        template = `Explore this prime commercial property for sale in a vibrant location at ${locality}, ${city}, offering versatile spaces ${subType}. Spanning ${mergedValues["Built Up Area"]} square feet, it boasts ${mergedValues.Facing} facing for optimal visibility and natural light. Priced competitively at ${mergedValues["Total Price"]}, it presents a compelling investment opportunity in a rapidly growing commercial hub. With modern infrastructure and strategic accessibility, this property is ideal for expanding businesses or lucrative income. Don't miss out—schedule a viewing today to seize this promising investment in ${city}.`;
      } else if (transactionType === "Rent") {
        template = `Discover the ideal commercial property for ${
          mergedValues["Property available for"]
        }, featuring spacious ${subType} spaces ranging from ${
          mergedValues["Built Up Area"]
        } Sq.ft, available at competitive ${
          mergedValues["Property available for"]
        } amount as ${mergedValues["Monthly Rent"]}${
          mergedValues["Lease amount"]
        }. The property is strategically oriented to optimize natural light, with options facing ${
          mergedValues.Facing
        }. Situated in ${locality || ""}, ${
          city || ""
        }, prime locations with excellent transport links, these spaces are perfect for various business needs. Designed to foster productivity and efficiency, they offer a solid foundation for your operations. Secure your optimal ${
          subType || ""
        } today and enhance your business prospects with attractive terms.`;
      }
    }

    return template;
  };

  const handleTextChange = (event) => {
    const text = event.target.value;
    if (text.length <= MAX_CHAR_COUNT) {
      setCharCount(text.length);
      setTextValue(text);
      onChangeText?.(text);
    }
  };

  const handleAutoGenerate = () => {
    setTextValue(autopara);
    setCharCount(autopara.length);
    setIsGenerated(true);
    inputRef.current?.blur(); // Blur the input after auto-generating text
  };

  return (
    <Box sx={{ position: "relative", marginBottom: 4 }}>
      {title && <Typography variant="h6">{title}</Typography>}
      <TextField
        {...restOfProps}
        inputRef={inputRef}
        value={textValue}
        onBlur={(event) => {
          setIsFocused(false);
          onBlur?.(event);
        }}
        onFocus={(event) => {
          setIsFocused(true);
          onFocus?.(event);
        }}
        onChange={handleTextChange}
        label={label}
        style={{ width: "100%", paddingTop: "3rem" }}
        variant="outlined"
        multiline
        rows={8}
        error={!!errorText}
        helperText={errorText}
        InputProps={{
          endAdornment: (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "absolute",
                bottom: 7,
                right: 7,
              }}
            >
              <Typography variant="caption" color="textSecondary">
                {label}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {charCount}/{MAX_CHAR_COUNT}
              </Typography>
            </Box>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: isFocused
                ? "#080F9C"
                : errorText
                ? "#B00020"
                : "#B9C4CA",
            },
            "&:hover fieldset": {
              borderColor: "#080F9C",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#080F9C",
            },
          },
        }}
      />
      <IconButton
        onClick={handleAutoGenerate}
        sx={{ position: "absolute", right: 0, top: 0 }}
      >
        <AutorenewIcon />
        <Typography variant="caption" sx={{ ml: 1 }}>
          Auto Generate
        </Typography>
      </IconButton>
      <Typography variant="body2" sx={{ marginTop: 2 }}>
        Tell us more about the special features of your property
      </Typography>
    </Box>
  );
};

export default TextArea;
