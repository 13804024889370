import { createContext, useEffect, useState } from "react";
import axiosInstance from "../Interceptor/axiosInstance";

const UserReviewContext = createContext();
const UserReviewProvider = ({ children }) => {
  const [showDropDown, setShowDropDown] = useState("");
  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [currentUser, setCurrentUser] = useState(null); // ✅ Can be null (not iterable)
  const [wholeData, setWholeData] = useState([]); // ✅ Ensure array

  const [filteredData, setFilteredData] = useState();
  const [selectedDateForFilter, setSelectedDateForFilter] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [reviewCount, setReviewCount] = useState(0);
  const [viewTab, setViewTab] = useState("Pending");

  const handleFilterRating = (rating) => {
    setSelectedRating(rating);

    const filteredDataByRating = filteredData.filter(
      (data) => data.Rating === rating
    );
    setTableData(filteredDataByRating);
    setReviewCount(filteredDataByRating.length);
    setShowDropDown("");
  };

  const handleDateChange = (e) => {
    const date = new Date(e);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;

    setSelectedDateForFilter(formattedDate);
  };

  const handleDateFilter = () => {
    const filteredData = originalData.filter((data) =>
      data.createdAt.includes(selectedDateForFilter)
    );
    setTableData(filteredData);
    setFilteredData(filteredData);
    setReviewCount(filteredData.length);

    setShowDropDown("");
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("/api/admin/getAll/reviewData");
      const data = response?.data?.message;

      const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split("-");
        return new Date(`20${year}-${month}-${day}`);
      };
      let propertiesList = [];
      if (viewTab === "Pending") {
        propertiesList = data?.filter((item) => item?.status === "Pending");
      } else if (viewTab === "Reviewed") {
        propertiesList = data?.filter((item) => item?.status === "Reviewed");
      }

      propertiesList.sort((a, b) => {
        const dateA = new Date(a.withTime);
        const dateB = new Date(b.withTime);
        return dateB - dateA; // Sorting in descending order
      });

      setTableData(propertiesList);
      setOriginalData(propertiesList);
      setFilteredData(propertiesList);
      setReviewCount(propertiesList.length);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, [viewTab]);

  return (
    <UserReviewContext.Provider
      value={{
        tableData,
        setTableData,
        currentUser,
        setCurrentUser,
        handleDateFilter,
        selectedDateForFilter,
        setSelectedDateForFilter,
        handleDateChange,
        showDropDown,
        setShowDropDown,
        handleFilterRating,
        fetchData,
        filteredData,
        selectedRating,
        setSelectedRating,
        reviewCount,
        viewTab,
        setViewTab,
        setTableData,
        setOriginalData,
        setFilteredData,
        setReviewCount,
        wholeData,
        setWholeData,
      }}
    >
      {children}
    </UserReviewContext.Provider>
  );
};

export { UserReviewContext, UserReviewProvider };
