import { Alert, Snackbar } from "@mui/material";
import React, { useState } from "react";
import Filters from "./components/Filters";
import PropertyDetailView from "./components/PropertyDetailView";
import PropertyListing from "./components/PropertyListing";

const PostApproval = () => {
  const [open, setOpen] = useState(null);
  const handleClose = (reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(null);
  };

  return (
    <div>
      <h3 className="text-xl py-4 font-bold border-b-[1px] border-b-[#404040]">
        Post Property Approval
      </h3>
      <div className="flex gap-2">
        <div className="w-[70%]">
          <Filters />
          <div className="hide-scrollbar h-[calc(100vh-64px)] overflow-auto">
            <PropertyListing setOpen={setOpen} />
          </div>
        </div>
        <div className="w-[30%] hide-scrollbar mt-16 border-l-[1px] border-l-[#404040] h-[calc(100vh-64px)] overflow-auto">
          <PropertyDetailView />
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {`New Property Posted by ${open === "Approved" ? "Admin" : "User"}.`}
          {open === "Approved" ? ` Check Vattara Properites` : ``}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PostApproval;
