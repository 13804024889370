import React, { useContext, useEffect, useRef } from "react";
import PropertiesList from "./PropertiesList";
import ReportDetails from "./ReportDetails";
import Filters from "./Filters";
import { ReportedPropsContext } from "../../../context/ReportedPropsContext";
import socket from "../../../utils/socket";

const ReportedPropsDashboard = () => {
  const {
    reportsList,
    setReportsList,
    setOriginalList,
    setFilteredData,
    setListCount,
    viewTab,
    setShowDetails,
    setSelectedProperty,
    wholeData,
    setWholeData,
  } = useContext(ReportedPropsContext);

  const viewTabRef = useRef(viewTab);

  useEffect(() => {
    viewTabRef.current = viewTab;
  }, [viewTab]);

  useEffect(() => {
    socket.on("newReport", (data) => {
      if (viewTabRef.current === "Pending") {
        const newData = data?.Data;
        setReportsList((prev) => [newData, ...prev]);
        setOriginalList((prev) => [newData, ...prev]);
        setFilteredData((prev) => [newData, ...prev]);
        setWholeData((prev) => [newData, ...prev]);
        setListCount((prev) => prev + 1);
      }
    });

    socket.on("PropertyReportApprove", (data) => {
      const newData = data?.Data;

      if (newData?.reviewed_status == viewTabRef.current) {
        const updatedProperty = wholeData?.find(
          (prop) => prop?.post_id === newData?.post_id
        );
        const updatedPropertyWithStatus = {
          ...updatedProperty,
          status: newData?.reviewed_status,
        };

        setReportsList((prev) => [updatedPropertyWithStatus, ...prev]);
        setOriginalList((prev) => [updatedPropertyWithStatus, ...prev]);
        setFilteredData((prev) => [updatedPropertyWithStatus, ...prev]);
        setShowDetails(false);
        setSelectedProperty(null);
      } else if (viewTabRef.current === "Pending") {
        console.log(viewTabRef.current);

        setReportsList((prev) =>
          prev?.filter((prop) => prop?.post_id != newData?.post_id)
        );
        setOriginalList((prev) =>
          prev?.filter((prop) => prop?.post_id != newData?.post_id)
        );
        setFilteredData((prev) =>
          prev?.filter((prop) => prop?.post_id != newData?.post_id)
        );
        setListCount((prev) => prev - 1);
        setShowDetails(false);
        setSelectedProperty(null);
      }
    });

    return () => {
      socket.off("newReport");
      socket.off("PropertyReportApprove");
    };
  }, []);

  return (
    <div>
      <h2 className="font-bold text-xl py-4 border-b-[1px] border-[#404040]">
        Reported Properties Dashboard
      </h2>
      <Filters />
      <div className="bg-[#F5F4F8]">
        <PropertiesList />
      </div>
    </div>
  );
};

export default ReportedPropsDashboard;
