import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackComponent from "../../icons/Back";
import KingComponent from "../../icons/King";
import { Box, Divider, Typography, Button } from "@material-ui/core";
import Manage from "./Manage";
import Permission from "./Permission";
import axiosInstance from "../../Interceptor/axiosInstance";
import { Alert, Snackbar } from "@mui/material";

const EditRole = () => {
  const location = useLocation();
  const responseData = location.state?.responseData || {};
  const [selectedRole, setSelectedRole] = useState(responseData?.Role);
  const [roles, setRoles] = useState([]);
  const [initialRole, setInitialRole] = useState(responseData?.Role);
  const [selectedTab, setSelectedTab] = useState("Display");
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const handleClose = (reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(null);
  };

  useEffect(() => {
    axiosInstance
      .get("/api/admin/getRole")
      .then((response) => {
        setRoles(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  }, [initialRole]);

  const existingRole = roles?.find(
    (role) =>
      role?.Roles?.toLowerCase()?.trim() === selectedRole?.toLowerCase()?.trim()
  );

  console.log(existingRole?.Roles, initialRole);

  const handleSave = () => {
    if (existingRole && existingRole?.Roles !== initialRole) {
      console.log("Already exists");

      setOpen("error");
      return;
    }
    axiosInstance
      .post("/api/admin/roleupdate", {
        ExistingRole: initialRole,
        updateRole: selectedRole,
      })
      .then((response) => {
        setInitialRole(selectedRole);
        setOpen("success");
      })
      .catch((error) => {
        console.error("Error updating role:", error);
      });
  };

  const handleReset = () => {
    setSelectedRole(initialRole);
  };

  //get method for the managemebers
  console.log(roles);

  return (
    <div className="flex">
      {/* Sidebar */}
      <div className="w-52 h-full bg-gray-100 p-5">
        <Link to="/rolesmanagement">
          <button
            type="button"
            onClick={() => setSelectedRole("")}
            className="mb-5 border-none bg-transparent w-full p-2 font-roboto text-lg font-bold relative right-4 "
          >
            <BackComponent />
            <span className="relative right-6 bottom-5 custom-xl:right-[20px]  ">
              BACK
            </span>
          </button>
        </Link>
        {/* {roles.map(
          (role, index) =>
            role?.Roles !== "Admin" &&
            role?.Roles !== "Member" && (
              <button
                key={index}
                type="button"
                onClick={() => {
                  setSelectedRole(role.Roles);
                  setInitialRole(role.Roles);
                }}
                className="mb-5 border-none bg-transparent w-full p-0 font-roboto text-lg font-normal text-left"
              >
                <KingComponent className="mr-2 relative top-1 right-2" />
                <span className="relative left-6 bottom-5">
                  {role.Roles}
                </span>{" "}
              </button>
            )
        )} */}
        {roles.map(
  (role, index) =>
    role?.Roles !== "Admin" &&
    role?.Roles !== "Member" && (
      <button
        key={index}
        type="button"
        onClick={() => {
          setSelectedRole(role.Roles);
          setInitialRole(role.Roles);
        }}
        className={`mb-5 border-none w-full p-2 font-roboto text-lg font-normal text-left flex items-center rounded-lg transition-all duration-300
          ${
            selectedRole === role.Roles
              ? "bg-blue-500 text-white font-semibold shadow-md"
              : "bg-transparent text-black"
          }`}
      >
        <KingComponent className="mr-2 ml-2 relative top-1 right-2" />
        <span className="relative left-2">{role.Roles}</span>
      </button>
    )
)}

      </div>

      <Divider
        orientation="vertical"
        flexItem
        className="relative right-6 h-3/4 lg:left-[20px] sm:left-[20px]  "
        style={{ height: "900px" }}
      />
      <div className="flex-1">
        <Box className="mt-5 relative lg:left-[40px] sm:left-[40px] ">
          <Typography variant="h6" className="flex flex-row justify-start">
            Edit Role - {initialRole}
          </Typography>
          <div className="flex flex-row justify-start mt-6 space-x-6">
  <button
    type="button"
    onClick={() => setSelectedTab("Display")}
    className={`mb-5 border-none w-40 p-1 font-roboto text-base font-normal text-left transition-all duration-300
      ${
        selectedTab === "Display"
          ? "border-b-2 border-blue-500 font-semibold text-blue-600"
          : "text-black"
      }`}
  >
    Display
  </button>
  
  <button
    type="button"
    onClick={() => setSelectedTab("Permission")}
    className={`mb-5 border-none w-96 p-1 font-roboto text-base font-normal text-left relative sm:right-10 transition-all duration-300
      ${
        selectedTab === "Permission"
          ? "border-b-2 border-blue-500 font-semibold text-blue-600"
          : "text-black"
      }`}
  >
    Permission
  </button>
  
  <button
    type="button"
    onClick={() => setSelectedTab("Manage Members")}
    className={`mb-5 border-none w-56 p-1 font-roboto text-base font-normal text-left relative sm:right-72 transition-all duration-300
      ${
        selectedTab === "Manage Members"
          ? "border-b-2 border-blue-500 font-semibold text-blue-600"
          : "text-black"
      }`}
  >
    Manage Members {`(${count})`}
  </button>
</div>

          {/* <div className="flex flex-row justify-start mt-6 space-x-6">
            <button
              type="button"
              onClick={() => setSelectedTab("Display")}
              className="mb-5 border-none bg-transparent w-40  p-1 font-roboto text-base font-normal text-left"
            >
              Display
            </button>
            <button
              type="button"
              onClick={() => setSelectedTab("Permission")}
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              className="mb-5 border-none bg-transparent w-96 p-1 font-roboto text-base font-normal text-left relative sm:right-10"
            >
              Permission
            </button>
            <button
              type="button"
              onClick={() => setSelectedTab("Manage Members")}
              className="mb-5 border-none bg-transparent w-56 p-1 font-roboto text-base font-normal text-left relative sm:right-72  "
            >
              Manage Members {`(${count})`}
            </button>
          </div> */}

          <Divider
            orientation="horizontal"
            className="w-7/12 border-b border-black relative top-[-10px]"
          />

          {selectedTab === "Display" && (
            <div>
              <p className="font-roboto text-base font-medium relative top-2.5">
                Role Name
              </p>
              <input
                placeholder="Role Name"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                className="w-4/12 rounded-lg p-2.5 border border-gray-400 font-roboto mt-6 outline-none"
              />
              <Divider
                orientation="horizontal"
                color="#bdbdbd"
                className="w-4/12 relative top-4"
              />
              <div className="mt-12 flex gap-2">
                {selectedRole !== "Member" && (
                  <>
                    <Button
                      variant="contained"
                      onClick={handleReset}
                      style={{
                        backgroundColor: "#F5F4F8",
                        border: "none",
                        boxShadow: "none",
                      }}
                      className=" text-black mr-36 w-24 p-2 font-roboto text-sm font-bold relative left-56"
                    >
                      Reset
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      style={{
                        backgroundColor: "#7DAE3F",
                      }}
                      className=" text-white ml-10 w-24 p-2 font-roboto text-sm font-bold relative left-64"
                    >
                      Save
                    </Button>

                    <Snackbar
                      open={open}
                      autoHideDuration={3000}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Alert
                        onClose={handleClose}
                        severity={
                          open === "success"
                            ? "success"
                            : open === "error"
                            ? "error"
                            : "info"
                        }
                        sx={{ width: "100%" }}
                      >
                        {open === "success"
                          ? "Role Name updated successfully"
                          : "This Role already exists."}
                      </Alert>
                    </Snackbar>
                  </>
                )}
              </div>
            </div>
          )}
          {selectedTab === "Permission" && (
            <div>
              <Permission selectedRole={selectedRole} />
            </div>
          )}
          {selectedTab === "Manage Members" && (
            <div>
              <Manage selectedRole={selectedRole} setCount={setCount} />
            </div>
          )}
        </Box>
      </div>
    </div>
  );
};

export default EditRole;
