import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  outlinedInputClasses,
  Snackbar,
  styled,
  Modal,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOptions } from "../../../redux/actions";
import Propertydetails from "../components/Propertydetails";
import Review from "../components/Review";
// import store from "../../../redux/store";
// import { Provider } from "react-redux";
import {
  formData,
  propertyTypeOptions,
  Residentialrent,
  Residentialsell,
  ResidentialSellPlot,
  CommercialrentOffice,
  CommercialrentWareh,
  CommercialIndSpace,
  CommerRentRetailnShow,
  CommercialSellRetailnShow,
  CommercialSellOff,
  CommercialSellWare,
  CommercialSellIndSp,
} from "../../../data";
// import "./index.css";
import { FaChevronDown } from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { FormContext } from "../../../context/formContext";
import { selectUserPermissions } from "../../../store/authSlice";
import Map from "./Map";
import SuccessModal from "../../SuccessModal";

const validationSchema = Yup.object().shape({
  propertyType: Yup.string().required("Property type is required"),
  transactionType: Yup.string().required("Transaction type is required"),
  subType: Yup.string().required("Sub type is required"),
  city: Yup.string().required("City is required"),
  locality: Yup.string().required("Locality is required"),
  landmark: Yup.string().required("Landmark is required"),
});

const CustomRadio = styled(Radio)(({ theme, checked }) => ({
  display: "none",
}));

// Styled label with custom styles
const CustomFormControlLabel = styled(FormControlLabel)(
  ({ theme, checked }) => ({
    margin: "2px",
    padding: "7px 14px",
    display: "flex",
    justifyContent: "center",
    width: "47%",
    marginTop: "8px",
    borderRadius: "100px",
    backgroundColor: checked ? "#02a7e4" : "#F5F4F8",
    color: checked ? "white" : "black",
    fontWeight: checked ? "bold" : "normal",
    "& .MuiTypography-root": {
      margin: 0,
    },
    "&:hover": {
      backgroundColor: checked ? "#02a7e4" : "#E0E0E0",
    },
  })
);

const customTextFieldStyles = {
  "& .MuiInputBase-input": {
    backgroundColor: "#F5F4F8",
    color: "#404040",
    borderRadius: "100px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiFormHelperText-root": {
    backgroundColor: "white",
  },
  "& input:-webkit-autofill": {
    backgroundColor: "#F5F4F8",
    borderRadius: "100px",
    color: "#404040",
  },
  "& input:-webkit-autofill:hover": {
    backgroundColor: "#F5F4F8",
    borderRadius: "100px",
  },
};

const CustomSelect = (props) => {
  const { field, form, ...other } = props;
  return (
    <Select
      {...field}
      {...other}
      IconComponent={() => (
        <FaChevronDown
          style={{ fontSize: 20, marginRight: 10, color: "#B0B0B0" }}
        />
      )}
      sx={{
        bgcolor: "#F5F4F8",
        borderRadius: "100px",
        "& .MuiSelect-select": {
          padding: "10px 24px",
          display: "flex",
          alignItems: "center",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiInputLabel-root": {
          top: "-6px",
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            bgcolor: "#F5F4F8",
            mt: 1,
          },
        },
      }}
    >
      {props.options.map((city) => (
        <MenuItem key={city.id} value={city.id}>
          {city.title}
        </MenuItem>
      ))}
    </Select>
  );
};

const PostPropertyForm = () => {
  const [subTypeOptions, setSubTypeOptions] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [selectedLocatilty, setSelectedLocality] = useState();
  const [open, setOpen] = useState(false);
  const massageValue = "Post details updated successfully";
  const handleclose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setShowMap(false);
  };

  const {
    submittedData,
    generatedParagraph,
    selectedValues,
    textFieldValues,
    customValues,
    selectedFloor,
    totalFloors,
    selectedAmenities,
    pricePerSqFt,
    builtUpArea,
    totalPrice,
    showDetails,
    setShowDetails,
    resetShowDetails,
    subLocality,
    setSubLocality,
  } = useContext(FormContext);

  const dispatch = useDispatch();

  const handlePropertyTypeSelect = (event, setFieldValue) => {
    const id = parseInt(event.target.value, 10);
    setFieldValue("propertyType", id);
    setFieldValue("transactionType", "");
    setFieldValue("subType", "");
    setSubTypeOptions([]);
    resetShowDetails();
  };

  const formik = useFormik({
    initialValues: {
      propertyType: 1,
      transactionType: 1,
    },
    // other Formik settings
  });
  const { values } = formik;

  const handleTransactionTypeSelect = (event, setFieldValue, propertyType) => {
    const id = parseInt(event.target.value, 10);
    setFieldValue("transactionType", id);
    const selectedType = propertyType === 1 ? "Residential" : "Commercial";
    const selectedTransaction = id === 1 ? "Sell" : "Rent";
    const options = propertyTypeOptions[selectedType][selectedTransaction].map(
      (title, idx) => ({ id: idx + 1, title })
    );
    setSubTypeOptions(options);
    setFieldValue("subType", "");
    resetShowDetails();
  };

  const handleSubTypeSelect = (event, setFieldValue) => {
    const id = parseInt(event.target.value, 10);
    setFieldValue("subType", id);
    resetShowDetails();
  };

  const getSelectedTitle = (options, selectedId) => {
    const selectedOption = options.find((option) => option.id === selectedId);
    return selectedOption ? selectedOption.title : null;
  };

  const handleDistrictSelect = (dist) => {
    const cityTitle = getSelectedTitle(
      formData.basic_details.find((field) => field.inputID === 3650150)?.data ||
        [],
      dist
    );
    setSelectedDistrict(cityTitle);
  };

  const handleMap = () => {
    selectedDistrict !== undefined ? setShowMap(true) : setOpenSnack(true);
  };

  const handleLocationSelect = (locationData) => {
    setSelectedLocality(locationData);
    setSubLocality(subLocality);
  };

  const handleSubmit = (values) => {
    const { propertyType, transactionType, subType, city, locality, landmark } =
      values;

    const propertyTypeTitle = propertyType === 1 ? "Residential" : "Commercial";
    const transactionTypeTitle = transactionType === 1 ? "Sell" : "Rent";
    const subTypeTitle = getSelectedTitle(subTypeOptions, subType);
    const cityTitle = getSelectedTitle(
      formData.basic_details.find((field) => field.inputID === 3650150)?.data ||
        [],
      city
    );

    const selectedOptions = {
      propertyType: propertyTypeTitle,
      transactionType: transactionTypeTitle,
      subType: subTypeTitle,
      city: cityTitle,
      locality: locality,
      landmark: landmark,
      sublocality: subLocality,
      latitude: selectedLocatilty.latitude,
      longitude: selectedLocatilty.longitude,
    };

    dispatch(
      setSelectedOptions(selectedOptions, transactionTypeTitle, subTypeTitle)
    );

    if (
      propertyTypeTitle === "Residential" &&
      transactionTypeTitle === "Sell" &&
      subTypeTitle === "Plot / Land"
    ) {
      setShowDetails((prev) => ({ ...prev, ResidentialSellPlot: true }));
    } else if (
      propertyTypeTitle === "Residential" &&
      transactionTypeTitle === "Sell" &&
      ["Apartment", "Villa", "Independent House"].includes(subTypeTitle)
    ) {
      setShowDetails((prev) => ({ ...prev, Residentialsell: true }));
    } else if (
      propertyTypeTitle === "Residential" &&
      transactionTypeTitle === "Rent" &&
      ["Apartment", "Villa", "Independent House"].includes(subTypeTitle)
    ) {
      setShowDetails((prev) => ({ ...prev, Residentialrent: true }));
    } else if (
      propertyTypeTitle === "Commercial" &&
      transactionTypeTitle === "Rent" &&
      subTypeTitle === "Office Space"
    ) {
      setShowDetails((prev) => ({ ...prev, CommercialRentOffice: true }));
    } else if (
      propertyTypeTitle === "Commercial" &&
      transactionTypeTitle === "Rent" &&
      ["Retail Shop", "Showroom"].includes(subTypeTitle)
    ) {
      setShowDetails((prev) => ({ ...prev, CommercialRentRetailnShow: true }));
    } else if (
      propertyTypeTitle === "Commercial" &&
      transactionTypeTitle === "Rent" &&
      subTypeTitle === "Warehouse"
    ) {
      setShowDetails((prev) => ({ ...prev, CommercialRentWareh: true }));
    } else if (
      propertyTypeTitle === "Commercial" &&
      transactionTypeTitle === "Rent" &&
      subTypeTitle === "Industrial Space"
    ) {
      setShowDetails((prev) => ({ ...prev, CommercialIndSpace: true }));
    } else if (
      propertyTypeTitle === "Commercial" &&
      transactionTypeTitle === "Sell" &&
      subTypeTitle === "Office Space"
    ) {
      setShowDetails((prev) => ({ ...prev, CommercialSellOff: true }));
    } else if (
      propertyTypeTitle === "Commercial" &&
      transactionTypeTitle === "Sell" &&
      ["Retail Shop", "Showroom"].includes(subTypeTitle)
    ) {
      setShowDetails((prev) => ({ ...prev, CommercialSellRetailnShow: true }));
    } else if (
      propertyTypeTitle === "Commercial" &&
      transactionTypeTitle === "Sell" &&
      subTypeTitle === "Warehouse"
    ) {
      setShowDetails((prev) => ({ ...prev, CommercialSellWare: true }));
    } else if (
      propertyTypeTitle === "Commercial" &&
      transactionTypeTitle === "Sell" &&
      subTypeTitle === "Industrial Space"
    ) {
      setShowDetails((prev) => ({ ...prev, CommercialSellIndSp: true }));
    }
  };

  return (
    <div className="flex">
      <div className="w-[70%]  hide-scrollbar h-[calc(100vh-64px)] overflow-auto font-Roboto border-r-[1px] border-[#404040]">
        <Snackbar
          open={openSnack}
          autoHideDuration={2000} // Duration in milliseconds (5 seconds)
          onClose={() => setOpenSnack(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setOpenSnack(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Select Locate Your Property
          </Alert>
        </Snackbar>

        <Box sx={{ paddingBottom: "50px", position: "relative" }}>
          <Typography
            sx={{
              mt: 2,
              borderBottom: "1px",
              borderColor: "bg-[#404040]",
            }}
            variant="h6"
            gutterBottom
          >
            Post Property
          </Typography>
          <div className="w-full h-[1px] bg-[#404040] my-4"></div>
          <Formik
            initialValues={{
              propertyType: "",
              transactionType: "",
              subType: "",
              city: "",
              locality: "",
              landmark: "",
              sublocality: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form className=" bg-white px-8 py-4 rounded-lg">
                <Box sx={{ mb: 2, width: "75%" }}>
                  <Typography variant="h6">Basic Details</Typography>
                  <Box sx={{ my: 2, width: "100%" }}>
                    <FormControl sx={{ width: "100%" }} component="fieldset">
                      <FormLabel component="legend">Property Type</FormLabel>
                      <RadioGroup
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        row
                        value={values.propertyType}
                        onChange={(e) =>
                          handlePropertyTypeSelect(e, setFieldValue)
                        }
                      >
                        <CustomFormControlLabel
                          value={1}
                          required
                          checked={values.propertyType === 1}
                          control={<CustomRadio />}
                          label="Residential"
                        />
                        <CustomFormControlLabel
                          value={2}
                          required
                          checked={values.propertyType === 2}
                          control={<CustomRadio />}
                          label="Commercial"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ mb: 2, width: "100%" }}>
                    <FormControl sx={{ width: "100%" }} component="fieldset">
                      <FormLabel component="legend">Transaction Type</FormLabel>
                      <RadioGroup
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        row
                        value={values.transactionType}
                        onChange={(e) =>
                          handleTransactionTypeSelect(
                            e,
                            setFieldValue,
                            values.propertyType
                          )
                        }
                      >
                        <CustomFormControlLabel
                          value={1}
                          required
                          checked={values.transactionType === 1}
                          control={<CustomRadio />}
                          label="Sell"
                        />
                        <CustomFormControlLabel
                          value={2}
                          checked={values.transactionType === 2}
                          required
                          control={<CustomRadio />}
                          label="Rent"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  {values.propertyType && values.transactionType && (
                    <Box sx={{ mb: 2 }}>
                      <FormControl fullWidth>
                        <FormLabel>Sub Type</FormLabel>
                        <CustomSelect
                          value={values.subType}
                          required
                          onChange={(e) =>
                            handleSubTypeSelect(e, setFieldValue)
                          }
                          label="Sub Type"
                          options={subTypeOptions}
                        />
                      </FormControl>
                    </Box>
                  )}

                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6">Locate Your Property</Typography>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      required
                    >
                      <InputLabel>City</InputLabel>
                      <CustomSelect
                        name="city"
                        value={values.city}
                        options={
                          formData.basic_details.find(
                            (field) => field.inputID === 3650150
                          )?.data || []
                        }
                        onChange={(event) => {
                          const newValue = event.target.value;
                          setFieldValue("city", newValue);
                          handleDistrictSelect(newValue);
                        }}
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="error"
                      />
                    </FormControl>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Field
                      size="small"
                      sx={customTextFieldStyles}
                      as={TextField}
                      name="locality"
                      placeholder="Locality"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      disabled 
                      helperText={<ErrorMessage name="locality" />}
                    />
                    <Button
                      onClick={handleMap}
                      sx={{
                        backgroundColor: "#02a7e4",
                        ":hover": { backgroundColor: "#02a7e4" },
                      }}
                      size="small"
                      variant="contained"
                      color="primary"
                    >
                      CHOOSE LOCATION
                    </Button>
                    <div>
                      <Modal
                        open={showMap}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box
                          sx={{
                            bgcolor: "#fff",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "50%",
                            p: 3,
                          }}
                        >
                          <Map
                            onClose={handleClose}
                            selectedDistrict={selectedDistrict}
                            // formName={formName}
                            onLocationSelect={handleLocationSelect}
                            setFieldValue={setFieldValue}
                          />
                        </Box>
                      </Modal>
                    </div>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Field
                      size="small"
                      sx={customTextFieldStyles}
                      as={TextField}
                      name="landmark"
                      placeholder="Landmark"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      helperText={<ErrorMessage name="landmark" />}
                    />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Button
                      sx={{
                        backgroundColor: "#02a7e4",
                        ":hover": { backgroundColor: "#02a7e4" },
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>

          {showDetails.Residentialrent && (
            <Propertydetails Residentialrent={Residentialrent} />
          )}
          {showDetails.Residentialsell && (
            <Propertydetails Residentialsell={Residentialsell} />
          )}
          {showDetails.ResidentialSellPlot && (
            <Propertydetails ResidentialSellPlot={ResidentialSellPlot} />
          )}
          {showDetails.CommercialRentOffice && (
            <Propertydetails CommercialrentOffice={CommercialrentOffice} />
          )}
          {showDetails.CommercialRentRetailnShow && (
            <Propertydetails CommerRentRetailnShow={CommerRentRetailnShow} />
          )}
          {showDetails.CommercialRentWareh && (
            <Propertydetails CommercialrentWareh={CommercialrentWareh} />
          )}
          {showDetails.CommercialIndSpace && (
            <Propertydetails CommercialIndSpace={CommercialIndSpace} />
          )}
          {showDetails.CommercialSellOff && (
            <Propertydetails CommercialSellOff={CommercialSellOff} />
          )}
          {showDetails.CommercialSellRetailnShow && (
            <Propertydetails
              CommercialSellRetailnShow={CommercialSellRetailnShow}
            />
          )}
          {showDetails.CommercialSellWare && (
            <Propertydetails CommercialSellWare={CommercialSellWare} />
          )}
          {showDetails.CommercialSellIndSp && (
            <Propertydetails CommercialSellIndSp={CommercialSellIndSp} />
          )}
        </Box>
      </div>
      <div className="w-[30%] hide-scrollbar h-[calc(100vh-64px)] overflow-auto top-0 right-0">
        <Review
          data={submittedData}
          generatedParagraph={generatedParagraph}
          selectedValues={selectedValues}
          textFieldValues={textFieldValues}
          customValues={customValues}
          selectedFloor={selectedFloor}
          totalFloors={totalFloors}
          selectedAmenities={selectedAmenities}
          pricePerSqFt={pricePerSqFt}
          builtUpArea={builtUpArea}
          totalPrice={totalPrice}
          setOpen={setOpen}
        />
      </div>
      <div>
        <SuccessModal
          open={open}
          handleClose={handleclose}
          massageValue={massageValue}
        />
      </div>
    </div>
  );
};

export default PostPropertyForm;
