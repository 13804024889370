import React, { useContext, useEffect, useRef } from "react";
import { ServiceListingContext } from "../../../context/ServiceListingContext";
import Shimmer from "../../Shimmer";
import axiosInstance from "../../../Interceptor/axiosInstance";
import userProfile from "../../../assets/dummy.webp";
import socket from "../../../utils/socket";

const ServicesList = () => {
  const {
    servicesList,
    setUserAndServiceDetail,
    userAndServiceDetail,
    shimmerState,
    listcount,
    setServicesList,
    setOriginalList,
    setFilteredData,
    setListcount,
    setWholeData,
    currentServiceTab,
  } = useContext(ServiceListingContext);

  const currentTabRef = useRef(currentServiceTab);

  useEffect(() => {
    currentTabRef.current = currentServiceTab;
  }, [currentServiceTab]);

  useEffect(() => {
    socket.on("servicePost", (data) => {
      const newData = data?.Data;
      console.log(newData);

      if (currentTabRef.current === "Pending") {
        setServicesList((prev) => [newData, ...prev]);
        setOriginalList((prev) => [newData, ...prev]);
        setFilteredData((prev) => [newData, ...prev]);
        setWholeData((prev) => [newData, ...prev]);
        setListcount((prev) => prev + 1);
      }
    });
    return () => {
      socket.off("servicePost");
    };
  }, []);

  const handleViewDetails = async (service) => {
    try {
      const response = await axiosInstance.get(
        `/services/getService/userDetail/${service.id}`
      );

      setUserAndServiceDetail(response?.data?.message[0]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="hide-scrollbar h-[calc(100vh-64px)] overflow-auto flex flex-col gap-3 mr-2">
      {shimmerState !== true ? (
        <h4 className="font-medium font-Inter text-[15px] px-2 underline">
          {listcount} Properties Listed
        </h4>
      ) : null}
      {shimmerState === true ? (
        <Shimmer />
      ) : (
        servicesList?.map((service, i) => (
          <div
            key={i}
            className={`${
              service?.id === userAndServiceDetail?.id
                ? "bg-[#E4E0EE]"
                : "bg-white"
            } flex items-center justify-between gap-5 text-xs rounded-[8px] shadow-sm p-2`}
          >
            <div className="relative">
              <img
                className="w-[90px] h-[75px] object-cover"
                src={
                  service.business_profile
                    ? service.business_profile
                    : userProfile
                }
                alt="business_profile"
              />
              <span className="absolute bg-[#02a7e4] px-2 top-0 text-white rounded-r-[8px]">
                {i + 1}
              </span>
            </div>
            <div className="w-[30%] flex justify-start">
              <table>
                <tbody>
                  <tr>
                    <td>Business Name</td>
                    <td className="px-2">:</td>
                    <td>{service.business_name}</td>
                  </tr>
                  <tr>
                    <td>Service Category</td>
                    <td className="px-2">:</td>
                    <td>{service.service_category}</td>
                  </tr>
                  <tr>
                    <td>Contact</td>
                    <td className="px-2">:</td>
                    <td>{service.mobile_no}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="bg-[#8E8E8E] w-[0.5px] h-[65px]"></div>
            <div className="w-[30%] flex justify-start">
              <table>
                <tbody>
                  <tr>
                    <td>Location(District)</td>
                    <td className="px-2">:</td>
                    <td>{service.district}</td>
                  </tr>
                  <tr>
                    <td>Submission Date</td>
                    <td className="px-2">:</td>
                    <td>{service.createdAt}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td className="px-2">:</td>
                    <td
                      className={`${
                        service.list_approval === "Pending"
                          ? "text-[#D28A29]"
                          : service.list_approval === "Verified"
                          ? "text-[#8BC83F]"
                          : service.list_approval === "Rejected"
                          ? "text-red-600"
                          : "text-black"
                      } font-bold`}
                    >
                      {service.list_approval}
                    </td>
                  </tr>
                  {service?.list_approval === "Pending" ? null : (
                    <>
                      <tr>
                        <td>
                          {service?.list_approval === "Verified"
                            ? "Verified By"
                            : "Rejected By"}
                        </td>
                        <td className="px-2">:</td>
                        <td>
                          {service?.tbl_admin_register?.username ? service?.tbl_admin_register?.username : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Emp Id</td>
                        <td className="px-2">:</td>
                        <td>{service?.emp_id ? service?.emp_id : "-"}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="bg-[#8E8E8E] w-[0.5px] h-[65px]"></div>
            <div className="flex flex-col gap-2">
              <button
                onClick={() => handleViewDetails(service)}
                className="bg-[] px-2 py-1 rounded-[8px] border-b-[0.3px] border-b-[#8E8E8E] bg-white"
              >
                View Details
              </button>
              {/* <button className="bg-[#CBE8A8] px-2 py-1 rounded-[8px] border-b-[0.3px] border-b-[#8E8E8E]">
                Verify
              </button> */}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ServicesList;
