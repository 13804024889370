import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Interceptor/axiosInstance"; // Assuming axiosInstance setup is correct

// Async action creator using Redux Thunk middleware for deleting user data
export const deleteFormDataAsync = createAsyncThunk(
  "form/deleteFormData",
  async (uniqueId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        "/api/admin/delete/user/detail",
        {
          data: {
            emp_id: uniqueId,
          },
        }
      );

      if (response && response.status === 200) {
        return uniqueId; // Return the unique ID of the deleted user
      } else {
        console.error("Error: Unexpected response status:", response.status);
        return rejectWithValue(response.status);
      }
    } catch (error) {
      console.error("Error deleting user data:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const formSlice = createSlice({
  name: "form",
  initialState: {
    formData: [], // Array to store multiple form submissions
    roles: [], // Array to store roles
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData.push(action.payload); // Push the new form data to the array
    },
    resetFormData: (state) => {
      state.formData = []; // Reset the form data array
    },
    setRoles: (state, action) => {
      state.roles = action.payload; // Set roles in the state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteFormDataAsync.fulfilled, (state, action) => {
      // Remove the deleted user from the formData array based on emp_id
      state.formData = state.formData.filter(
        (item) => item.adminUserDetail.emp_id !== action.payload
      );
    });
  },
});

export const { setFormData, resetFormData, setRoles } = formSlice.actions;

export const selectFormData = (state) => state.form.formData;
export const selectRoles = (state) => state.form.roles;

// Async action creator using Redux Thunk middleware for saving/updating form data
export const saveFormData =
  (formattedData, onClose, isNewUser, uniqueId, reset) => async (dispatch) => {
    try {
      let response;
      if (isNewUser) {
        // Create new user with POST method using axiosInstance
        response = await axiosInstance.post(
          "/api/admin/register",
          formattedData
        );
      } else {
        // Update existing user with PUT method using axiosInstance
        response = await axiosInstance.put(
          "/api/admin/user/detail/update",
          formattedData
        );
      }

      if (response && response.status === 200) {
        // Include the unique ID received from the table in the formatted data
        const userData = { ...formattedData, id: uniqueId };
        dispatch(setFormData(userData));
        onClose(); // Close the form
        reset("success");
      } else {
        console.error("Error: Unexpected response status:", response.status);
      }
    } catch (error) {
      reset(error?.response?.data?.message);
    }
  };

export default formSlice.reducer;
