import React, { useContext } from "react";
import { AssRequestContext } from "../../../context/AssRequestContext";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoSearchSharp } from "react-icons/io5";
// import { userAssRequests } from "../../data";
import Calendar from "react-calendar";
import { IoMdRefresh } from "react-icons/io";

// import Calendar from "react-calendar";

const Filters = () => {
  const {
    showDropDown,
    setShowDropDown,

    handleSearchInput,
    handleSearch,
    selectedDateForFilter,
    handleDateChange,
    fetchData,
    handleDateFilter,
  } = useContext(AssRequestContext);
  return (
    <div className="p-2">
      <div className="flex relative items-center gap-4 my-2 text-xs">
        <IoMdRefresh
          onClick={fetchData}
            className="refresh-icon text-3xl cursor-pointer bg-white rounded-md p-1 hover:text-[#02a7e4] transition-all"
        />
        <div
          onClick={() =>
            showDropDown === "DateDropDown"
              ? setShowDropDown("")
              : setShowDropDown("DateDropDown")
          }
          className="relative cursor-pointer  bg-[#FFFFFF] w-[177px] p-2 rounded-[8px] shadow-sm flex items-center justify-between"
        >
          {`Posted Date ${
            selectedDateForFilter !== null || undefined || "" ? " - " : ""
          } ${selectedDateForFilter !== null ? selectedDateForFilter : ""}`}
          <span className="text-lg">
            <IoMdArrowDropdown />
          </span>
        </div>
        <div
          className={`absolute ${
            showDropDown === "DateDropDown" ? "block" : "hidden"
          } left-11 top-10    w-[250px] z-10 bg-white rounded-[8px] shadow-md transition-all duration-500`}
        >
          <Calendar onChange={(e) => handleDateChange(e)} />
          <div className="flex justify-end items-center px-4 py-2 gap-4 text-[#65558F] font-medium">
            <button
              onClick={() => setShowDropDown("")}
              className="hover:font-bold"
            >
              Cancel
            </button>
            <button onClick={handleDateFilter} className="hover:font-bold">
              Ok
            </button>
          </div>
        </div>

        <div className="bg-[#FFFFFF] rounded-[8px] shadow-sm flex items-center text-[#84919A]">
          <div className="text-xs  flex items-center gap-2 px-2">
            <span className="text-lg">
              <IoSearchSharp />
            </span>
            <input
              onChange={(e) => handleSearchInput(e)}
              className="p-2 w-[410px] focus:outline-none"
              type="search"
              placeholder="Search by user name, or request id"
            />
          </div>
          <button
            onClick={handleSearch}
            className="px-4 py-1 mr-2 border-[1px] border-[#DDE2E4] rounded-[6px] text-[#252C32]"
          >
            Search
          </button>
        </div>
        {/* <button className="hover:underline" onClick={() => fetchData()}>
          Reset
        </button> */}
      </div>
    </div>
  );
};

export default Filters;
