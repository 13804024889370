import React from "react";
import Filters from "./components/Filters";
import ServicesList from "./components/ServicesList";
import UserAndServiceDetail from "./components/UserAndServiceDetail";

const ServiceListing = () => {
  return (
    <div className="font-Roboto">
      <h2 className="font-bold text-xl py-4">Service Listing Verification</h2>
      <div className="flex items-start ">
        <div
          className={`w-[70%] bg-[#F5F4F8] border-r-[1px] border-t-[1px] border-[#404040]
            `}
        >
          <Filters />
          <ServicesList />
          {/* <RequestsList /> */}
        </div>
        <div className="w-[30%] bg-[#F5F4F8] hide-scrollbar h-[calc(100vh-64px)] overflow-auto">
          <UserAndServiceDetail />
          {/* <UserDetails /> */}
        </div>
      </div>
    </div>
  );
};

export default ServiceListing;
