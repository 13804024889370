import Calendar from "react-calendar";
import { useContext, useState } from "react";
import { RiArrowDropDownFill } from "react-icons/ri";
import { UserContext } from "../../../context/userContext";
import { CircularProgress } from "@mui/material";

const Centerbar = () => {
  const {
    data,
    setData,
    handleDateChange,
    customersList,
    dropDown,
    setDropDown,
    handleDateFilter,
    loading,
  } = useContext(UserContext);

  const onClickHandler = (item) => {
    setData(item);
  };

  return (
    <div className="w-[70%] border-r  font-roboto border-[#404040]">
      <div className="border-b border-[#404040]">
        <h1 className="font-bold text-xl py-4">
          Customer Management - Dashboard{" "}
        </h1>
      </div>

      <div className="relative">
        {/* <div
          onClick={() => setDropDown(!dropDown)}
          className="cursor-pointer my-4 rounded-md  text-sm  bg-[#FFFFFF] w-[150px]  justify-around shadow-md p-2 font-normal text-[#202020] flex items-center"
        >
          <p>Report Date</p>
          <RiArrowDropDownFill className="text-2xl" />
        </div>
        {dropDown && (
          <div
            className={`absolute ${
              dropDown ? "block" : "hidden"
            } left-0 top-13  w-[250px] z-10 bg-white rounded-[8px] shadow-md transition-all duration-500`}
          >
            <Calendar onChange={(e) => handleDateChange(e)} />
            <div className="flex justify-end items-center px-4 py-2 gap-4 text-[#65558F] font-medium">
              <button
                onClick={() => setDropDown("")}
                className="hover:font-bold"
              >
                Cancel
              </button>
              <button onClick={handleDateFilter} className="hover:font-bold">
                Ok
              </button>
            </div>
          </div>
        )} */}

        {/* table */}
        {loading ? (
          <div className="w-full flex justify-center my-8">
            <CircularProgress style={{ color: "black" }} size={24} />
          </div>
        ) : (
          <div className="px-2 mt-4">
            <p className="text-lg font-medium my-2">{`${customersList?.length} Customers`}</p>
            <div className="overflow-x-hidden hide-scrollbar h-[80vh] overflow-y-auto">
              <table className="min-w-full   overflow-x-auto shadow-md">
                <thead className="bg-[#D9D9D9]  ">
                  <tr className="text-sm text-[#202020]  font-medium ">
                    <th className=" p-[10px] border-r-[0.3px] border-[#ffffff] ">
                      No.
                    </th>
                    <th className=" p-[10px] border-r-[0.3px] border-[#ffffff] ">
                      Name
                    </th>
                    <th className="p-[10px] border-r-[0.3px]  border-[#ffffff] ">
                      Mobile Number
                    </th>
                    <th className=" p-[10px]  border-r-[0.3px] border-[#ffffff] ">
                      E-mail
                    </th>
                    <th className="p-[10px]  border-r-[0.3px]  border-[#ffffff]">
                      User Type
                    </th>
                    <th className=" p-[10px]  w-48  ">Company Name</th>
                  </tr>
                </thead>
                <tbody className="bg-[#FFFFFF]  divide-y  divide-[#D9D9D9]  ">
                  {customersList &&
                    customersList?.map((item, index) => (
                      <tr
                        className={`text-center  ${
                          item.user_id === data?.user_id ? "bg-[#E4E0EE]" : ""
                        }  text-[#404040] font-medium text-sm cursor-pointer `}
                        key={index}
                        onClick={() => onClickHandler(item)}
                      >
                        <td className="  p-[10px]   border-r-[0.3px] border-[#D9D9D9]">
                          {index + 1}
                        </td>
                        <td className="  p-[10px]   border-r-[0.3px] border-[#D9D9D9]">
                          {item?.name && item?.name}
                        </td>
                        <td className=" p-[10px] border-r-[0.3px] border-[#D9D9D9]">
                          {item?.mobile_no && item?.mobile_no}
                        </td>
                        <td className="   p-[10px] border-r-[0.3px] border-[#D9D9D9]">
                          {item?.email && item?.email}
                        </td>
                        <td className="p-[10px]  border-r-[0.3px] border-[#D9D9D9]">
                          {item?.category && item?.category}
                        </td>
                        <td className="p-[10px]">
                          {item?.company_name && item?.company_name}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Centerbar;
