import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  IconButton,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormHelperText,
  styled,
  ListItemText,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import TextArea from "./Textarea";
import InputAdornment from "@mui/material/InputAdornment";
import Review from "./Review";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import ImageUploader from "./Image";
import { FormContext } from "../../../context/formContext";
import { FaCheck } from "react-icons/fa";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInput-underline:before": {
    borderBottomColor: "#404040", // Underline color when input is not focused
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "#404040", // Underline color on hover
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#404040", // Underline color when input is focused
  },
  "& .MuiFormHelperText-root": {
    color: "#404040", // Helper text color
  },
  "& .MuiInput-root.Mui-error .MuiInput-underline:before": {
    borderBottomColor: "#404040", // Error underline color
  },
  "& .MuiInput-root.Mui-error .MuiInput-underline:after": {
    borderBottomColor: "#404040", // Error underline color when focused
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#404040", // Error helper text color
  },
  // Customize label (legend) color
  "& .MuiInputLabel-root": {
    color: "#404040", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#404040", // Label color when focused
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: "#404040", // Error state label color
  },
}));

const CustomRadio = styled(Radio)(({ theme, checked }) => ({
  display: "none",
}));

const CustomCheckbox = styled(Checkbox)(({ theme, checked }) => ({
  display: "none",
}));

// Styled label with custom styles
const CustomFormControlLabel = styled(FormControlLabel)(
  ({ theme, checked }) => ({
    margin: "6px",
    padding: "4px 20px",
    borderRadius: "100px",
    backgroundColor: checked ? "#02a7e4" : "#F5F4F8",
    color: checked ? "white" : "black",
    fontWeight: checked ? "bold" : "normal",
    "& .MuiTypography-root": {
      margin: 0,
    },
    "&:hover": {
      backgroundColor: checked ? "#02a7e4" : "#E0E0E0",
    },
  })
);

const Propertydetails = ({
  Residentialsell,
  Residentialrent,
  ResidentialSellPlot,
  CommercialrentOffice,
  CommerRentRetailnShow,
  CommercialrentWareh,
  CommercialIndSpace,
  CommercialSellIndSp,
  CommercialSellWare,
  CommercialSellOff,
  CommercialSellRetailnShow,
}) => {
  const [currentFieldValue, setCurrentFieldValue] = useState("");
  const {
    submittedData,
    setSubmittedData,
    generatedParagraph,
    setGeneratedParagraph,
    selectedValues,
    setSelectedValues,
    textFieldValues,
    setTextFieldValues,
    customValues,
    setCustomValues,
    selectedFloor,
    setSelectedFloor,
    totalFloors,
    setTotalFloors,
    selectedAmenities,
    setSelectedAmenities,
    pricePerSqFt,
    setPricePerSqFt,
    builtUpArea,
    setBuiltUpArea,
    totalPrice,
    setTotalPrice,
    setShowPreview,
    setSelectedFurnishings,
    setPropertyAvailableFor,
    setPropertySellFor,
    setPropertycommericalrentStatusFor,
    setPropertycommericalrentretailshowStatusFor,
    setPropertySellcomInspFor,
    setPropertySellcomWarFor,
    setPropertySellcomOffspaceFor,
    setPropertySellcomRetaiAndShowroomFor,
    setPropertycommericalrentFor,
    setSelectedIds,
    setSelectedDropdownValue,
    selectedIds,
    propertyAvailableFor,
    propertySellFor,
    propertycommericalrentFor,
    propertycommericalrentStatusFor,
    propertycommericalrentretailshowStatusFor,
    propertySellcomOffspaceFor,
    propertySellcomWarFor,
    propertySellcomRetaiAndShowroomFor,
    selectedFurnishings,
    propertySellcomInspFor,
    securitydeposit,
    taggedImages,
    unit,
    handleUnitChange,
    plotFieldValue,
    setPlotFieldValue,
    showDetails,
  } = useContext(FormContext);

  const pickerResponse = useSelector(
    (state) => state.imageReducer.pickerResponse
  );

  const handleFurnishingSelect = (id) => {
    setSelectedFurnishings(id);
  };

  const handlePropertyAvailableForChange = (id, title) => {
    const rentOrLeaseTitles = ["Rent", "Lease"];
    const readyOrUnderConstructionTitles = [
      "Ready to Move",
      "Under Construction",
    ];

    if (rentOrLeaseTitles.includes(title)) {
      setPropertyAvailableFor(title);
      setPropertycommericalrentFor(title);
    }

    if (readyOrUnderConstructionTitles.includes(title)) {
      setPropertySellFor(title);
      setPropertycommericalrentStatusFor(title);
      setPropertycommericalrentretailshowStatusFor(title);
      setPropertySellcomInspFor(title);
      setPropertySellcomWarFor(title);
      setPropertySellcomOffspaceFor(title);
      setPropertySellcomRetaiAndShowroomFor(title);
    }
  };

  const handleRadioChange = (label, itemId, itemTitle) => {
    setSelectedIds((prevIds) => ({
      ...prevIds,
      [label]: itemId,
    }));

    setSelectedValues((prevValues) => ({
      ...prevValues,
      [label]: itemTitle,
    }));

    handlePropertyAvailableForChange(itemId, itemTitle);
    // handleFurnishingSelect(itemTitle);
    //
  };

  const handleDropDownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedDropdownValue(selectedValue);

    if (Residentialrent && Residentialrent.price_details) {
      const selectedOption = Residentialrent.price_details.find(
        (option) => option.inputID === selectedValue
      );
      if (selectedOption) {
        handlePropertyAvailableForChange(
          selectedOption.inputID,
          selectedOption.label
        );
      } else {
        console.warn(`No option found for inputID: ${selectedValue}`);
      }
    } else {
      console.error("Residentialrent or price_details is undefined or null.");
    }
  };

  const handleAmenitySelect = (amenityId, field) => {
    setSelectedAmenities((prevSelected) => {
      const updatedSelected = { ...prevSelected };

      if (updatedSelected[field.label]?.includes(amenityId)) {
        updatedSelected[field.label] = updatedSelected[field.label].filter(
          (id) => id !== amenityId
        );
      } else {
        updatedSelected[field.label] = [
          ...(updatedSelected[field.label] || []),
          amenityId,
        ];
      }
      const selectedTitles = field.data
        .filter((amenity) => updatedSelected[field.label]?.includes(amenity.id))
        .map((amenity) => amenity.title);

      return updatedSelected;
    });
  };

  const handleTotalPriceChange = (event) => {
    const value = event.target.value;
    setTotalPrice(value);

    updatePricePerSqFt(value, builtUpArea);
  };

  const handleBuiltUpAreaChange = (event, unit) => {
    const value = event.target.value;
    setCurrentFieldValue(value);

    if (unit === "Cent") {
      const areaValue = Math.floor(value * 435.6);
      setBuiltUpArea(areaValue);
    } else if (unit === "Acre") {
      const areaValue = Math.floor(value * 43560);
      setBuiltUpArea(areaValue);
    } else {
      setBuiltUpArea(value);
    }

    setPlotFieldValue(value + " " + unit);
  };

  const updatePricePerSqFt = (price, area) => {
    const parsedPrice = parseFloat(price);
    const parsedArea = parseFloat(area);

    if (!isNaN(parsedPrice) && !isNaN(parsedArea) && parsedArea > 0) {
      const calculatedPricePerSqFt = (parsedPrice / parsedArea).toFixed(2);

      setPricePerSqFt(calculatedPricePerSqFt);
    } else {
      setPricePerSqFt("");
    }
  };

  // const [textFieldValues, setTextFieldValues] = useState({});

  const formateDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${day} ${year}`;
  };

  // const handleTextFieldChange = (label, event) => {
  //   const value = event.target.value;

  //   const formattedValue =
  //     label === "Available From "||"Available Date" ? formateDate(value) : value;

  //   setTextFieldValues((prevValues) => ({
  //     ...prevValues,
  //     // [label]: value,
  //     [label]:
  //       label === "Maintenance Charges" ||
  //       label === "Monthly Rent" ||
  //       label === "Lease Amount"
  //         ? `₹ ${formattedValue}`
  //         : formattedValue,
  //   }));
  // };
  // const handleTextFieldChange = (label, event) => {
  //   let value = event.target.value;

  //   // Format date for "Available From" or "Available Date"
  //   if (label === "Available From" || label === "Available Date") {
  //     value = formateDate(value);
  //   }

  //   // Add rupee symbol for specific labels
  //   if (
  //     label === "Maintenance Charges" ||
  //     label === "Monthly Rent" ||
  //     label === "Lease Amount"

  //   ) {
  //     // Check if value already has the rupee symbol to avoid adding it multiple times
  //     value = value.startsWith("₹") ? value : `₹${value}`;
  //   }

  //   // Update the state using the modified value
  //   setTextFieldValues((prevValues) => ({
  //     ...prevValues,
  //     [label]: value,
  //   }));
  // };
  const handleTextFieldChange = (label, event) => {
    let value = event.target.value;

    // Format date for "Available From" or "Available Date"
    if (
      label === "Available From" ||
      label === "Available Date" ||
      label === "Available from"
    ) {
      value = formateDate(value);
    }

    // Add rupee symbol and format for specific labels
    if (
      label === "Maintenance Charges" ||
      label === "Monthly Rent" ||
      label === "Lease Amount"
    ) {
      //   // Convert the input to a number and format it as per the Indian numbering system
      //   const numericValue = parseFloat(value.replace(/,/g, "")); // Remove commas to prevent parsing issues
      //   if (!isNaN(numericValue)) {
      //     const formattedValue = new Intl.NumberFormat("en-IN").format(numericValue); // Format to Indian style
      //     value = `₹${formattedValue}`;
      //   }
      // }

      const numericValue = parseFloat(value.replace(/,/g, "")); // Remove commas to prevent parsing issues
      if (!isNaN(numericValue)) {
        // Format value in Indian numbering system
        const formattedValue = new Intl.NumberFormat("en-IN", {
          maximumFractionDigits: 0, // Optional: avoid decimal places
        }).format(numericValue);

        // Add rupee symbol in front of the formatted value
        value = `₹ ${formattedValue}`;
      }
    }

    // Update the state using the modified value
    setTextFieldValues((prevValues) => ({
      ...prevValues,
      [label]: value,
    }));
  };

  // const [totalFloors, setTotalFloors] = useState("");
  // const [selectedFloor, setSelectedFloor] = useState("");
  const handleTotalFloorsChange = (event) => {
    const value = event.target.value;

    if (value === "" || (parseInt(value) <= 200 && !isNaN(parseInt(value)))) {
      setTotalFloors(value);
    } else {
      setTotalFloors("");
    }
  };
  const floorOptions = Array.from(
    { length: parseInt(totalFloors) || 0 },
    (_, index) => ({
      id: index + 1,
      title: `${index + 1}`,
    })
  );

  // const handleFloorChange = (event) => {
  //   setSelectedFloor(event.target.value);
  // };
  const handleFloorChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedFloor(typeof value === "string" ? value.split(",") : value);
  };

  const handleSelectChange = (event, label) => {
    const selectedValue = event.target.value;

    setSelectedValues((prevState) => ({
      ...prevState,
      [label]: selectedValue,
    }));

    if (selectedValue === "Custom") {
      setCustomValues((prevState) => ({
        ...prevState,
        [label]: "",
      }));
    } else {
    }
  };

  const handleCustomChange = (event, label) => {
    const customValue = event.target.value;

    // Update the state with the custom value
    setCustomValues((prevState) => ({
      ...prevState,
      [label]: customValue,
    }));

    // Log the custom value
  };
  const selectedOptions = useSelector((state) => state.form.selectedOptions);

  const renderFields = (fields) => {
    return fields.map((field) => {
      switch (field.Type) {
        case "Box Type":
        case "Box Type2":
        case "Custom Box Type":
          return (
            <Box key={field.inputID} sx={{ mb: 2 }}>
              <Typography sx={{ fontSize: 18, margin: "8px 0" }}>
                {field.label}
              </Typography>
              <FormControl
                component="fieldset"
                required={field.required}
                error={field.required && !selectedIds[field.label]}
              >
                <FormGroup row>
                  {/* This makes the radio buttons horizontal */}
                  {field.data.map((item) => {
                    const isChecked = selectedIds[field.label] === item.id;

                    return (
                      <CustomFormControlLabel
                        key={item.id}
                        control={
                          <CustomRadio
                            checked={isChecked}
                            onChange={(event) =>
                              handleRadioChange(
                                field.label,
                                item.id,
                                item.title,
                                event.target.value
                              )
                            }
                          />
                        }
                        label={item.title}
                        checked={isChecked}
                      />
                    );
                  })}
                </FormGroup>
                {field.required && (
                  <FormHelperText>
                    {selectedIds[field.label] != null
                      ? ""
                      : `${field.label} is required`}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          );
        case "Text Field":
        case "Price Text Field":
        case "Price Text Field1":
        case "Price Text Field2":
          if (
            selectedOptions.subType === "Apartment" &&
            field.label === "Plot Area"
          ) {
            return null; // Don't render the Plot Area field for apartments
          }

          return (
            <CustomTextField
              variant="standard"
              style={{
                width: "65%",
              }}
              key={field.inputID}
              label={field.label}
              margin="normal"
              type={field.keyboard}
              required={field.required}
              error={field.required && !textFieldValues[field.label]}
              helperText={
                field.required && !textFieldValues[field.label]
                  ? `${field.label} is required`
                  : ""
              }
              onChange={(event) => handleTextFieldChange(field.label, event)} // Pass label and event to the handler
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {field.label2}
                  </InputAdornment>
                ),
              }}
            />
          );
        case "Drop down deposit":
          return (
            <FormControl
              key={field.inputID}
              fullWidth
              sx={{ mb: 2 }}
              required={field.required}
              error={field.required && !selectedValues[field.label]}
            >
              <InputLabel>{field.label}</InputLabel>
              <Select
                value={selectedValues[field.label] || ""}
                onChange={(e) => handleSelectChange(e, field.label)}
                label={field.label}
              >
                {field.data.map((item) => (
                  <MenuItem key={item.id} value={item.title}>
                    {item.title}
                  </MenuItem>
                ))}
                <MenuItem value="Custom">Custom</MenuItem>
              </Select>
              {field.required && !selectedValues[field.label] && (
                <FormHelperText>{`${field.label} is required`}</FormHelperText>
              )}

              {selectedValues[field.label] === "Custom" && (
                <CustomTextField
                  style={{
                    width: "65%",
                  }}
                  variant="standard"
                  label="Enter Amount"
                  value={customValues[field.label] || ""}
                  onChange={(e) => handleCustomChange(e, field.label)}
                  fullWidth
                  required={field.required}
                  error={field.required && !customValues[field.label]}
                  helperText={
                    field.required && !customValues[field.label]
                      ? "Custom amount is required"
                      : ""
                  }
                  margin="normal"
                />
              )}
            </FormControl>
          );
        case "calendar":
          return (
            <CustomTextField
              style={{
                width: "65%",
              }}
              variant="standard"
              key={field.inputID}
              label={field.label}
              fullWidth
              margin="normal"
              type="date"
              required={field.required}
              onChange={(event) => handleTextFieldChange(field.label, event)} // Pass label and event to the handler
              error={field.required && !textFieldValues[field.label]}
              helperText={
                field.required && !textFieldValues[field.label]
                  ? `${field.label} is required`
                  : ""
              }
            />
          );

        case "FDrop down":
          return (
            <CustomTextField
              style={{
                width: "65%",
              }}
              variant="standard"
              key={field.inputID}
              label={field.label}
              margin="normal"
              type="number"
              value={totalFloors}
              required={field.required}
              onChange={handleTotalFloorsChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Floors</InputAdornment>
                ),
              }}
              error={totalFloors === "" && field.required} // Show error if the field is required and empty
              helperText={
                field.required && !totalFloors
                  ? `${field.label} field is required`
                  : ""
              } // Custom error message
            />
          );
        // case "Drop down1":
        //   return (
        //     <CustomTextField
        //       style={{
        //         width: "65%",
        //       }}
        //       variant="standard"
        //       key={field.inputID}
        //       select
        //       required={field.required}
        //       label={field.label}
        //       margin="normal"
        //       value={selectedFloor}
        //       onChange={handleFloorChange}
        //       error={selectedFloor === "" && field.required} // Show error if the field is required and no value is selected
        //       helperText={
        //         selectedFloor === "" && field.required
        //           ? "This field is required"
        //           : ""
        //       } // Custom error message
        //     >
        //       {floorOptions.map((option) => (
        //         <MenuItem key={option.id} value={option.title}>
        //           {option.title}
        //         </MenuItem>
        //       ))}
        //     </CustomTextField>
        //   );
        case "Drop down1":
          return (
            <CustomTextField
              style={{
                width: "65%",
              }}
              variant="standard"
              key={field.inputID}
              select
              SelectProps={{
                multiple: true, // Enable multiple selection
                renderValue: (selected) => selected.join(", "), // Show selected options
              }}
              required={field.required}
              label={field.label}
              margin="normal"
              value={selectedFloor} // Make sure this is an array
              onChange={handleFloorChange}
              error={selectedFloor.length === 0 && field.required} // Show error if required and no value selected
              helperText={
                selectedFloor.length === 0 && field.required
                  ? "This field is required"
                  : ""
              } // Custom error message
            >
              {floorOptions.map((option) => (
                <MenuItem key={option.id} value={option.title}>
                  <Checkbox
                    checked={selectedFloor.indexOf(option.title) > -1}
                  />
                  <ListItemText primary={option.title} />
                </MenuItem>
              ))}
            </CustomTextField>
          );

        case "AMENITIES":
        case "Box Type4":
        case "Box Type15":

        case "Furnishing":
          // Integrate Amenities component
          return (
            <Box key={field.inputID} sx={{ mb: 2 }}>
              <Typography sx={{ fontSize: 18, margin: "8px 0" }}>
                {field.label}
              </Typography>
              {field &&
                field.data &&
                field.data.map((amenity) => (
                  <CustomFormControlLabel
                    checked={selectedAmenities[field.label]?.includes(
                      amenity.title
                    )}
                    key={amenity.id}
                    control={
                      <CustomCheckbox
                        onChange={() =>
                          handleAmenitySelect(amenity.title, field)
                        }
                      />
                    }
                    label={amenity.title}
                  />
                ))}
            </Box>
          );

        case "Plot Text Field":
          return (
            <div className="relative">
              <CustomTextField
                style={{
                  width: "65%",
                }}
                variant="standard"
                key={field.inputID}
                label={field.label}
                fullWidth
                margin="normal"
                type="number"
                value={currentFieldValue}
                onChange={(e) => handleBuiltUpAreaChange(e, unit)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <select
                        value={unit}
                        onChange={handleUnitChange}
                        label="Unit"
                      >
                        <option value="Sq.ft">Sq.ft</option>
                        <option value="Cent">cent</option>
                        <option value="Acre">acre</option>
                      </select>
                    </InputAdornment>
                  ),
                }}
                required={field.required}
                error={field.required && !builtUpArea} // Show error if the field is required and no value is provided
                helperText={
                  field.required && !builtUpArea
                    ? `${field.label} field is required`
                    : ""
                } // Custom error message
              />
              {/* <div
                onClick={handlePlotPriceBtnChange}
                className="absolute cursor-pointer p-1 text-lg text-[#02a7e4] top-[34px] rounded-md shadow-md bg-zinc-200 right-[356px]"
              >
                <FaCheck />
              </div> */}
            </div>
          );
        case "Built up Text Field":
          return (
            <CustomTextField
              style={{
                width: "65%",
              }}
              variant="standard"
              key={field.inputID}
              label={field.label}
              fullWidth
              margin="normal"
              type="number"
              value={builtUpArea}
              onChange={handleBuiltUpAreaChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Sq.ft.</InputAdornment>
                ),
              }}
              required={field.required}
              error={field.required && !builtUpArea} // Show error if the field is required and no value is provided
              helperText={
                field.required && !builtUpArea
                  ? `${field.label} field is required`
                  : ""
              } // Custom error message
            />
          );

        case "Plot Price Text Field":
          return (
            <div key={field.inputID}>
              <CustomTextField
                style={{
                  width: "65%",
                }}
                variant="standard"
                label={field.label}
                fullWidth
                margin="normal"
                type="number"
                value={totalPrice}
                onChange={handleTotalPriceChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                required={field.required}
                error={field.required && !totalPrice} // Show error if the field is required and no value is provided
                helperText={
                  field.required && !totalPrice
                    ? `${field.label} field is required`
                    : ""
                } // Custom error message
              />

              <Typography variant="body1" gutterBottom>
                Price per Sq.ft.: ₹{pricePerSqFt}
              </Typography>
            </div>
          );
        default:
          return null;
      }
    });
  };

  const renderPriceDetails = (fields) => {
    return fields.map((field) => {
      if (
        propertyAvailableFor === "Rent" &&
        (field.label === "Monthly Rent" || field.label === "Security Deposit")
      ) {
        return renderFields([field]);
      }
      if (propertyAvailableFor === "Lease" && field.label === "Lease Amount") {
        return renderFields([field]);
      }
      if (field.label === "Property available for") {
        return renderFields([field]);
      }
      if (field.label === "Negotiable") {
        return renderFields([field]);
      }
      if (field.label === "Available from") {
        return renderFields([field]);
      }
      return null;
    });
  };

  const renderPriceDetails2 = (fields) => {
    return fields.map((field) => {
      if (
        propertySellFor === "Under Construction" &&
        field.label === "Available Date"
      ) {
        return renderFields([field]);
      }

      if (field.label === "Construction Status") {
        return renderFields([field]);
      }

      if (field.label === "Price") {
        return (
          <div key={field.inputID}>
            <CustomTextField
              style={{
                width: "65%",
              }}
              variant="standard"
              label={field.label}
              fullWidth
              margin="normal"
              type="number"
              value={totalPrice}
              onChange={handleTotalPriceChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
              required={field.required}
              error={field.required && !totalPrice} // Show error if the field is required and no value is provided
              helperText={
                field.required && !totalPrice
                  ? `${field.label} field is required`
                  : ""
              } // Custom error message
            />

            <Typography variant="body1" gutterBottom>
              Price per Sq.ft.: ₹{pricePerSqFt}
            </Typography>
          </div>
        );
      }
      if (field.label === "Negotiable") {
        return renderFields([field]);
      }
      if (field.label === "Built Up Area") {
        return (
          <div key={field.inputID}>
            <Typography variant="h6">Property Details</Typography>

            <CustomTextField
              style={{
                width: "65%",
              }}
              variant="standard"
              label={field.label}
              fullWidth
              margin="normal"
              type="number"
              value={builtUpArea}
              onChange={handleBuiltUpAreaChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Sq.ft.</InputAdornment>
                ),
              }}
              required // Assuming the field is required; you can adjust this based on your requirements
              error={!builtUpArea} // Show error if no value is provided for builtUpArea
              helperText={!builtUpArea ? `${field.label} is required` : ""} // Custom error message
            />
          </div>
        );
      }

      return null;
    });
  };

  const renderPriceDetails3 = (fields) => {
    return fields.map((field) => {
      switch (field.label) {
        case "Monthly Rent":
        case "Security Deposit":
          if (propertycommericalrentFor === "Rent") {
            return renderFields([field]);
          }
          break;
        case "Lease Amount":
          if (propertycommericalrentFor === "Lease") {
            return renderFields([field]);
          }
          break;
        case "Property available for":
          return renderFields([field]);
        case "Available From":
          if (
            propertycommericalrentStatusFor === "Under Construction" ||
            propertycommericalrentretailshowStatusFor === "Under Construction"
          ) {
            return renderFields([field]);
          }
          break;
        case "Property Condition":
        case "Possession Status":
        case "Price":
        case "Negotiable":
        case "Property Description":
        case "Building / Project Name  Optional ":
        case "Built Up Area":
        case "Carpet Area":
        case "Total Floors":
        case "Select Your Floor":
        case "Lifts":
        case "Parking":
        case "Washroom":
        case "Meeting Room":
        case "min  number of seats":
        case "max  number of seats":
        case "Facing":
        case "Suitable For":
        case "Plot Area":
          return renderFields([field]);
        default:
          return null;
      }
    });
  };

  const renderPriceDetails4 = (fields) => {
    return fields.map((field) => {
      // Handling under construction properties
      if (
        propertySellcomOffspaceFor === "Under Construction" &&
        field.label === "Available From"
      ) {
        return renderFields([field]);
      }
      if (
        propertySellcomWarFor === "Under Construction" &&
        field.label === "Available From"
      ) {
        return renderFields([field]);
      }
      if (
        propertySellcomRetaiAndShowroomFor === "Under Construction" &&
        field.label === "Available From"
      ) {
        return renderFields([field]);
      }

      // Specific fields for commercial property details
      const commercialFieldLabels = [
        "Possession Status",
        "Negotiable",
        "Property Condition",
        "Built Up Area",
        "Property Description",
        "Building / Project Name  Optional ",
        "Plot Area",
        "Facing",
        "Carpet Area",
        "Parking",
        "Suitable For",
        "Lifts",
        "Meeting Room",
        "Washroom",
        "min  number of seats",
        "max  number of seats",
        "Total Floors",
        "Select Your Floor",
      ];
      if (field.label === "Plot Area") {
        return (
          <div className="relative">
            <CustomTextField
              style={{
                width: "65%",
              }}
              variant="standard"
              key={field.inputID}
              label={field.label}
              fullWidth
              margin="normal"
              type="number"
              value={currentFieldValue}
              onChange={(e) => handleBuiltUpAreaChange(e, unit)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <select
                      value={unit}
                      onChange={handleUnitChange}
                      label="Unit"
                    >
                      <option value="Sq.ft">Sq.ft</option>
                      <option value="Cent">cent</option>
                      <option value="Acre">acre</option>
                    </select>
                  </InputAdornment>
                ),
              }}
              required={field.required}
              error={field.required && !builtUpArea} // Show error if the field is required and no value is provided
              helperText={
                field.required && !builtUpArea
                  ? `${field.label} field is required`
                  : ""
              } // Custom error message
            />
            {/* <div
              onClick={handlePlotPriceBtnChange}
              className="absolute cursor-pointer p-1 text-lg text-[#02a7e4] top-[34px] rounded-md shadow-md bg-zinc-200 right-[356px]"
            >
              <FaCheck />
            </div> */}
          </div>
        );
      }
      if (field.label === "Built Up Area") {
        return (
          <div key={field.inputID}>
            <Typography variant="h6">Property Details</Typography>

            <CustomTextField
              style={{
                width: "65%",
              }}
              variant="standard"
              label={field.label}
              fullWidth
              margin="normal"
              type="number"
              value={builtUpArea}
              onChange={handleBuiltUpAreaChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Sq.ft.</InputAdornment>
                ),
              }}
              required // Assuming the field is required; adjust based on your needs
              error={!builtUpArea} // Shows error if builtUpArea is empty
              helperText={!builtUpArea ? `${field.label} is required` : ""} // Error message if field is empty
            />
          </div>
        );
      }

      if (commercialFieldLabels.includes(field.label)) {
        return renderFields([field]);
      }

      return null;
    });
  };
  const renderPriceDetailscommericaloff = (fields) => {
    return fields.map((field) => {
      const commercialFieldLabels = [
        "Negotiable",
        "Price",
        "Property Description",
      ];
      if (field.label === "Price") {
        return (
          <div key={field.inputID}>
            <CustomTextField
              style={{
                width: "65%",
              }}
              variant="standard"
              label="Price"
              fullWidth
              margin="normal"
              type="number"
              value={totalPrice}
              onChange={handleTotalPriceChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
              required // Assuming the field is required; you can adjust this based on your requirements
              error={!totalPrice} // Show error if no value is provided for totalPrice
              helperText={!totalPrice ? "Price is required" : ""} // Custom error message
            />

            <Typography variant="body1" gutterBottom>
              Price per Sq.ft.: ₹{pricePerSqFt}
            </Typography>
          </div>
        );
      }

      if (commercialFieldLabels.includes(field.label)) {
        return renderFields([field]);
      }

      return null;
    });
  };

  //generate para
  // const [generatedParagraph, setGeneratedParagraph] = useState("");

  const handleGeneratedParagraph = (paragraph) => {
    setGeneratedParagraph(paragraph);
  };

  // const [submittedData, setSubmittedData] = useState(null);
  const coverImage = pickerResponse.find((img) => img?.isCover === true);

  const handleSubmit = () => {
    const formData = {
      submittedData,
      generatedParagraph,
      selectedValues,
      textFieldValues,
      customValues,
      selectedFloor,
      totalFloors,
      selectedAmenities,
      pricePerSqFt,
      builtUpArea,
      totalPrice,
      selectedIds,
      selectedFurnishings,
      plotFieldValue,
    };

    if (pickerResponse?.length > 0) {
      if (!coverImage) {
        alert("Choose a cover image.");
        return;
      }
    }

    if (showDetails?.Residentialsell) {
      if (
        !formData?.selectedValues?.BHK ||
        !formData?.selectedValues?.Balcony ||
        !formData?.selectedValues?.Bathroom ||
        !formData?.selectedValues?.Facing ||
        !formData?.selectedValues?.Parking ||
        !formData?.selectedValues?.["Construction Status"] ||
        !formData?.selectedValues?.["Eligible for Loan"] ||
        !formData?.selectedValues?.["Flat furnishing"] ||
        !formData?.textFieldValues?.["Carpet Area"] ||
        !formData?.builtUpArea ||
        !formData?.totalPrice
      ) {
        alert("Some Required fields are missing.");
      } else {
        setShowPreview(true);
      }
    }

    if (showDetails?.ResidentialSellPlot) {
      if (
        !formData?.selectedValues?.Facing ||
        !formData?.selectedValues?.["Patta Available"] ||
        !formData?.selectedValues?.["Approval Status"] ||
        !formData?.plotFieldValue ||
        !formData?.totalPrice
      ) {
        alert("Some Required fields are missing.");
      } else {
        setShowPreview(true);
      }
    }

    if (showDetails?.Residentialrent) {
      const isPropertyAvailableForRent =
        formData?.selectedValues?.["Property available for"] === "Rent";
      const isPropertyAvailableForLease =
        formData?.selectedValues?.["Property available for"] === "Lease";
      if (
        !formData?.selectedValues?.BHK ||
        !formData?.builtUpArea ||
        !formData?.selectedValues?.["Flat furnishing"] ||
        !formData?.textFieldValues?.["Carpet Area"] ||
        !formData?.selectedValues?.Bathroom ||
        !formData?.selectedValues?.Facing ||
        !formData?.selectedValues?.["Property available for"] ||
        !formData?.textFieldValues?.["Available from"] ||
        (isPropertyAvailableForRent &&
          !formData?.textFieldValues?.["Monthly Rent"]) ||
        (isPropertyAvailableForRent &&
          !formData?.selectedValues?.["Security Deposit"]) ||
        (isPropertyAvailableForLease &&
          !formData?.textFieldValues?.["Lease Amount"])
      ) {
        alert("Some Required fields are missing.");
      } else {
        setShowPreview(true);
      }
    }

    if (showDetails?.CommercialSellOff) {
      if (
        !formData?.builtUpArea ||
        !formData?.selectedValues?.["Possession Status"] ||
        !formData?.selectedValues?.["Property Condition"] ||
        !formData?.textFieldValues?.["Carpet Area"] ||
        !formData?.totalFloors ||
        !formData?.selectedValues?.Facing ||
        !formData?.selectedFloor ||
        !formData?.totalPrice
      ) {
        alert("Some Required fields are missing.");
      } else {
        setShowPreview(true);
      }
    }

    if (showDetails?.CommercialSellRetailnShow) {
      if (
        !formData?.selectedValues?.["Possession Status"] ||
        !formData?.builtUpArea ||
        !formData?.textFieldValues?.["Carpet Area"] ||
        !formData?.totalFloors ||
        !formData?.selectedFloor ||
        !formData?.selectedValues?.Facing ||
        !formData?.totalPrice
      ) {
        alert("Some Required fields are missing.");
      } else {
        setShowPreview(true);
      }
    }

    if (showDetails?.CommercialSellWare) {
      if (
        !formData?.selectedValues?.["Possession Status"] ||
        !formData?.builtUpArea ||
        !formData?.textFieldValues?.["Carpet Area"] ||
        !formData?.selectedValues?.Facing ||
        !formData?.totalPrice
      ) {
        alert("Some Required fields are missing.");
      } else {
        setShowPreview(true);
      }
    }

    if (showDetails?.CommercialSellIndSp) {
      if (
        !formData?.selectedValues?.["Possession Status"] ||
        !formData?.builtUpArea ||
        !formData?.selectedValues?.Facing ||
        !formData?.totalPrice
      ) {
        alert("Some Required fields are missing.");
      } else {
        setShowPreview(true);
      }
    }

    if (showDetails?.CommercialRentOffice) {
      const isPropertyAvailableForRent =
        formData?.selectedValues?.["Property available for"] === "Rent";
      const isPropertyAvailableForLease =
        formData?.selectedValues?.["Property available for"] === "Lease";
      const isUnderConstruction =
        formData?.selectedValues?.["Possession Status"] ===
        "Under Construction";
      if (
        !formData?.selectedValues?.["Possession Status"] ||
        (isUnderConstruction &&
          !formData?.textFieldValues?.["Available From"]) ||
        !formData?.selectedValues?.["Property Condition"] ||
        !formData?.builtUpArea ||
        !formData?.textFieldValues?.["Carpet Area"] ||
        !formData?.totalFloors ||
        !formData?.selectedFloor ||
        !formData?.selectedValues?.Facing ||
        !formData?.selectedValues?.["Property available for"] ||
        (isPropertyAvailableForRent &&
          !formData?.textFieldValues?.["Monthly Rent"]) ||
        (isPropertyAvailableForRent &&
          !formData?.selectedValues?.["Security Deposit"]) ||
        (isPropertyAvailableForLease &&
          !formData?.textFieldValues?.["Lease Amount"])
      ) {
        alert("Some Required fields are missing.");
      } else {
        setShowPreview(true);
      }
    }

    if (showDetails?.CommercialRentRetailnShow) {
      const isPropertyAvailableForRent =
        formData?.selectedValues?.["Property available for"] === "Rent";
      const isPropertyAvailableForLease =
        formData?.selectedValues?.["Property available for"] === "Lease";
      const isUnderConstruction =
        formData?.selectedValues?.["Possession Status"] ===
        "Under Construction";
      if (
        !formData?.selectedValues?.["Possession Status"] ||
        (isUnderConstruction &&
          !formData?.textFieldValues?.["Available From"]) ||
        !formData?.builtUpArea ||
        !formData?.textFieldValues?.["Carpet Area"] ||
        !formData?.totalFloors ||
        !formData?.selectedFloor ||
        !formData?.selectedValues?.Facing ||
        !formData?.selectedValues?.["Property available for"] ||
        (isPropertyAvailableForRent &&
          !formData?.textFieldValues?.["Monthly Rent"]) ||
        (isPropertyAvailableForRent &&
          !formData?.selectedValues?.["Security Deposit"]) ||
        (isPropertyAvailableForLease &&
          !formData?.textFieldValues?.["Lease Amount"])
      ) {
        alert("Some Required fields are missing.");
      } else {
        setShowPreview(true);
      }
    }

    if (showDetails?.CommercialRentWareh) {
      const isPropertyAvailableForRent =
        formData?.selectedValues?.["Property available for"] === "Rent";
      const isPropertyAvailableForLease =
        formData?.selectedValues?.["Property available for"] === "Lease";
      const isUnderConstruction =
        formData?.selectedValues?.["Possession Status"] ===
        "Under Construction";
      if (
        !formData?.selectedValues?.["Possession Status"] ||
        (isUnderConstruction &&
          !formData?.textFieldValues?.["Available From"]) ||
        !formData?.builtUpArea ||
        !formData?.textFieldValues?.["Carpet Area"] ||
        !formData?.selectedValues?.Facing ||
        !formData?.selectedValues?.["Property available for"] ||
        (isPropertyAvailableForRent &&
          !formData?.textFieldValues?.["Monthly Rent"]) ||
        (isPropertyAvailableForRent &&
          !formData?.selectedValues?.["Security Deposit"]) ||
        (isPropertyAvailableForLease &&
          !formData?.textFieldValues?.["Lease Amount"])
      ) {
        alert("Some Required fields are missing.");
      } else {
        setShowPreview(true);
      }
    }

    if (showDetails?.CommercialIndSpace) {
      const isPropertyAvailableForRent =
        formData?.selectedValues?.["Property available for"] === "Rent";
      const isPropertyAvailableForLease =
        formData?.selectedValues?.["Property available for"] === "Lease";
      const isUnderConstruction =
        formData?.selectedValues?.["Possession Status"] ===
        "Under Construction";
      if (
        !formData?.selectedValues?.["Possession Status"] ||
        (isUnderConstruction &&
          !formData?.textFieldValues?.["Available From"]) ||
        !formData?.plotFieldValue ||
        !formData?.selectedValues?.Facing ||
        !formData?.selectedValues?.["Property available for"] ||
        (isPropertyAvailableForRent &&
          !formData?.textFieldValues?.["Monthly Rent"]) ||
        (isPropertyAvailableForRent &&
          !formData?.selectedValues?.["Security Deposit"]) ||
        (isPropertyAvailableForLease &&
          !formData?.textFieldValues?.["Lease Amount"])
      ) {
        alert("Some Required fields are missing.");
      } else {
        setShowPreview(true);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end ",
        alignItems: "center",
        // backgroundColor: "white",
        flexDirection: { md: "row", xs: "column" },
      }}
    >
      <Box sx={{ width: "100%" }}>
        {Residentialsell && (
          <>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Residential Sell Details</Typography>
              {renderFields(Residentialsell.property_details)}
            </div>

            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Price Details</Typography>
              {renderPriceDetails2(Residentialsell.price_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Additional Details</Typography>
              {renderFields(Residentialsell.additional_details)}
            </div>
          </>
        )}
        {ResidentialSellPlot && (
          <>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">
                Residential Sell Plot Details
              </Typography>
              {renderFields(ResidentialSellPlot.property_details)}
            </div>

            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Price Details</Typography>
              {renderFields(ResidentialSellPlot.price_details)}{" "}
            </div>
          </>
        )}
        {Residentialrent && (
          <>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Residential Rent Details</Typography>
              {renderFields(Residentialrent.property_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Price Details</Typography>
              {renderPriceDetails(Residentialrent.price_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Additional Details</Typography>
              {renderFields(Residentialrent.additional_details)}{" "}
            </div>
          </>
        )}
        {CommercialrentOffice && (
          <>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">
                Commercial Rent Office Details
              </Typography>
              {renderPriceDetails3(CommercialrentOffice.property_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Price Details</Typography>
              {renderPriceDetails3(CommercialrentOffice.price_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Additional Details</Typography>
              {renderFields(CommercialrentOffice.additional_details)}{" "}
            </div>
          </>
        )}
        {CommerRentRetailnShow && (
          <>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">
                Commercial Rent Retail and Showroom Details
              </Typography>
              {renderPriceDetails3(CommerRentRetailnShow.property_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Price Details</Typography>
              {renderPriceDetails3(CommerRentRetailnShow.price_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Additional Details</Typography>
              {renderFields(CommerRentRetailnShow.additional_details)}{" "}
            </div>
          </>
        )}
        {CommercialrentWareh && (
          <>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">
                Commercial Rent Warehouse Details
              </Typography>
              {renderPriceDetails3(CommercialrentWareh.property_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Price Details</Typography>
              {renderPriceDetails3(CommercialrentWareh.price_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Additional Details</Typography>
              {renderFields(CommercialrentWareh.additional_details)}{" "}
            </div>
          </>
        )}
        {CommercialIndSpace && (
          <>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">
                Commercial Industrial Space Details
              </Typography>
              {renderPriceDetails3(CommercialIndSpace.property_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Price Details</Typography>
              {renderPriceDetails3(CommercialIndSpace.price_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Additional Details</Typography>
              {renderFields(CommercialIndSpace.additional_details)}{" "}
            </div>
          </>
        )}
        {CommercialSellIndSp && (
          <>
            {" "}
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">
                Commercial Industrial Space Details
              </Typography>
              {renderPriceDetails4(CommercialSellIndSp.property_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Price Details</Typography>
              {renderPriceDetailscommericaloff(
                CommercialSellIndSp.price_details
              )}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Additional Details</Typography>
              {renderFields(CommercialSellIndSp.additional_details)}{" "}
            </div>
          </>
        )}
        {CommercialSellWare && (
          <>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Commercial Warehouse Details</Typography>
              {renderPriceDetails4(CommercialSellWare.property_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Price Details</Typography>
              {renderPriceDetailscommericaloff(
                CommercialSellWare.price_details
              )}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Additional Details</Typography>
              {renderFields(CommercialSellWare.additional_details)}{" "}
            </div>
          </>
        )}
        {CommercialSellRetailnShow && (
          <>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">
                Commercial Retail Space Details
              </Typography>
              {renderPriceDetails4(CommercialSellRetailnShow.property_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Price Details</Typography>
              {renderPriceDetailscommericaloff(
                CommercialSellRetailnShow.price_details
              )}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Additional Details</Typography>
              {renderFields(CommercialSellRetailnShow.additional_details)}{" "}
            </div>
          </>
        )}
        {CommercialSellOff && (
          <>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">
                Commercial Office Space Details
              </Typography>
              {renderPriceDetails4(CommercialSellOff.property_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Price Details</Typography>
              {/* Price per square foot display */}
              {renderPriceDetailscommericaloff(CommercialSellOff.price_details)}
            </div>
            <div className="my-4 bg-white px-8 py-4 rounded-lg">
              <Typography variant="h6">Additional Details</Typography>
              {renderFields(CommercialSellOff.additional_details)}{" "}
            </div>
          </>
        )}
        <div className="my-4 bg-white px-8 py-4 rounded-lg">
          <TextArea
            onGeneratedParagraph={handleGeneratedParagraph} // Pass the callback function
            selectedValues={selectedValues}
            textFieldValues={textFieldValues}
            customValues={customValues}
            selectedFloor={selectedFloor}
            totalFloors={totalFloors}
            selectedAmenities={selectedAmenities}
            pricePerSqFt={pricePerSqFt}
            builtUpArea={builtUpArea}
            totalPrice={totalPrice}
          />
        </div>
        <Box>
          <div className="my-4 bg-white px-8 py-4 rounded-lg">
            <Typography variant="h6" mt={4}>
              Upload Images (Max 6)
            </Typography>
            {/* \ */}
            <ImageUploader />
          </div>
        </Box>
      </Box>

      <Box sx={{ my: 4, position: "absolute", bottom: "-24px" }}>
        <Button
          sx={{
            backgroundColor: "#7DAE3F",
            borderRadius: "5px",
            ":hover": { backgroundColor: "#7DAE3F" },
          }}
          variant="contained"
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};
export default Propertydetails;
