import React, { useContext } from "react";
import { AssRequestContext } from "../../../context/AssRequestContext";

const RequestCard = ({ request }) => {
  const {
    selectedRequest,
    setSelectedRequest,
    setShowDetails,
    // handleMarkAsReviewed,
  } = useContext(AssRequestContext);

  const handleViewDetails = () => {
    setSelectedRequest(request);
    setShowDetails(true);
  };

  return (
    <div
      className={`${
        selectedRequest?.id === request?.id ? "bg-[#e4e0ee]" : "bg-white"
      } flex flex-col shadow-sm text-xs rounded-[8px] py-2`}
    >
      <div className="border-collapse text-[#404040] text-xs flex gap-3 p-2 whitespace-nowrap overflow-hidden">
        <table className="w-full border-collapse">
          <tbody>
            <tr>
              <td className="py-[2px] px-2">Name</td>
              <td className="py-[2px] px-2">:</td>
              <td className="py-[2px] px-2 truncate">
                {request?.tbl_user_reg?.name}
              </td>
            </tr>
            <tr>
              <td className="py-[2px] px-2">User Type</td>
              <td className="py-[2px] px-2">:</td>
              <td className="py-[2px] px-2 truncate">
                {request?.tbl_user_reg?.category}
              </td>
            </tr>
            <tr>
              <td className="py-[2px] px-2">Contact</td>
              <td className="py-[2px] px-2">:</td>
              <td className="py-[2px] px-2 truncate">
                {request?.tbl_user_reg?.mobile_no}
              </td>
            </tr>
            <tr>
              <td className="py-[2px] px-2">Email</td>
              <td className="py-[2px] px-2">:</td>
              <td className="py-[2px] px-2 truncate">
                {request?.tbl_user_reg?.email}
              </td>
            </tr>
            <tr>
              <td className="py-[2px] px-2">Company Name</td>
              <td className="py-[2px] px-2">:</td>
              <td className="py-[2px] px-2 truncate">
                {request?.tbl_user_reg?.company_name}
              </td>
            </tr>
            <tr>
              <td className="py-[2px] px-2">Status</td>
              <td className="py-[2px] px-2">:</td>
              <td
                className={`py-[2px] px-2 ${
                  request?.status === "Pending"
                    ? "text-[#D28A29]"
                    : "text-[#8BC83F]"
                } font-bold truncate`}
              >
                {request?.status}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="w-[88%] mx-auto h-[0.3px] my-2 bg-[#8E8E8E] bg-opacity-30"></div>
      <div className="flex items-center justify-between px-2 text-[#202020]">
        <p>{`${request?.createdAt} • ${request?.Created_time}`}</p>
        <button
          onClick={handleViewDetails}
          className="px-4 bg-[#02a7e4] py-[6px] text-white rounded-[8px] bg-opacity-80"
        >
          View Details
        </button>
      </div>
    </div>
  );
};

export default RequestCard;
