import { IoMdArrowDropdown } from "react-icons/io";
import UserDataTable from "./UserDataTable";
import { FaStar } from "react-icons/fa6";
import { useContext, useEffect, useRef } from "react";
import { UserReviewContext } from "../../../context/UserReviewContext";
import Calendar from "react-calendar";
import { IoMdRefresh } from "react-icons/io";
import socket from "../../../utils/socket";

const ratingsDropDown = [1, 2, 3, 4, 5];

const UserReviewDashboard = () => {
  const {
    handleFilterRating,
    handleDateChange,
    showDropDown,
    setShowDropDown,
    handleDateFilter,
    selectedDateForFilter,
    setSelectedDateForFilter,
    selectedRating,
    setSelectedRating,
    fetchData,
    viewTab,
    setViewTab,
    setTableData,
    setOriginalData,
    setFilteredData,
    setReviewCount,
    setCurrentUser,
    wholeData,
    setWholeData,
  } = useContext(UserReviewContext);

  const viewTabRef = useRef(viewTab);

  useEffect(() => {
    viewTabRef.current = viewTab;
  }, [viewTab]);

  useEffect(() => {
    socket.on("newRating", (data) => {
      const newData = data?.Data;
      setTableData((prev) => [newData, ...prev]);
      setOriginalData((prev) => [newData, ...prev]);
      setFilteredData((prev) => [newData, ...prev]);
      setWholeData((prev) => [newData, ...prev]);
      setReviewCount((prev) => prev + 1);
    });

    socket.on("ReviewStatusUpdate", (data) => {
      const newData = data?.Data;

      if (newData?.review_status === viewTabRef.current) {
        return;
      } else {
        setTableData((prev) => prev?.filter((prop) => prop?.id != newData?.id));
        setOriginalData((prev) =>
          prev?.filter((prop) => prop?.id != newData?.id)
        );
        setFilteredData((prev) =>
          prev?.filter((prop) => prop?.id != newData?.id)
        );
        setReviewCount((prev) => prev - 1);
        setCurrentUser(null);
      }
    });

    return () => {
      socket.off("newRating");
      socket.off("ReviewStatusUpdate");
    };
  }, []);

  return (
    <div>
      <h2 className="font-bold text-xl py-4 border-b-[1px] border-[#404040]">
        User Review - Dashboard
      </h2>
      <div className="p-2 relative">
        <div className="flex items-center gap-4 my-2 text-xs">
          <IoMdRefresh
            onClick={() => {
              fetchData();
              setSelectedDateForFilter(null);
              setSelectedRating(null);
            }}
  className="refresh-icon text-3xl cursor-pointer bg-white rounded-md p-1 hover:text-[#02a7e4] transition-all"
            />
          <div
            onClick={() =>
              showDropDown === "DateDropDown"
                ? setShowDropDown("")
                : setShowDropDown("DateDropDown")
            }
            className="relative cursor-pointer bg-[#FFFFFF] w-[230px] px-4 py-2 rounded-[8px] shadow-sm flex items-center justify-between"
          >
            {`Review Posted Date ${
              selectedDateForFilter !== null || undefined || "" ? " - " : ""
            } ${
              selectedDateForFilter !== null || undefined
                ? selectedDateForFilter
                : ""
            }`}
            <span className="text-lg">
              <IoMdArrowDropdown />
            </span>
          </div>
          <div
            className={`absolute ${
              showDropDown === "DateDropDown" ? "block" : "hidden"
            } left-[54px] top-14 z-10  w-[250px] bg-white rounded-[8px] shadow-md transition-all duration-500`}
          >
            <Calendar onChange={(e) => handleDateChange(e)} />
            <div className="flex justify-end items-center px-4 py-2 gap-4 text-[#02a7e4] font-medium">
              <button
                onClick={() => {
                  setShowDropDown("");
                  setSelectedDateForFilter(null);
                }}
                className="hover:font-bold"
              >
                Cancel
              </button>
              <button onClick={handleDateFilter} className="hover:font-bold">
                Ok
              </button>
            </div>
          </div>
          <div
            onClick={() =>
              showDropDown === "RatingsDropDown"
                ? setShowDropDown("")
                : setShowDropDown("RatingsDropDown")
            }
            className=" relative cursor-pointer bg-[#FFFFFF] w-[170px] pl-6 pr-4 py-2 rounded-[8px] shadow-sm flex items-center justify-between"
          >
            {`Ratings ${
              selectedRating !== null || undefined || "" ? " - " : ""
            } ${selectedRating !== null || undefined ? selectedRating : ""}`}
            <span className="text-lg">
              <IoMdArrowDropdown />
            </span>
            <div
              className={`absolute ${
                showDropDown === "RatingsDropDown"
                  ? "top-10 z-10"
                  : "-top-0 -z-10"
              }  left-0 flex flex-col items-center justify-center bg-white rounded-[8px] shadow-md transition-all duration-500`}
            >
              {ratingsDropDown.map((item, i) => (
                <div
                  onClick={() => handleFilterRating(item)}
                  className="cursor-pointer hover:bg-zinc-100 flex items-center gap-2 text-sm px-[33px] py-2 border-b-[#8E8E8E] border-b-[0.3px] border-opacity-30"
                  key={i}
                >
                  {[...Array(5)].map((_, i) => (
                    <div
                      key={i}
                      className={i < item ? "text-[#FBB337]" : "text-[#929292]"}
                    >
                      <FaStar />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex my-4 gap-4 text-xs font-bold">
          <button
            onClick={() => {
              setViewTab("Pending");
            }}
            className={`${
              viewTab === "Pending"
                ? "border-[0.3px] border-[#02a7e4] bg-[#02a7e4] text-white"
                : "bg-white text-black"
            } shadow-md rounded-lg px-6 py-[6px]`}
          >
            Pending
          </button>
          <button
            onClick={() => {
              setViewTab("Reviewed");
            }}
            className={`${
              viewTab === "Reviewed"
                ? "border-[0.3px] border-[#02a7e4] bg-[#02a7e4] text-white"
                : "bg-white text-black"
            } shadow-md rounded-lg px-6 py-[6px]`}
          >
            Reviewed
          </button>
        </div>
        <UserDataTable />
      </div>
    </div>
  );
};

export default UserReviewDashboard;
