export const formatDateToShow = (date) => {
  if (!date) return;

  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, "0");
  const month = d.toLocaleString("default", { month: "short" });
  const year = d.getFullYear();

  return `${day}-${month}-${year}`;
};
