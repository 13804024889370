import React, { useContext } from "react";
import Calendar from "react-calendar";
import { IoMdArrowDropdown, IoMdRefresh } from "react-icons/io";
import { IoSearchSharp } from "react-icons/io5";
import { ServiceListingContext } from "../../../context/ServiceListingContext";

const Filters = () => {
  const {
    showDropDown,
    setShowDropDown,
    handleSearchInput,
    handleDateChange,
    handleDateFilter,
    currentServiceTab,
    setCurrentServiceTab,
    handleReset,
    selectedDateForFilter,
    handleCategoryTypeFilter,
    categorySelected,
  } = useContext(ServiceListingContext);

  const serviceCategories = [
    "Electrician",
    "Packers & Movers",
    "Home Renovations",
    "Painting",
    "Construction Service",
    "Home Interior",
  ];

  const servicesTab = ["Pending", "Verified", "Rejected"];

  return (
    <div className="p-2">
      <div className="flex gap-4 mt-2 text-sm">
        {servicesTab.map((tab, i) => (
          <button
            onClick={() => setCurrentServiceTab(tab)}
            key={i}
            className={`flex-1 ${
              currentServiceTab === tab
                ? "bg-[#02a7e4] text-white"
                : "bg-white text-black"
            }  py-1 rounded-[8px] border-b-[0.3px] border-b-[#8E8E8E]`}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="w-[100%] h-[0.6px] bg-[#404040] my-4"></div>
      <div className="flex relative items-center gap-4 my-2 text-xs">
        <IoMdRefresh
          onClick={handleReset}
            className="refresh-icon text-3xl cursor-pointer bg-white rounded-md p-1 hover:text-[#02a7e4] transition-all"
        />
        <div
          onClick={() =>
            showDropDown === "DateDropDown"
              ? setShowDropDown("")
              : setShowDropDown("DateDropDown")
          }
          className="cursor-pointer  bg-[#FFFFFF] w-[170px] px-2 py-2 rounded-[8px] shadow-sm flex items-center justify-between"
        >
          {`Posted Date ${
            selectedDateForFilter !== null || undefined || "" ? " - " : ""
          } ${
            selectedDateForFilter !== null || undefined
              ? selectedDateForFilter
              : ""
          }`}
          <span className="text-lg">
            <IoMdArrowDropdown />
          </span>
        </div>
        <div
          onClick={() =>
            showDropDown === "PropertyTypeDropDown"
              ? setShowDropDown("")
              : setShowDropDown("PropertyTypeDropDown")
          }
          className="cursor-pointer relative bg-[#FFFFFF] w-[150px] px-2 py-2 rounded-[8px] shadow-sm flex items-center justify-between"
        >
          {categorySelected ? categorySelected : "Service Category"}
          <span className="text-lg">
            <IoMdArrowDropdown />
          </span>
          <div
            className={`absolute ${
              showDropDown === "PropertyTypeDropDown"
                ? "top-10 z-10"
                : "-top-0 -z-10"
            }  left-0 flex flex-col w-full items-start justify-center pl-[23px] bg-white rounded-[8px] shadow-md transition-all duration-500`}
          >
            {serviceCategories.map((category, i) => (
              <div
                key={i}
                onClick={() => handleCategoryTypeFilter(category)}
                className="cursor-pointer  hover:underline flex items-center gap-2 py-2"
              >
                {category}
              </div>
            ))}
          </div>
        </div>
        <div
          className={`absolute ${
            showDropDown === "DateDropDown" ? "block" : "hidden"
          } left-12 top-10 w-[250px] z-10 bg-white rounded-[8px] shadow-md transition-all duration-500`}
        >
          <Calendar onChange={(e) => handleDateChange(e)} />
          <div className="flex justify-end items-center px-4 py-2 gap-4 text-[#65558F] font-medium">
            <button
              onClick={() => setShowDropDown("")}
              className="hover:font-bold"
            >
              Cancel
            </button>
            <button onClick={handleDateFilter} className="hover:font-bold">
              Ok
            </button>
          </div>
        </div>
        <div className="bg-[#FFFFFF] rounded-[8px] shadow-sm flex items-center text-[#84919A]">
          <div className="text-xs  flex items-center gap-2 px-2">
            <span className="text-lg">
              <IoSearchSharp />
            </span>
            <input
              onChange={(e) => handleSearchInput(e)}
              className="p-2 w-[250px] focus:outline-none"
              type="search"
              placeholder="Search by business name or contact no."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
