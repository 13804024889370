import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { saveFormData, selectFormData } from "../../store/formSlice";
import UserProfile from "../UserProfile";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { selectImageUrl } from "../../store/imageSlice";
import { selectEmpId } from "../../store/authSlice";
import userProfile from "../../assets/userProfile.jpg";
import axiosInstance from "../../Interceptor/axiosInstance";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Form = ({ onClose, userDataFromResponse = {}, setOpen }) => {
  const [userData, setUserData] = useState(userDataFromResponse);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const formSelectorData = useSelector(selectFormData);
  const [roles, setRoles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: userDataFromResponse
      ? yup.string().nullable() // If editing, make password optional (nullable)
      : yup
          .string()
          .min(8, "Password must be at least 8 characters")
          .max(32, "Password can't be more than 32 characters")
          .required("Password is required"), // If creating, make password required
    username: yup
      .string()
      .matches(/^[a-zA-Z]+$/, "Username can only contain letters")
      .required(),
    phone: yup.string().min(10).max(10).required(),
    role: yup.string().required(),
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const profilePicUrl = useSelector(selectImageUrl);
  const empId = useSelector(selectEmpId);

const [profilePic, setProfilePic] = useState(
  userDataFromResponse?.adminUserDetail?.profile_pic
);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // user data getting from props
  useEffect(() => {
    if (userDataFromResponse) {
      setValue(
        "username",
        userDataFromResponse.adminUserDetail?.username || ""
      );
      setValue("email", userDataFromResponse.adminUserDetail?.emailId || "");
      setValue("phone", userDataFromResponse.adminUserDetail?.phn_number || "");
      setValue("role", userDataFromResponse.role?.Roles || "");
      setValue(
        "profilePic",
        userDataFromResponse.adminUserDetail?.profile_pic || ""
      );
    
      setValue(
        "password",
        userDataFromResponse.adminUserDetail?.password || ""
      );
    }
  }, [userDataFromResponse, setValue]);

  useEffect(() => {
    setUserData(userDataFromResponse); // Update userData when userDataFromResponse changes
  }, [userDataFromResponse]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.get("/api/admin/getRole");
        const data = response?.data;
        setRoles(data.message); // Update roles state with fetched roles array
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    // Fetch roles from your backend API
    fetchRoles();
  }, [dispatch]);

  const onSubmitHandler = async (data) => {
    setLoader(true);
    try {
      const formattedData = {
        username: data.username,
        email: data.email,
        password: data.password,
        role: data.role,
        phn_number: data.phone,
        profilePic: profilePicUrl || null,
        emp_id: userData?.adminUserDetail?.emp_id || null,
      };
  
      if (userDataFromResponse) {
        // Update existing user with PUT method
        await dispatch(saveFormData(formattedData, onClose, false, 0, setOpen));
      } else {
        // Create new user with POST method
        await dispatch(saveFormData(formattedData, onClose, true, 0, setOpen));
      }
  
      // Reset the profile picture state after saving
      setProfilePic(userProfile); // You can set it to userProfile or an empty string
      dispatch(selectImageUrl('')); // Reset the Redux store profile image URL as well, if necessary
  
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoader(false);
    }
  };
  
  
  const handleReset = () => {
    reset();
    setProfilePic(userProfile);
  };

  useEffect(() => {
    setProfilePic(userData?.adminUserDetail?.profile_pic);
  }, [userData]);

  return (
    <>
      {/* <h3 className="mt-2 font-roboto text-xl font-bold">Employee Management</h3>
      <br /> */}
      <div>
        <p className="m-0 mb-5 font-roboto text-base font-normal">
          Create a new user and add them to this site
        </p>
        <UserProfile profilePic={profilePic} />
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          noValidate
          className="flex flex-col mb-2 mt-2 font-roboto"
        >
          {/* Name, Email, and Role Assignment in one line */}
          <div className="flex flex-row mb-2 font-roboto">
            {/* Name */}
            <div className="w-full  mb-2 sm:mr-4">
              <div className="text-xs font-roboto 	 text-gray-700">
                <span className="text-xs font-roboto font-semibold	 text-gray-700">
                  NAME
                </span>
                <br />
                <input
                  {...register("username")}
                  placeholder="Username"
                  autoComplete="off"
                  required
                  className={`border ${
                    errors.username
                      ? "border-red-500 text-red-500"
                      : "border-gray-300"
                  } custom-lg:w-7/12 xl:w-8/12 lg:w-10/12  sm:w-44 md:w-44 rounded-lg p-2 mt-2 font-roboto outline-none`}
                />
                <p className="text-red-500">{errors.username?.message}</p>
              </div>
            </div>

            {/* Email */}
            <div className="w-full  mb-2 sm:mr-4">
              <div className="text-xs font-roboto text-gray-700">
                <span className="text-xs font-roboto font-semibold	 text-gray-700">
                  EMAIL
                </span>
                <br />
                <input
                  {...register("email")}
                  placeholder="Email"
                  autoComplete="off"
                  required
                  className={`border ${
                    errors.email
                      ? "border-red-500 text-red-500 "
                      : "border-gray-300"
                  }custom-lg:w-7/12 xl:w-8/12 lg:w-10/12  sm:w-44 md:w-44 rounded-lg p-2 mt-2 font-roboto outline-none`}
                />
                <p className="text-red-500">{errors.email?.message}</p>
              </div>
            </div>

            {/* Role Assignment */}
            <div className="w-full mb-2">
              <div className="text-xs font-roboto text-gray-700">
                <span className="text-xs font-roboto font-semibold	 text-gray-700">
                  ROLE ASSIGNMENT
                </span>
                <br />
                <select
                  {...register("role")}
                  required
                  className={`border ${
                    errors.role
                      ? "border-red-500 text-red-500"
                      : "border-gray-300"
                  } custom-lg:w-7/12 xl:w-8/12 lg:w-10/12 sm:w-44 md:w-44   rounded-lg p-2 mt-1 font-roboto outline-none`}
                >
                  <option value="">Roles</option>
                  {roles.map((role, index) => (
                    <option
                      key={index}
                      value={role.Roles}
                      selected={
                        userDataFromResponse?.role?.Roles === role.Roles
                      }
                    >
                      {role.Roles}
                    </option>
                  ))}
                </select>
                <p className="text-red-500">{errors.role?.message}</p>
              </div>
            </div>
          </div>

          <div className="flex flex-row mb-2 font-roboto">
            <div className="w-full  mb-2 sm:mr-4">
              <div className="text-xs font-roboto text-gray-700">
                <span className="text-xs font-roboto font-semibold	 text-gray-700">
                  PHONE NO
                </span>
                <br />
                <input
                  {...register("phone")}
                  placeholder="Phone"
                  autoComplete="off"
                  required
                  className={`border ${
                    errors.phone
                      ? "border-red-500 text-red-500"
                      : "border-gray-300"
                  } custom-lg:w-7/12 xl:w-8/12 lg:w-10/12  sm:w-44 md:w-44  rounded-lg p-2 mt-1 font-roboto text-xs outline-none`}
                />
                <p className="text-red-500">{errors.phone?.message}</p>
              </div>
            </div>

            {/* Email */}
            <div className="w-full mb-2 relative lg:right-2 sm:right-[5px]">
              <div className="text-xs font-roboto text-gray-700">
                <span className="text-xs font-roboto font-semibold text-gray-700">
                  PASSWORD
                </span>
                <br />
                <div className="flex items-center">
                  <input
                    {...register("password")}
                    placeholder="Password"
                    autoComplete="off"
                    type={showPassword ? "text" : "password"} // Toggle between text and password
                    required
                    className={`border ${
                      errors.password
                        ? "border-red-500 text-red-500"
                        : "border-gray-300"
                    } custom-lg:w-8/12 xl:w-8/12 lg:w-10/12 sm:w-44 md:w-44 rounded-lg p-2 mt-1 font-roboto text-xs outline-none`}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="ml-2 text-gray-500 focus:outline-none"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </button>
                </div>
                <p className="text-red-500">{errors.password?.message}</p>
              </div>
            </div>

            {/* Role Assignment */}
            <div className="w-full mb-2 flex flex-row mt-4 relative sm:left-16 md:left-12 custom-lg:left-[119px] lg:right-[10px] xl:left-[40px] ">
              <div className="w-full sm:w-auto mb-2 sm:mb-0">
                <button
                  type="button"
                  onClick={handleReset}
                  className="border-none p-2 w-full sm:w-14  lg:w-24 font-roboto text-xs font-bold"
                >
                  Reset
                </button>
              </div>
              <div className="flex items-center justify-center relative w-full sm:w-auto mb-2">
                <button
                  type="submit"
                  className={`rounded bg-green-700 text-white ${
                    loader === true ? "px-2 py-4" : " p-2"
                  } w-full sm:w-14 lg:w-24 md:w-20  border border-green-700 font-roboto text-xs font-bold`}
                >
                  {loader === true ? "" : "Save"}
                </button>
                <div
                  className={`absolute ${
                    loader === true ? "z-10" : "-z-10"
                  }  inset-0 flex items-center justify-center`}
                >
                  {loader === true ? (
                    <CircularProgress size={13} sx={{ color: "white" }} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          
        </form>

        <hr className="mt-4 w-full" />
      </div>
    </>
  );
};

Form.propTypes = {
  onClose: PropTypes.func.isRequired,
  userDataFromResponse: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.oneOf([null]),
  ]).isRequired,
};

export default Form;
