import AdListing from "./AdListing";
import UploadAd from "./UploadAd";
import { useContext } from "react";
import { AdContext } from "../../../context/AdContext";

const ManageAdCarousel = () => {
  const { viewTab, setViewTab, fetchData, setCurrentAdUpload } =
    useContext(AdContext);

  return (
    <div>
      <h3 className="font-Roboto font-bold text-[20px]">Manage Ad Carousel</h3>
      <p className="font-Inter font-bold text-[#7B7D8C] text-xs py-3">
        Home -{`>`} In-App Customization -{`>`} Manage Ad Carousel
      </p>

      <div className="flex gap-4 text-xs font-bold my-2">
        <button
          onClick={() => {
            fetchData();
            setViewTab((prev) => "Property List View Ads");
            setCurrentAdUpload({ title: "", url: "", image: "" });
          }}
          className={`${
            viewTab === "Property List View Ads"
              ? "border-[0.3px] border-[#02a7e4] bg-[#02a7e4] text-white"
              : "bg-white text-black"
          } shadow-md rounded-lg px-6 py-[6px]`}
        >
          Property List View Ads
        </button>
        <button
          onClick={() => {
            fetchData();
            setViewTab((prev) => "Property Contact View Ads");
            setCurrentAdUpload({ title: "", url: "", image: "" });
          }}
          className={`${
            viewTab === "Property Contact View Ads"
              ? "border-[0.3px] border-[#02a7e4] bg-[#02a7e4] text-white"
              : "bg-white text-black"
          } shadow-md rounded-lg px-6 py-[6px]`}
        >
          Property Contact View Ads
        </button>
      </div>
      <div className="w-full h-[1px] bg-[#404040]"></div>
      <UploadAd />

      <AdListing />
    </div>
  );
};

export default ManageAdCarousel;
