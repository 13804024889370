import { useContext } from "react";
import { UserContext } from "../../../context/userContext";

const UserDetails = () => {
  const { data } = useContext(UserContext);

  return (
    <>
      {data && (
        <div className="w-[30%] m-2 h-fit bg-[rgb(228,224,238)] mt-[104px] p-4 rounded-[15px]">
          <div className="w-[90%] mx-auto font-bold flex flex-col gap-2">
            <h1 className="border-b border-[#404040] text-[#202020] text-sm">
              User Details
            </h1>
            <p>
              Name:{" "}
              <span className="text-[#252B5C] text-[16px]">{data?.name}</span>
            </p>
            <p>
              Company Name:{" "}
              <span className="text-[#252B5C] text-[16px]">
                {data?.company_name}
              </span>
            </p>
            <p>
              Mobile Number:{" "}
              <span className="text-[#252B5C] text-[16px]">
                {data?.mobile_no}
              </span>
            </p>
            <p>
              E-mail:{" "}
              <span className="text-[#252B5C] text-[16px]">{data?.email}</span>
            </p>
            <p>
              User Type:{" "}
              <span className="text-[#252B5C] text-[16px]">
                {data?.category}
              </span>
            </p>
          </div>

          <div className="w-[90%] mx-auto font-bold mt-4 flex flex-col gap-2">
            <h1 className="border-b border-[#404040] text-[#202020] text-sm">
              Number of properties listed
            </h1>
            <p>{data.totalPropertiesPosted}</p>
            {/* <button className="bg-[#7DAE3F] mx-auto rounded-[5px] text-white px-4 py-2 text-sm font-bold text-center">
            View Profile
            </button> */}
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetails;
