import React, { useContext, useEffect, useState } from "react";
import { ReportedPropsContext } from "../../../context/ReportedPropsContext";
import { useSelector } from "react-redux";
import { selectUserPermissions } from "../../../store/authSlice";
import { CircularProgress } from "@mui/material";
import axiosInstance from "../../../Interceptor/axiosInstance";
import dummy from "../../../assets/dummy.webp";

const SinglePropertyCard = ({ property, index }) => {
  const [access, setAccess] = useState();

  const expectedPermission = "Property Management";
  const {
    selectedProperty,
    setShowDetails,
    setSelectedProperty,
    handleMarkAsReviewed,
    loader,
    reportDetails,
    setReportDetails,
  } = useContext(ReportedPropsContext);
  const userRolePermission = useSelector(selectUserPermissions);

  useEffect(() => {
    const hasAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.edit === "true"
    );

    setAccess(hasAccess);
  }, [userRolePermission]);

  const handleViewDetails = async (property) => {
    // setSelectedProperty(property);
    setShowDetails(true);
    setReportDetails(property);
    try {
      const response = await axiosInstance.get(
        `/api/admin/findId/propertyDetail/${property?.propertyID}`,
        {
          method: "GET",
        }
      );

      setSelectedProperty(response.data.Data[0]);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      onClick={() => handleViewDetails(property)}
      className={`${
        reportDetails?.reported_property_id === property?.reported_property_id
          ? "bg-[#e4e0ee]"
          : "bg-white"
      } flex items-center shadow-sm text-xs  rounded-[8px]`}
    >
      <div className="w-[42%] p-4 flex gap-3 items-start">
        <div className="w-[100px] relative">
          <span className="absolute bg-[#02a7e4] px-2 text-white rounded-r-[8px]">
            {index + 1}
          </span>
          <img
            className="w-full h-[100px] object-cover"
            src={property?.images ? property?.images[0]?.uri : dummy}
            alt="property"
          />
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="font-bold text-[#18181B]">
            {property?.building_name}
          </h4>
          <p className="font-medium text-[#71717A]">
            {property?.landmark_street}
          </p>
          <div className="font-bold">
            <span className="text-[#18181B] border-r-[1px] border-r-[#18181B] pr-1">
              {property?.price ||
                property?.monthly_rent ||
                property?.cost ||
                property?.lease_amount}
            </span>
            <span className="text-[#404040] pl-1">
              {`${property?.built_up_area} Sq.ft`}
            </span>
          </div>
        </div>
      </div>
      <div className="w-[0.3px] h-[90px] bg-[#8E8E8E] bg-opacity-30"></div>
      <div className="w-[38%] border-collapse text-[#404040] text-xs flex gap-3 p-2 whitespace-nowrap overflow-hidden">
        <table className="w-full border-collapse">
          <tbody>
            <tr>
              <td className="py-[2px] px-2 w-[50%]">Property ID</td>
              <td className="py-[2px] px-2">:</td>
              <td className="py-[2px] px-2 truncate w-[50%]">
                {property?.propertyID}
              </td>
            </tr>
            <tr>
              <td className="py-[2px] px-2 w-[50%]">Reported By</td>
              <td className="py-[2px] px-2">:</td>
              <td className="py-[2px] px-2 truncate w-[50%]">
                {property?.userData?.name}
              </td>
            </tr>
            <tr>
              <td className="py-[2px] px-2 w-[50%]">Reported Date</td>
              <td className="py-[2px] px-2">:</td>
              <td className="py-[2px] px-2 truncate w-[50%]">
                {property?.Reported_Date}
              </td>
            </tr>
            <tr>
              <td className="py-[2px] px-2 w-[50%]">Reported Reason</td>
              <td className="py-[2px] px-2">:</td>
              <td className="py-[2px] px-2 truncate w-[50%]">
                {property?.Report_issuse}
              </td>
            </tr>
            <tr>
              <td className="py-[2px] px-2 w-[50%]">Status</td>
              <td className="py-[2px] px-2">:</td>
              <td
                className={`py-[2px] px-2 ${
                  property?.status === "Pending"
                    ? "text-[#D28A29]"
                    : "text-[#8BC83F]"
                } font-bold truncate w-[50%]`}
              >
                {property?.status}
              </td>
            </tr>
            {property?.status === "Pending" ? null : (
              <>
                <tr>
                  <td className="py-[2px] px-2">Reviewed By</td>
                  <td className="py-[2px] px-2">:</td>
                  <td className="py-[2px] px-2">
                    {property?.emp_name ? property?.emp_name : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="py-[2px] px-2">Emp Id</td>
                  <td className="py-[2px] px-2">:</td>
                  <td className="py-[2px] px-2">
                    {property?.emp_id ? property?.emp_id : "-"}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="w-[0.3px] h-[90px] bg-[#8E8E8E] bg-opacity-30"></div>
      <div className="flex flex-col gap-2 w-fit mx-auto px-2 text-[#202020]">
        <button
          onClick={() => handleViewDetails(property)}
          className="px-4 bg-white py-1 rounded-[8px] border-b-[0.3px] border-b-[#8E8E8E]"
        >
          View Details
        </button>
        {access && property?.status !== "Reviewed" ? (
          <div
            onClick={() => handleMarkAsReviewed(property, index)}
            className={`${
              loader === index ? "py-3" : "py-1"
            } px-4 relative cursor-pointer rounded-[8px] border-b-[0.3px] border-b-[#8E8E8E] bg-[#CBE8A8] flex items-center justify-center`}
          >
            <button>{loader === index ? "" : "Mark as Reviewed"}</button>
            <div className="absolute inset-0 flex items-center justify-center">
              {loader === index ? <CircularProgress size={13} /> : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SinglePropertyCard;
