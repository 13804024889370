import { useContext } from "react";
import UserContextProvider, { UserContext } from "../../context/userContext";
import Centerbar from "./components/Centerbar";
import UserDetails from "./components/UserDetails";

const UserManagement = () => {
  return (
    <div className="App h-screen flex bg-[#F5F4F8] font-Roboto">
      <Centerbar />
      <UserDetails />
    </div>
  );
};

export default UserManagement;
