import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MyCustomSwitch from "../../switch/MyCustomSwitch";
import {
  Checkbox,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Button,
  makeStyles,
} from "@material-ui/core";
import { Alert, Divider, Snackbar } from "@mui/material";
import axiosInstance from "../../Interceptor/axiosInstance";

const useStyles = makeStyles(() => ({
  reset: {
    backgroundColor: "#F5F4F8",
    color: "#000",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "700",
    boxShadow: "none",
  },
  save: {
    backgroundColor: "#5E9618",
    color: "#fff",
    width: "120px",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: "700",
    fontFamily: "Roboto",
    backgroundColor: "#E4E0EE",
  },
  cell: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "700",
    padding: "0px 13px",
  },
  paper: {
    marginTop: "20px",
    padding: "0px",
    paddingInlineEnd: "20px",
    fontFamily: "Roboto",
    width: "60%",
    height: "auto",
    top: "722px",
    left: "357px",
    background: "#F5F4F8",
    boxShadow: "none",
  },
  checkbox: {
    "& .MuiCheckbox-root": {
      color: "transparent",
      borderRadius: "10px",
      "& .MuiSvgIcon-root": {
        backgroundColor: "#D9D9D9",
        borderRadius: "3px",
        fontSize: "18px",
      },
      "&.Mui-checked": {
        color: "#006CE7",
      },
    },
  },
}));

const Permission = ({ selectedRole }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [adminChecked, setAdminChecked] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [rolePermissions, setRolePermissions] = useState({});
  const [open, setOpen] = useState(false);
  const handleClose = (reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (selectedRole) {
      fetchRolePermissions(selectedRole);
    }
  }, [selectedRole]);

  const fetchRolePermissions = async (role) => {
    try {
      const response = await axiosInstance.get(
        `/api/admin/user/role/permission/${role}` // Adjust the endpoint as per your backend API
      );

      const responseData = response?.data;

      // Define a list of expected permission labels
      const expectedPermissions = [
        "Role Management",
        "Employee Management",
        "Property Management",
        "Ad Posting",
        "In App Customization",
        "Vattara Property Management",
        "Customer Support",
        "Customer Management",
        "Services",
        "Upcoming Projects Ad",
        // "Careers",
      ];

      // Transform fetched permissions to the required format
      const permissions = responseData.Permission.reduce((acc, permission) => {
        // Check if the permission label is one of the expected ones
        if (expectedPermissions.includes(permission.Permission_label)) {
          acc[permission.Permission_label] = {
            view: permission.view === "true",
            create: permission.create === "true",
            edit: permission.edit === "true",
            delete: permission.delete === "true",
          };
        }
        return acc;
      }, {});

      setRolePermissions((prevState) => ({
        ...prevState,
        [role]: permissions,
      }));

      setCheckboxStates(permissions || {});
    } catch (error) {
      console.error("Error fetching role permissions:", error);
    }
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
    setAdminChecked()
    const updatedCheckboxStates = {};
    rows.forEach((row) => {
      updatedCheckboxStates[row.Rname] = {
        view: event.target.checked,
        create: false,
        edit: false,
        delete: false,
      };
    });
    setCheckboxStates(updatedCheckboxStates);
  };

  const handleAdminChange = (event) => {
    setAdminChecked(event.target.checked);
    setChecked()
    const updatedCheckboxStates = {};
    rows.forEach((row) => {
      updatedCheckboxStates[row.Rname] = {
        view: event.target.checked,
        create: event.target.checked,
        edit: event.target.checked,
        delete: event.target.checked,
      };
    });
    setCheckboxStates(updatedCheckboxStates);
  };

  const rows = [
    { Rname: "Role Management" },
    { Rname: "Employee Management" },
    { Rname: "Vattara Property Management" },
    { Rname: "Ad Posting" },
    { Rname: "Property Management" },
    { Rname: "Upcoming Projects Ad" },
    { Rname: "Services" },
    { Rname: "In App Customization" }, // Add the new permission here
    { Rname: "Customer Support" },
    { Rname: "Customer Management" },
    // { Rname: "Careers" },
  ];

  const handleCheckboxChange = (rowName, action) => (event) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [rowName]: {
        ...prevState[rowName],
        [action]: event.target.checked,
      },
    }));

    if (!event.target.checked) {
      setChecked(false);
      setAdminChecked(false);
    }
  };

  const handleReset = () => {
    setChecked(false);
    setAdminChecked(false);
    setCheckboxStates({});
  };

  const handleSave = async () => {
    const permissionData = {
      permission: rows.map((row) => ({
        [row.Rname]: {
          view: checkboxStates[row.Rname]?.view || false,
          create: checkboxStates[row.Rname]?.create || false,
          edit: checkboxStates[row.Rname]?.edit || false,
          delete: checkboxStates[row.Rname]?.delete || false,
        },
      })),
      role: selectedRole,
    };

    try {
      const response = await axiosInstance.put(
        "/api/admin/role/permission/update",
        permissionData
      );

      const responseData = response?.data;
      

      setRolePermissions((prevState) => ({
        ...prevState,
        [selectedRole]: checkboxStates,
      }));

      fetchRolePermissions(selectedRole);
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div>
        {/* <input
          type="text"
          id="small-input"
          className="w-7/12 p-2 bg-[#D9D9D9] text-gray-900 border rounded-lg mt-2"
          placeholder="Search Permissions"
        /> */}
      </div>
      <div className="flex justify-between mt-10">
        <p className=" font-roboto font-bold text-base">
          GENERAL SERVER PERMISSION
        </p>
      </div>
      <div className="flex justify-between items-center mt-6">
        <div>
          <h1 className="font-roboto font-bold text-base">View Channels</h1>
          <span className="text-sm text-gray-500 font-roboto relative top-2">
            Allow Members to view Channels by default (Excluding private
            Channels)
          </span>
        </div>
        <MyCustomSwitch
          checked={checked}
          onChange={handleChange}
          className="relative sm:right-[350px] sm:bottom-[15px] custom-lg:right-[590px] custom-xl:right-[600px] "
        />
      </div>

      <Divider
        orientation="horizontal"
        className="w-7/12 border-b border-black relative top-6"
      />

      <div className="flex justify-between mt-14">
        <p className=" font-roboto font-bold text-base">
          GENERAL SERVER PERMISSION
        </p>
      </div>
      <div className="flex justify-between items-center mt-6">
        <div>
          <h1 className="font-roboto font-bold text-base">Administrator</h1>
          <span className="text-sm text-gray-500 font-roboto relative top-2">
            Members with this permission have every permission and also cannot
            be restricted
          </span>
        </div>
        <MyCustomSwitch
          checked={adminChecked}
          onChange={handleAdminChange}
          className="relative sm:right-[350px] sm:bottom-[15px] custom-lg:right-[590px] custom-xl:right-[600px] "
        />
      </div>

      <Divider
        orientation="horizontal"
        className="w-7/12 border-b border-black relative top-6"
      />
      <div className="mt-8">
        <TableContainer component={Paper} className={classes.paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>PERMISSIONS</TableCell>
                <TableCell>VIEW</TableCell>
                <TableCell>EDIT</TableCell>
                <TableCell>CREATE</TableCell>
                <TableCell>DELETE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.cell}
                  >
                    {row.Rname}
                  </TableCell>
                  <TableCell className={classes.checkbox}>
                    <Checkbox
                      checked={checkboxStates[row.Rname]?.view || false}
                      onChange={handleCheckboxChange(row.Rname, "view")}
                    />
                  </TableCell>
                  <TableCell className={classes.checkbox}>
                    <Checkbox
                      checked={checkboxStates[row.Rname]?.edit || false}
                      onChange={handleCheckboxChange(row.Rname, "edit")}
                    />
                  </TableCell>
                  <TableCell className={classes.checkbox}>
                    {row.Rname === "Property Management" ||
                    row.Rname === "Upcoming Projects Ad" ||
                    row.Rname === "Services" ||
                    row.Rname === "In App Customization" ||
                    row.Rname === "Customer Support" ||
                    row.Rname === "Customer Management" ? null : (
                      <Checkbox
                        checked={checkboxStates[row.Rname]?.create || false}
                        onChange={handleCheckboxChange(row.Rname, "create")}
                      />
                    )}
                  </TableCell>
                  <TableCell className={classes.checkbox}>
                    {row.Rname === "Property Management" ||
                    row.Rname === "Upcoming Projects Ad" ||
                    row.Rname === "Services" ||
                    row.Rname === "In App Customization" ||
                    row.Rname === "Customer Support" ||
                    row.Rname === "Customer Management" ? null : (
                      <Checkbox
                        checked={checkboxStates[row.Rname]?.delete || false}
                        onChange={handleCheckboxChange(row.Rname, "delete")}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Divider
        orientation="horizontal"
        className="w-7/12 border-b border-black relative top-4"
      />
      <div className="flex justify-end gap-4 mt-10 mr-[580px]">
        {selectedRole !== "Member" && (
          <>
            {" "}
            <Button className={classes.reset} onClick={handleReset}>
              Reset
            </Button>
            <Button className={classes.save} onClick={handleSave}>
              Save
            </Button>
          </>
        )}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Permissions updated successfully
        </Alert>
      </Snackbar>
    </>
  );
};

Permission.propTypes = {
  selectedRole: PropTypes.string.isRequired,
};

export default Permission;
