import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import propImg from "./assets/(1) 1.png";

export const reportedProperties = [
  {
    id: 123,
    name: "3+BHK Villa for sale in Adyar",
    address: "Sandy Paradise, 2nd main road, gandhi nagar, chennai.",
    fare: "₹ 72,000/month",
    area: "2600 Sq.ft.",
    postedDate: "08-08-2024",
    status: "Pending",
    image: propImg,
    reportedBy: "James Anderson",
    reportedDate: "15-08-2024",
    reportReason: "Duplicate Listing",
    reportStatus: "Pending",
    propType: "Residential",
  },
  {
    id: 234,
    name: "3+BHK Villa for sale in Thoraipakkam",
    address: "Sandy Paradise, 2nd main road, gandhi nagar, chennai.",
    fare: "₹ 72,000/month",
    area: "2600 Sq.ft.",
    postedDate: "08-08-2024",
    status: "Pending",
    image: propImg,
    reportedBy: "Dale Steyn",
    reportedDate: "17-08-2024",
    reportReason: "Other (Lorem ipsum lorem ipsum)",
    reportStatus: "Pending",
    propType: "Commercial",
  },
  {
    id: 456,
    name: "3+BHK Villa for sale in Perungudi",
    address: "Sandy Paradise, 2nd main road, gandhi nagar, chennai.",
    fare: "₹ 72,000/month",
    area: "2600 Sq.ft.",
    postedDate: "08-08-2024",
    status: "Pending",
    image: propImg,
    reportedBy: "Trent Boult",
    reportedDate: "17-08-2024",
    reportReason: "Duplicate Listing",
    reportStatus: "Pending",
    propType: "Residential",
  },
  {
    id: 567,
    name: "3+BHK Villa for sale in Palavakkam",
    address: "Sandy Paradise, 2nd main road, gandhi nagar, chennai.",
    fare: "₹ 72,000/month",
    area: "2600 Sq.ft.",
    postedDate: "08-08-2024",
    status: "Pending",
    image: propImg,
    reportedBy: "Mitch Starc",
    reportedDate: "18-08-2024",
    reportReason: "Other (Lorem ipsum lorem ipsum)",
    reportStatus: "Reviewed",
    propType: "Commercial",
  },
  {
    id: 678,
    name: "3+BHK Villa for sale in Adyar",
    address: "Sandy Paradise, 2nd main road, gandhi nagar, chennai.",
    fare: "₹ 72,000/month",
    area: "2600 Sq.ft.",
    postedDate: "08-08-2024",
    status: "Pending",
    image: propImg,
    reportedBy: "Joe Root",
    reportedDate: "15-08-2024",
    reportReason: "Duplicate Listing",
    reportStatus: "Reviewed",
    propType: "Residential",
  },
];

export const userAssRequests = [
  {
    id: 123,
    name: "Josh Hazlewood",
    userType: "Owner",
    contact: "+919876543210",
    email: "example@gmail.com",
    companyName: "-",
    status: "Pending",
    dateAndTime: "08-08-2024 • 00:45",
  },
  {
    id: 234,
    name: "Mitch Starc",
    userType: "Owner",
    contact: "+919876543210",
    email: "example@gmail.com",
    companyName: "-",
    status: "Pending",
    dateAndTime: "08-08-2024 • 00:45",
  },
  {
    id: 456,
    name: "Pat Cummins",
    userType: "Owner",
    contact: "+919876543210",
    email: "example@gmail.com",
    companyName: "-",
    status: "Pending",
    dateAndTime: "08-08-2024 • 00:45",
  },
  {
    id: 789,
    name: "James Anderson",
    userType: "Owner",
    contact: "+919876543210",
    email: "example@gmail.com",
    companyName: "-",
    status: "Pending",
    dateAndTime: "08-08-2024 • 00:45",
  },
  {
    id: 490,
    name: "Trent Boult",
    userType: "Owner",
    contact: "+919876543210",
    email: "example@gmail.com",
    companyName: "-",
    status: "Pending",
    dateAndTime: "08-08-2024 • 00:45",
  },
  {
    id: 561,
    name: "Ismail Fazil",
    userType: "Owner",
    contact: "+919876543210",
    email: "example@gmail.com",
    companyName: "-",
    status: "Pending",
    dateAndTime: "08-08-2024 • 00:45",
  },
];

export const formData = {
  basic_details: [
    {
      Type: "Custom Box Type",
      data: [
        { id: 1, title: "Residential" },
        { id: 2, title: "Commercial" },
      ],
      label: "Property Type",
      inputID: 1,
      required: true,
    },
    {
      Type: "Custom Box Type",
      data: [
        { id: 1, title: "Sell" },
        { id: 2, title: "Rent/Lease" },
      ],
      label: "Looking To",
      inputID: 2,
      required: true,
      isvisible: true,
    },
    {
      Type: "Drop down",
      data: [], // This will be populated dynamically in the component
      label: "Property SubType",
      inputID: 638112,
      required: true,
      isvisible: true,
    },
    {
      Type: "Drop down",
      data: [
        {
          id: 1,
          title: "Ariyalur",
          name_tamil: "அரியலூர்",
        },
        {
          id: 2,
          title: "Erode",
          name_tamil: "ஈரோடு",
        },
        {
          id: 3,
          title: "Nilgiris",
          name_tamil: "உதகமண்டலம்",
        },
        {
          id: 4,
          title: "Cuddalore",
          name_tamil: "கடலூர்",
        },
        {
          id: 5,
          title: "Karur",
          name_tamil: "கரூர்",
        },
        {
          id: 6,
          title: "Kallakurichi",
          name_tamil: "கள்ளக்குறிச்சி",
        },
        {
          id: 7,
          title: "Kanchipuram",
          name_tamil: "காஞ்சிபுரம்",
        },
        {
          id: 8,
          title: "Krishnagiri",
          name_tamil: "கிருஷ்ணகிரி",
        },
        {
          id: 9,
          title: "Coimbatore",
          name_tamil: "கோயம்புத்தூர்",
        },
        {
          id: 10,
          title: "Sivaganga",
          name_tamil: "சிவகங்கை",
        },
        {
          id: 11,
          title: "Chengalpattu",
          name_tamil: "செங்கல்பட்டு",
        },
        {
          id: 12,
          title: "Chennai",
          name_tamil: "சென்னை",
        },
        {
          id: 13,
          title: "Salem",
          name_tamil: "சேலம்",
        },
        {
          id: 14,
          title: "Thanjavur",
          name_tamil: "தஞ்சாவூர்",
        },
        {
          id: 15,
          title: "Dharmapuri",
          name_tamil: "தர்மபுரி",
        },
        {
          id: 16,
          title: "Dindigul",
          name_tamil: "திண்டுக்கல்",
        },
        {
          id: 17,
          title: "Tiruchirappalli",
          name_tamil: "திருச்சி",
        },
        {
          id: 18,
          title: "Tirunelveli",
          name_tamil: "திருநெல்வேலி",
        },
        {
          id: 19,
          title: "Thirupattur",
          name_tamil: "திருப்பத்தூர்",
        },
        {
          id: 20,
          title: "Tiruppur",
          name_tamil: "திருப்பூர்",
        },
        {
          id: 21,
          title: "Tiruvannamalai",
          name_tamil: "திருவண்ணாமலை",
        },
        {
          id: 22,
          title: "Tiruvallur",
          name_tamil: "திருவள்ளூர்",
        },
        {
          id: 23,
          title: "Thiruvarur",
          name_tamil: "திருவாரூர்",
        },
        {
          id: 24,
          title: "Thoothukudi",
          name_tamil: "தூத்துக்குடி",
        },
        {
          id: 25,
          title: "Tenkasi",
          name_tamil: "தென்காசி",
        },
        {
          id: 26,
          title: "Theni",
          name_tamil: "தேனி",
        },
        {
          id: 27,
          title: "Nagapattinam",
          name_tamil: "நாகப்பட்டினம்",
        },
        {
          id: 28,
          title: "Kanniyakumari",
          name_tamil: "நாகர்கோயில்",
        },
        {
          id: 29,
          title: "Namakkal",
          name_tamil: "நாமக்கல்",
        },
        {
          id: 30,
          title: "Pudukkottai",
          name_tamil: "புதுக்கோட்டை",
        },
        {
          id: 31,
          title: "Perambalur",
          name_tamil: "பெரம்பலூர்",
        },
        {
          id: 32,
          title: "Madurai",
          name_tamil: "மதுரை",
        },
        {
          id: 33,
          title: "Mayiladuthurai",
          name_tamil: "மயிலாடுதுறை",
        },
        {
          id: 34,
          title: "Ranipet",
          name_tamil: "ராணிப்பேட்டை",
        },
        {
          id: 35,
          title: "Ramanathapuram",
          name_tamil: "ராமநாதபுரம்",
        },
        {
          id: 36,
          title: "Virudhunagar",
          name_tamil: "விருதுநகர்",
        },
        {
          id: 37,
          title: "Viluppuram",
          name_tamil: "விழுப்புரம்",
        },
        {
          id: 38,
          title: "Vellore",
          name_tamil: "வேலூர்",
        },
      ],
      label: "City",
      inputID: 3650150,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Locality",
      inputID: 4,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Landmark/Street",
      inputID: 50102340,
      required: true,
      isvisible: true,
    },
  ],
};

export const propertyTypeOptions = {
  Residential: {
    Sell: [
      "Apartment",
      "Villa",
      "Independent House",
      "Plot / Land" || "Plot / Land",
    ],
    Rent: ["Apartment", "Villa", "Independent House"],
  },
  Commercial: {
    Sell: [
      "Office Space",
      "Retail Shop",
      "Showroom",
      "Warehouse",
      "Industrial Space",
    ],
    Rent: [
      "Office Space",
      "Retail Shop",
      "Showroom",
      "Warehouse",
      "Industrial Space",
    ],
  },
};
export const Residentialrent = {
  additional_details: [
    {
      Type: "AMENITIES",
      data: [
        {
          id: 2,
          title: "Lift",
          // icon: "<Furnishing />",
          // activeicon: "<FurnishingW />",
        },
        {
          id: 1,
          title: "CCTV",
          icon: "<CCTV />",
          activeicon: "<CCTVW />",
          // icon: "<CCTV />",
          // activeicon: "<CCTVW />",
        },
        {
          id: 3,
          title: "Community Hall",
          icon: "<Ups />",
          activeicon: "<UpsW />",
        },
        {
          id: 4,
          title: "Swimming Pool",
          icon: "<PowerBackup />",
          activeicon: "<PowerBackupW />",
        },
        {
          id: 34,
          title: "Kids Area",
          icon: "<WaterSupply />",
          activeicon: "<WaterSupplyW />",
        },
        {
          id: 12,
          title: "sports",
          icon: "<Cafeteria />",
          activeicon: "<CafeteriaW />",
        },
        {
          id: 6,
          title: "Gym",
          icon: "<Internet />",
          activeicon: "<InternetW />",
        },
        {
          id: 7,
          title: "Water Supply",
          icon: "<Duct />",
          activeicon: "<DuctW />",
        },
        {
          id: 8,
          title: "Intercom",
          icon: "<Vastu />",
          activeicon: "<VastuW />",
        },
        {
          id: 11,
          title: "Gated Community",
          icon: "<Security />",
          activeicon: "<SecurityW />",
        },
        {
          id: 13,
          title: "Pet Allowed",
          icon: "<Waterstorage />",
          activeicon: "<WaterstorageW />",
        },
        {
          id: 9,
          title: "Power Back Up",
          icon: "<FireSens />",
          activeicon: "<FireSensW />",
        },
        {
          id: 5,
          title: "Club House",
          icon: "<AirCondit />",
          activeicon: "<AirConditW />",
        },
        {
          id: 16,
          title: "Garden",
          icon: "<Pantry />",
          activeicon: "<PantryW />",
        },
        // {
        //   id: 17,
        //   title: 'Fire NOC Certified',
        //   icon: '<FireNoc />',
        //   activeicon: '<FireNocW />',
        // },
        // {
        //   id: 14,
        //   title: 'DG Availability',
        //   icon: '<DG />',
        //   activeicon: '<DGW />',
        // },
        // {
        //   id: 10,
        //   title: 'Fire extinguishers',
        //   icon: '<FireExt />',
        //   activeicon: '<FireExtW />',
        // },
        // {
        //   id: 15,
        //   title: 'Reception Area',
        //   icon: '<Reception />',
        //   activeicon: '<ReceptionW />',
        // },
        // {
        //   id: 18,
        //   title: 'Occupancy Certificate',
        //   icon: '<FireNoc />',
        //   activeicon: '<FireNocW />',
        // },
      ],
      label: "AMENITIES",
      inputID: "9832013",
      required: false,
      isvisible: true,
    },
    {
      Type: "AMENITIES",
      data: [
        {
          id: 2,
          title: "Dining Table",
          // icon: "<Furnishing />",
          // activeicon: "<FurnishingW />",
        },
        {
          id: 1,
          title: "Microwave",
          icon: "<CCTV />",
          activeicon: "<CCTVW />",
          // icon: "<CCTV />",
          // activeicon: "<CCTVW />",
        },
        {
          id: 3,
          title: "Sofa",
          icon: "<Ups />",
          activeicon: "<UpsW />",
        },
        {
          id: 4,
          title: "Stove",
          icon: "<PowerBackup />",
          activeicon: "<PowerBackupW />",
        },
        {
          id: 34,
          title: "Cupboard",
          icon: "<WaterSupply />",
          activeicon: "<WaterSupplyW />",
        },
        {
          id: 12,
          title: "Fridge",
          icon: "<Cafeteria />",
          activeicon: "<CafeteriaW />",
        },
        {
          id: 6,
          title: "Television",
          icon: "<Internet />",
          activeicon: "<InternetW />",
        },
        {
          id: 7,
          title: "Water Purifier",
          icon: "<Duct />",
          activeicon: "<DuctW />",
        },
        {
          id: 8,
          title: "AC",
          icon: "<Vastu />",
          activeicon: "<VastuW />",
        },
        {
          id: 11,
          title: "Washing Machine",
          icon: "<Security />",
          activeicon: "<SecurityW />",
        },
        {
          id: 13,
          title: "Gas Pipe",
          icon: "<Waterstorage />",
          activeicon: "<WaterstorageW />",
        },
        // {
        //   id: 9,
        //   title: 'Fire sensors',
        //   icon: '<FireSens />',
        //   activeicon: '<FireSensW />',
        // },
        // {
        //   id: 5,
        //   title: 'Central Air Conditioning',
        //   icon: '<AirCondit />',
        //   activeicon: '<AirConditW />',
        // },
        // {
        //   id: 16,
        //   title: 'Pantry',
        //   icon: '<Pantry />',
        //   activeicon: '<PantryW />',
        // },
        // {
        //   id: 17,
        //   title: 'Fire NOC Certified',
        //   icon: '<FireNoc />',
        //   activeicon: '<FireNocW />',
        // },
        // {
        //   id: 14,
        //   title: 'DG Availability',
        //   icon: '<DG />',
        //   activeicon: '<DGW />',
        // },
        // {
        //   id: 10,
        //   title: 'Fire extinguishers',
        //   icon: '<FireExt />',
        //   activeicon: '<FireExtW />',
        // },
        // {
        //   id: 15,
        //   title: 'Reception Area',
        //   icon: '<Reception />',
        //   activeicon: '<ReceptionW />',
        // },
        // {
        //   id: 18,
        //   title: 'Occupancy Certificate',
        //   icon: '<FireNoc />',
        //   activeicon: '<FireNocW />',
        // },
      ],
      label: "Furnishing",
      inputID: "98320112",
      required: false,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        { id: 1, title: "1" },
        {
          id: 2,
          title: "2",
        },
        {
          id: 3,
          title: "3",
        },
        {
          id: 4,
          title: "3+",
        },
      ],
      label: "Bathroom",
      inputID: 20379,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 0,
          title: "0",
        },
        {
          id: 1,
          title: "1",
        },
        {
          id: 2,
          title: "2",
        },
        {
          id: 3,
          title: "3",
        },
        {
          id: 4,
          title: "3+",
        },
      ],
      label: "Balcony",
      inputID: 98564,
      required: false,
      isvisible: true,
    },
    {
      Type: "Box Type4",
      data: [
        {
          id: 1,
          title: "Family",
        },
        {
          id: 2,
          title: "Bachelors",
        },
        {
          id: 3,
          title: "Corporate",
        },
      ],
      label: "Preferred Tenant Type",
      inputID: 41938,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Parking",
      inputID: 80594,
      required: false,
      isvisible: true,
    },

    {
      Type: "Text Field",
      data: [],
      label: "Carpet Area",
      label2: "Sq.ft",
      keyboard: "number",
      inputID: 75491,
      required: true,
      isvisible: true,
    },
    {
      Type: "Price Text Field",
      data: [],
      label: "Maintenance Charges",
      maxlen: 12,
      keyboard: "number",
      label3: "per month",
      inputID: 93058,
      required: false,
      isvisible: true,
    },

    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "North",
        },
        {
          id: 2,
          title: "East",
        },
        {
          id: 3,
          title: "West",
        },
        {
          id: 4,
          title: "South",
        },
      ],
      label: "Facing",
      inputID: 57934,
      required: true,
      isvisible: true,
    },

    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Servant Room",
      inputID: 67539,
      required: false,
      isvisible: true,
    },
    {
      Type: "Text Area",
      data: [],
      label: "Property Description",
      inputID: 46823,
      required: false,
      isvisible: true,
    },

    {
      Type: "Form Button",
      label: "Back",
      PrevPage: "PriceDetails",
    },
  ],
  price_details: [
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Rent",
        },
        {
          id: 2,
          title: "Lease",
        },
      ],
      label: "Property available for",
      inputID: 78956,
      required: true,
      isvisible: true,
    },
    {
      Type: "Price Text Field1",
      data: [],
      label: "Monthly Rent",
      keyboard: "number",
      maxlen: 11,
      inputID: 75428,
      required: true,
      isvisible: true,
    },
    {
      Type: "Price Text Field2",
      data: [],
      label: "Lease Amount",
      maxlen: 11,
      keyboard: "number",
      inputID: 75422,
      required: true,
      isvisible: true,
    },

    {
      Type: "calendar",
      data: [],
      label: "Available from",
      inputID: 60937,
      required: true,
      isvisible: true,
    },

    {
      Type: "Drop down deposit",
      data: [
        {
          id: 1,
          title: "1 Month",
        },
        {
          id: 2,
          title: "2 Months",
        },
        {
          id: 3,
          title: "3 Months",
        },
        {
          id: 4,
          title: "4 Months",
        },
        {
          id: 5,
          title: "5 Months",
        },
        {
          id: 6,
          title: "6 Months",
        },
        {
          id: 7,
          title: "7 Months",
        },
        {
          id: 8,
          title: "8 Months",
        },
        {
          id: 9,
          title: "9 Months",
        },
        {
          id: 10,
          title: "10 Months",
        },
        {
          id: 11,
          title: "11 Months",
        },
        {
          id: 12,
          title: "12 Months",
        },
      ],
      title: "Security Deposit",
      label: "Security Deposit",
      // icon: "<ArrowBackIosIcon/>",
      inputID: 80214,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Negotiable",
      inputID: 406502,
      required: false,
      isvisible: true,
    },
  ],
  property_details: [
    {
      Type: "Text Field",
      data: [],
      label: "Building / Project Name  Optional ",
      inputID: 98234,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Locality',
    //   inputID: 76129,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "1RK",
        },
        {
          id: 2,
          title: "1BHK",
        },
        {
          id: 3,
          title: "2BHK",
        },
        {
          id: 4,
          title: "3BHK",
        },
        {
          id: 5,
          title: "3BHK+",
        },
      ],
      label: "BHK",
      inputID: 54876,
      required: true,
      isvisible: true,
    },
    {
      Type: "Built up Text Field",
      data: [],
      label: "Built Up Area",
      keyboard: "number",
      label2: "Sq.ft",
      inputID: 30291,
      required: true,
      isvisible: true,
    },

    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Fully Furnished",
        },
        {
          id: 2,
          title: "Semi Furnished",
        },
        {
          id: 3,
          title: "Unfurnished",
        },
      ],
      label: "Flat furnishing",
      inputID: 58742,
      required: true,
      isvisible: true,
    },
  ],
  id: 8,
};

export const Residentialsell = {
  additional_details: [
    {
      Type: "AMENITIES",
      data: [
        {
          id: 2,
          title: "Lift",
          // icon: "<Furnishing />",
          // activeicon: "<FurnishingW />",
        },
        {
          id: 1,
          title: "CCTV",
          icon: "<CCTV />",
          activeicon: "<CCTVW />",
          // icon: "<CCTV />",
          // activeicon: "<CCTVW />",
        },
        {
          id: 3,
          title: "Community Hall",
          icon: "<Ups />",
          activeicon: "<UpsW />",
        },
        {
          id: 4,
          title: "Swimming Pool",
          icon: "<PowerBackup />",
          activeicon: "<PowerBackupW />",
        },
        {
          id: 34,
          title: "Kids Area",
          icon: "<WaterSupply />",
          activeicon: "<WaterSupplyW />",
        },
        {
          id: 12,
          title: "sports",
          icon: "<Cafeteria />",
          activeicon: "<CafeteriaW />",
        },
        {
          id: 6,
          title: "Gym",
          icon: "<Internet />",
          activeicon: "<InternetW />",
        },
        {
          id: 7,
          title: "Water Supply",
          icon: "<Duct />",
          activeicon: "<DuctW />",
        },
        {
          id: 8,
          title: "Intercom",
          icon: "<Vastu />",
          activeicon: "<VastuW />",
        },
        {
          id: 11,
          title: "Gated Community",
          icon: "<Security />",
          activeicon: "<SecurityW />",
        },
        {
          id: 13,
          title: "Pet Allowed",
          icon: "<Waterstorage />",
          activeicon: "<WaterstorageW />",
        },
        {
          id: 9,
          title: "Power Back Up",
          icon: "<FireSens />",
          activeicon: "<FireSensW />",
        },
        {
          id: 5,
          title: "Club House",
          icon: "<AirCondit />",
          activeicon: "<AirConditW />",
        },
        {
          id: 16,
          title: "Garden",
          icon: "<Pantry />",
          activeicon: "<PantryW />",
        },
      ],
      label: "AMENITIES",
      inputID: 9832013,
      required: false,
      isvisible: true,
    },
    {
      Type: "AMENITIES",
      data: [
        {
          id: 2,
          title: "Dining Table",
          // icon: "<Furnishing />",
          // activeicon: "<FurnishingW />",
        },
        {
          id: 1,
          title: "Microwave",
          icon: "<CCTV />",
          activeicon: "<CCTVW />",
          // icon: "<CCTV />",
          // activeicon: "<CCTVW />",
        },
        {
          id: 3,
          title: "Sofa",
          icon: "<Ups />",
          activeicon: "<UpsW />",
        },
        {
          id: 4,
          title: "Stove",
          icon: "<PowerBackup />",
          activeicon: "<PowerBackupW />",
        },
        {
          id: 34,
          title: "Cupboard",
          icon: "<WaterSupply />",
          activeicon: "<WaterSupplyW />",
        },
        {
          id: 12,
          title: "Fridge",
          icon: "<Cafeteria />",
          activeicon: "<CafeteriaW />",
        },
        {
          id: 6,
          title: "Television",
          icon: "<Internet />",
          activeicon: "<InternetW />",
        },
        {
          id: 7,
          title: "Water Purifier",
          icon: "<Duct />",
          activeicon: "<DuctW />",
        },
        {
          id: 8,
          title: "AC",
          icon: "<Vastu />",
          activeicon: "<VastuW />",
        },
        {
          id: 11,
          title: "Washing Machine",
          icon: "<Security />",
          activeicon: "<SecurityW />",
        },
        {
          id: 13,
          title: "Gas Pipe",
          icon: "<Waterstorage />",
          activeicon: "<WaterstorageW />",
        },
      ],
      label: "Furnishing",
      inputID: 98320112,
      required: false,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "1",
        },
        {
          id: 2,
          title: "2",
        },
        {
          id: 3,
          title: "3",
        },
        {
          id: 4,
          title: "3+",
        },
      ],
      label: "Bathroom",
      inputID: 543,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 0,
          title: "0",
        },
        {
          id: 1,
          title: "1",
        },
        {
          id: 2,
          title: "2",
        },
        {
          id: 3,
          title: "3",
        },
        {
          id: 4,
          title: "3+",
        },
      ],
      label: "Balcony",
      inputID: 222,
      required: true,
      isvisible: true,
    },

    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Parking",
      inputID: 5650,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Carpet Area",
      keyboard: "number",
      label2: "Sq.ft",
      inputID: 610,
      required: true,
      isvisible: true,
    },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "North",
        },
        {
          id: 2,
          title: "East",
        },
        {
          id: 3,
          title: "West",
        },
        {
          id: 4,
          title: "South",
        },
      ],
      label: "Facing",
      inputID: 231,
      required: true,
      isvisible: true,
    },

    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Servant Room",
      inputID: 565,
      required: false,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Eligible for Loan",
      inputID: 5655,
      required: true,
      isvisible: true,
    },

    {
      Type: "Text Area",
      data: [],
      label: "Property Description",
      inputID: 613,
      required: false,
      isvisible: true,
    },
  ],
  price_details: [
    {
      Type: "Price Text Field",
      data: [],
      label: "Price",
      keyboard: "number",
      inputID: 128,
      maxlen: 16,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type2",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Negotiable",
      inputID: 406505,
      required: false,
      isvisible: true,
    },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Ready to Move",
        },
        {
          id: 2,
          title: "Under Construction",
        },
      ],
      label: "Construction Status",
      inputID: 129,
      required: true,
      isvisible: true,
    },
    {
      Type: "calendar",
      data: [],
      label: "Available Date",
      inputID: 60567,
      required: true,
      isvisible: true,
    },
  ],
  property_details: [
    {
      Type: "Text Field",
      data: [],
      label: "Building / Project Name  Optional ",
      inputID: 235,
      required: false,
      isvisible: false,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Locality',
    //   inputID: 236,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "1RK",
        },
        {
          id: 2,
          title: "1BHK",
        },
        {
          id: 3,
          title: "2BHK",
        },
        {
          id: 4,
          title: "3BHK",
        },
        {
          id: 5,
          title: "3BHK+",
        },
      ],
      label: "BHK",
      inputID: 234,
      required: true,
      isvisible: true,
    },
    {
      Type: "Built up Text Field",
      data: [],
      label: "Built Up Area",
      keyboard: "number",
      label2: "Sq.ft",
      inputID: 237,
      required: true,
      isvisible: true,
    },
 
    {
      Type: "Text Field",
      data: [],
      label: "Plot Area",
      keyboard: "number-pad",

      label2: "Sq.ft",
      inputID: 301945,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Fully Furnished",
        },
        {
          id: 2,
          title: "Semi Furnished",
        },
        {
          id: 3,
          title: "Unfurnished",
        },
      ],
      label: "Flat furnishing",
      inputID: 330,
      required: true,
      isvisible: true,
    },
  ],
  id: 11,
};

export const ResidentialSellPlot = {
  additional_details: null,
  price_details: [
    {
      Type: "Plot Price Text Field",
      data: [],
      label: "Plot Price",
      keyboard: "number",
      inputID: 91,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type2",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Negotiable",
      inputID: 406510,
      required: false,
      isvisible: true,
    },
    {
      Type: "Text Area",
      data: [],
      label: "Property Description",
      inputID: 468232,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Immediate',
    //     },
    //     {
    //       id: 2,
    //       title: 'Available From',
    //     },
    //   ],
    //   label: 'Possession Status',
    //   inputID: 93,
    //   required: true,
    //   isvisible: true,
    // },
  ],
  property_details: [
    {
      Type: "Text Field",
      data: [],
      label: "Building / Project Name  Optional ",
      inputID: 455,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Locality',
    //   inputID: 446,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: "Plot Text Field",
      data: [],
      label: "Plot Area",
      keyboard: "number",
      inputID: 477,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Length",
      keyboard: "number",
      label2: "ft.",
      inputID: 435,
      required: false,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Width",
      label2: "ft.",
      keyboard: "number",
      inputID: 39,
      required: false,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "DTCP Approved",
        },
        {
          id: 2,
          title: "Panchayat Approved",
        },
        {
          id: 3,
          title: "CMDA Approved",
        },
        {
          id: 4,
          title: "Un Approved",
        },
      ],
      label: "Approval Status",
      inputID: 444,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Patta Available",
      inputID: 30,
      required: true,
      isvisible: true,
    },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "North",
        },
        {
          id: 2,
          title: "East",
        },
        {
          id: 3,
          title: "West",
        },
        {
          id: 4,
          title: "South",
        },
      ],
      label: "Facing",
      inputID: 57914,
      required: true,
      isvisible: true,
    },
  ],
  id: 12,
};

export const CommercialRenttype = {
  property_drop_detail: [
    {
      Type: "Drop down",
      data: [
        {
          id: 1,
          title: "Retail Shop",
        },
        {
          id: 2,
          title: "Office Space",
        },
        {
          id: 3,
          title: "Showroom",
        },
        {
          id: 4,
          title: "Warehouse",
        },
        {
          id: 5,
          title: "Industrial Space",
        },
      ],
      label: "Property Type",
      inputID: 1,
      required: true,
      isvisible: true,
    },
  ],
};

export const CommerRentRetailnShow = {
  additional_details: [
    {
      Type: "AMENITIES",
      data: [
        {
          id: 2,
          title: "Furnishing",
          // icon: "<Furnishing />",
          // activeicon: "<FurnishingW />",
        },
        {
          id: 1,
          title: "CCTV",
          icon: "<CCTV />",
          activeicon: "<CCTVW />",
          // icon: "<CCTV />",
          // activeicon: "<CCTVW />",
        },
        {
          id: 3,
          title: "UPS",
          icon: "<Ups />",
          activeicon: "<UpsW />",
        },
        {
          id: 4,
          title: "Power backup",
          icon: "<PowerBackup />",
          activeicon: "<PowerBackupW />",
        },
        {
          id: 34,
          title: "Water Supply",
          icon: "<WaterSupply />",
          activeicon: "<WaterSupplyW />",
        },
        {
          id: 12,
          title: "Cafeteria",
          icon: "<Cafeteria />",
          activeicon: "<CafeteriaW />",
        },
        {
          id: 6,
          title: "Internet Connectivity",
          icon: "<Internet />",
          activeicon: "<InternetW />",
        },
        {
          id: 7,
          title: "Oxygen Duct",
          icon: "<Duct />",
          activeicon: "<DuctW />",
        },
        {
          id: 8,
          title: "Vastu",
          icon: "<Vastu />",
          activeicon: "<VastuW />",
        },
        {
          id: 11,
          title: "Security Personnel",
          icon: "<Security />",
          activeicon: "<SecurityW />",
        },
        {
          id: 13,
          title: "Water Storage",
          icon: "<Waterstorage />",
          activeicon: "<WaterstorageW />",
        },
        {
          id: 9,
          title: "Fire sensors",
          icon: "<FireSens />",
          activeicon: "<FireSensW />",
        },
        {
          id: 5,
          title: "Central Air Conditioning",
          icon: "<AirCondit />",
          activeicon: "<AirConditW />",
        },
        {
          id: 16,
          title: "Pantry",
          icon: "<Pantry />",
          activeicon: "<PantryW />",
        },
        {
          id: 17,
          title: "Fire NOC Certified",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
        {
          id: 14,
          title: "DG Availability",
          icon: "<DG />",
          activeicon: "<DGW />",
        },
        {
          id: 10,
          title: "Fire extinguishers",
          icon: "<FireExt />",
          activeicon: "<FireExtW />",
        },
        {
          id: 15,
          title: "Reception Area",
          icon: "<Reception />",
          activeicon: "<ReceptionW />",
        },
        {
          id: 18,
          title: "Occupancy Certificate",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
      ],
      label: "AMENITIES",
      inputID: "9832011",
      required: false,
      isvisible: true,
    },
  ],
  price_details: [
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Rent",
        },
        {
          id: 2,
          title: "Lease",
        },
      ],
      label: "Property available for",
      inputID: 78956,
      required: true,
      isvisible: true,
    },
    {
      Type: "Price Text Field1",
      data: [],
      label: "Monthly Rent",
      keyboard: "number-pad",
      maxlen: 11,
      inputID: 75428,
      required: true,
      isvisible: true,
    },
    {
      Type: "Price Text Field2",
      data: [],
      label: "Lease Amount",
      maxlen: 11,
      keyboard: "number-pad",
      inputID: 75422,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Negotiable",
      inputID: 406512,
      required: false,
      isvisible: true,
    },

    {
      Type: "Drop down deposit",
      data: [
        {
          id: 1,
          title: "1 Month",
        },
        {
          id: 2,
          title: "2 Months",
        },
        {
          id: 3,
          title: "3 Months",
        },
        {
          id: 4,
          title: "4 Months",
        },
        {
          id: 5,
          title: "5 Months",
        },
        {
          id: 6,
          title: "6 Months",
        },
        {
          id: 7,
          title: "7 Months",
        },
        {
          id: 8,
          title: "8 Months",
        },
        {
          id: 9,
          title: "9 Months",
        },
        {
          id: 10,
          title: "10 Months",
        },
        {
          id: 11,
          title: "11 Months",
        },
        {
          id: 12,
          title: "12 Months",
        },
      ],
      title: "Security Deposit",
      label: "Security Deposit",
      icon: "<Arrowicon />",
      inputID: 80214,
      required: true,
      isvisible: true,
    },

    {
      Type: "Text Area",
      data: [],
      label: "Property Description",
      inputID: 46825,
      required: false,
      isvisible: true,
    },
  ],
  property_details: [
    {
      Type: "Text Field",
      data: [],
      label: "Building / Project Name  Optional ",
      inputID: 406039,
      required: false,
      isvisible: true,
    },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Ready to Move",
        },
        {
          id: 2,
          title: "Under Construction",
        },
      ],
      label: "Possession Status",
      title: "POSSESSION INFO",
      inputID: 748124,
      required: true,
      isvisible: true,
    },
    {
      Type: "calendar",
      data: [],
      label: "Available From",
      inputID: 60567,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type4",
      data: [
        {
          id: 1,
          title: "Jewellery",
        },
        {
          id: 2,
          title: "Gym",
        },
        {
          id: 3,
          title: "Grocery",
        },
        {
          id: 4,
          title: "Clinic",
        },
        {
          id: 5,
          title: "Footwear",
        },
        {
          id: 6,
          title: "Electronics",
        },
        {
          id: 7,
          title: "Clothing",
        },
        {
          id: 8,
          title: "Others",
        },
      ],
      label: "Suitable For",
      title: "ABOUT THE PROPERTY",
      inputID: 725492,
      required: true,
      isvisible: true,
    },
    {
      Type: "Built up Text Field",
      data: [],
      label: "Built Up Area",
      keyboard: "number-pad",
      label2: "Sq.ft",
      inputID: 627431,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Carpet Area",
      keyboard: "number-pad",

      label2: "Sq.ft",
      inputID: 301945,
      required: true,
      isvisible: true,
    },
    {
      Type: "FDrop down",
      data: [],
      label: "Total Floors",
      icon: "<Arrowicon />",
      title: "FLOORS AVAILABLE",
      inputID: 498507,
      required: true,
      isvisible: true,
    },
    {
      Type: "Drop down1",
      data: [],
      label: "Select Your Floor",
      icon: "<Arrowicon />",
      inputID: 496758,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Parking",
      inputID: 412659,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Parking',
    //   inputID: 436660,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Washroom",
      title: "FACILITIES",
      inputID: 465681,
      required: false,
      isvisible: true,
    },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "North",
        },
        {
          id: 2,
          title: "East",
        },
        {
          id: 3,
          title: "West",
        },
        {
          id: 4,
          title: "South",
        },
      ],
      label: "Facing",
      inputID: 579134,
      required: true,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Washroom',
    //   inputID: 446652,
    //   required: false,
    //   isvisible: true,
    // },
  ],
  id: 22,
};

export const CommercialrentOffice = {
  additional_details: [
    {
      Type: "AMENITIES",
      data: [
        {
          id: 2,
          title: "Furnishing",
          icon: "<Furnishing />",
          activeicon: "<FurnishingW />",
        },
        {
          id: 1,
          title: "CCTV",
          icon: "<CCTV />",
          activeicon: "<CCTVW />",
        },
        {
          id: 3,
          title: "UPS",
          icon: "<Ups />",
          activeicon: "<UpsW />",
        },
        {
          id: 4,
          title: "Power backup",
          icon: "<PowerBackup />",
          activeicon: "<PowerBackupW />",
        },
        {
          id: 34,
          title: "Water Supply",
          icon: "<WaterSupply />",
          activeicon: "<WaterSupplyW />",
        },
        {
          id: 12,
          title: "Cafeteria",
          icon: "<Cafeteria />",
          activeicon: "<CafeteriaW />",
        },
        {
          id: 6,
          title: "Internet Connectivity",
          icon: "<Internet />",
          activeicon: "<InternetW />",
        },
        {
          id: 7,
          title: "Oxygen Duct",
          icon: "<Duct />",
          activeicon: "<DuctW />",
        },
        {
          id: 8,
          title: "Vastu",
          icon: "<Vastu />",
          activeicon: "<VastuW />",
        },
        {
          id: 11,
          title: "Security Personnel",
          icon: "<Security />",
          activeicon: "<SecurityW />",
        },
        {
          id: 13,
          title: "Water Storage",
          icon: "<Waterstorage />",
          activeicon: "<WaterstorageW />",
        },
        {
          id: 9,
          title: "Fire sensors",
          icon: "<FireSens />",
          activeicon: "<FireSensW />",
        },
        {
          id: 5,
          title: "Central Air Conditioning",
          icon: "<AirCondit />",
          activeicon: "<AirConditW />",
        },
        {
          id: 16,
          title: "Pantry",
          icon: "<Pantry />",
          activeicon: "<PantryW />",
        },
        {
          id: 17,
          title: "Fire NOC Certified",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
        {
          id: 14,
          title: "DG Availability",
          icon: "<DG />",
          activeicon: "<DGW />",
        },
        {
          id: 10,
          title: "Fire extinguishers",
          icon: "<FireExt />",
          activeicon: "<FireExtW />",
        },
        {
          id: 15,
          title: "Reception Area",
          icon: "<Reception />",
          activeicon: "<ReceptionW />",
        },
        {
          id: 18,
          title: "Occupancy Certificate",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
      ],
      label: "AMENITIES",
      inputID: "129037",
      required: false,
      isvisible: true,
    },
  ],
  price_details: [
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Rent",
        },
        {
          id: 2,
          title: "Lease",
        },
      ],
      label: "Property available for",
      inputID: 78956,
      required: true,
      isvisible: true,
    },
    {
      Type: "Price Text Field1",
      data: [],
      label: "Monthly Rent",
      keyboard: "number-pad",
      maxlen: 11,
      inputID: 75428,
      required: true,
      isvisible: true,
    },
    {
      Type: "Price Text Field2",
      data: [],
      label: "Lease Amount",
      maxlen: 11,
      keyboard: "number-pad",
      inputID: 75422,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Negotiable",
      inputID: 406522,
      required: false,
      isvisible: true,
    },

    {
      Type: "Drop down deposit",
      data: [
        {
          id: 1,
          title: "1 Month",
        },
        {
          id: 2,
          title: "2 Months",
        },
        {
          id: 3,
          title: "3 Months",
        },
        {
          id: 4,
          title: "4 Months",
        },
        {
          id: 5,
          title: "5 Months",
        },
        {
          id: 6,
          title: "6 Months",
        },
        {
          id: 7,
          title: "7 Months",
        },
        {
          id: 8,
          title: "8 Months",
        },
        {
          id: 9,
          title: "9 Months",
        },
        {
          id: 10,
          title: "10 Months",
        },
        {
          id: 11,
          title: "11 Months",
        },
        {
          id: 12,
          title: "12 Months",
        },
      ],
      title: "Security Deposit",
      label: "Security Deposit",
      icon: "<Arrowicon />",
      inputID: 80214,
      required: true,
      isvisible: true,
    },

    {
      Type: "Text Area",
      data: [],
      label: "Property Description",
      inputID: 46885,
      required: false,
      isvisible: true,
    },
  ],
  property_details: [
    {
      Type: "Text Field",
      data: [],
      label: "Building / Project Name  Optional ",
      inputID: 400801,
      required: false,
      isvisible: true,
    },

    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Ready to Move",
        },
        {
          id: 2,
          title: "Under Construction",
        },
      ],
      label: "Possession Status",
      title: "POSSESSION INFO",
      inputID: 748124,
      required: true,
      isvisible: true,
    },
    {
      Type: "calendar",
      data: [],
      label: "Available From",
      inputID: 60937,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Ready to Use",
        },
        {
          id: 2,
          title: "Bare Shell",
        },
      ],
      label: "Property Condition",
      title: "ABOUT THE PROPERTY",
      inputID: 9653214,
      required: true,
      isvisible: true,
    },
    {
      Type: "Built up Text Field",
      data: [],
      label: "Built Up Area",
      keyboard: "number-pad",
      label2: "Sq.ft",
      inputID: 480100,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Carpet Area",
      label2: "Sq.ft",
      keyboard: "number-pad",
      inputID: 750491,
      required: true,
      isvisible: true,
    },

    {
      Type: "FDrop down",
      data: [],
      label: "Total Floors",
      icon: "<Arrowicon />",
      title: "FLOORS AVAILABLE",
      inputID: 498150,
      required: true,
      isvisible: true,
    },
    {
      Type: "Drop down1",
      data: [],
      label: "Select Your Floor",
      icon: "<Arrowicon />",
      inputID: 496800,
      required: true,
      isvisible: true,
    },

    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Lifts",
      inputID: 845988,
      required: false,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Parking",
      inputID: 845129,
      required: false,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Washroom",
      inputID: 4857120,
      required: false,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Meeting Room",
      inputID: 4843121,
      required: false,
      isvisible: true,
    },

    {
      Type: "Text Field",
      data: [],
      label: "min  number of seats",
      keyboard: "number-pad",

      title: "FACILITIES",
      inputID: 394127,
      required: false,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "max  number of seats",
      keyboard: "number-pad",

      inputID: 412739,
      required: false,
      isvisible: true,
    },

    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "North",
        },
        {
          id: 2,
          title: "East",
        },
        {
          id: 3,
          title: "West",
        },
        {
          id: 4,
          title: "South",
        },
      ],
      label: "Facing",
      inputID: 579304,
      required: true,
      isvisible: true,
    },

    {
      Type: "Form Button",
      label: "Next, add additional details",
      NextButton: "PriceDetails",
    },
  ],
  id: 17,
};

export const CommercialrentWareh = {
  additional_details: [
    {
      Type: "AMENITIES",
      data: [
        {
          id: 2,
          title: "Furnishing",
          icon: "<Furnishing />",
          activeicon: "<FurnishingW />",
        },
        {
          id: 1,
          title: "CCTV",
          icon: "<CCTV />",
          activeicon: "<CCTVW />",
        },
        {
          id: 3,
          title: "UPS",
          icon: "<Ups />",
          activeicon: "<UpsW />",
        },
        {
          id: 4,
          title: "Power backup",
          icon: "<PowerBackup />",
          activeicon: "<PowerBackupW />",
        },
        {
          id: 34,
          title: "Water Supply",
          icon: "<WaterSupply />",
          activeicon: "<WaterSupplyW />",
        },
        {
          id: 12,
          title: "Cafeteria",
          icon: "<Cafeteria />",
          activeicon: "<CafeteriaW />",
        },
        {
          id: 6,
          title: "Internet Connectivity",
          icon: "<Internet />",
          activeicon: "<InternetW />",
        },
        {
          id: 7,
          title: "Oxygen Duct",
          icon: "<Duct />",
          activeicon: "<DuctW />",
        },
        {
          id: 8,
          title: "Vastu",
          icon: "<Vastu />",
          activeicon: "<VastuW />",
        },
        {
          id: 11,
          title: "Security Personnel",
          icon: "<Security />",
          activeicon: "<SecurityW />",
        },
        {
          id: 13,
          title: "Water Storage",
          icon: "<Waterstorage />",
          activeicon: "<WaterstorageW />",
        },
        {
          id: 9,
          title: "Fire sensors",
          icon: "<FireSens />",
          activeicon: "<FireSensW />",
        },
        {
          id: 5,
          title: "Central Air Conditioning",
          icon: "<AirCondit />",
          activeicon: "<AirConditW />",
        },
        {
          id: 16,
          title: "Pantry",
          icon: "<Pantry />",
          activeicon: "<PantryW />",
        },
        {
          id: 17,
          title: "Fire NOC Certified",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
        {
          id: 14,
          title: "DG Availability",
          icon: "<DG />",
          activeicon: "<DGW />",
        },
        {
          id: 10,
          title: "Fire extinguishers",
          icon: "<FireExt />",
          activeicon: "<FireExtW />",
        },
        {
          id: 15,
          title: "Reception Area",
          icon: "<Reception />",
          activeicon: "<ReceptionW />",
        },
        {
          id: 18,
          title: "Occupancy Certificate",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
      ],
      label: "AMENITIES",
      inputID: "1234567",
      required: false,
      isvisible: true,
    },
  ],
  price_details: [
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Rent",
        },
        {
          id: 2,
          title: "Lease",
        },
      ],
      label: "Property available for",
      inputID: 78956,
      required: true,
      isvisible: true,
    },
    {
      Type: "Price Text Field1",
      data: [],
      label: "Monthly Rent",
      keyboard: "number-pad",
      maxlen: 11,
      inputID: 75428,
      required: true,
      isvisible: true,
    },
    {
      Type: "Price Text Field2",
      data: [],
      label: "Lease Amount",
      maxlen: 11,
      keyboard: "number-pad",
      inputID: 75422,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Negotiable",
      inputID: 406513,
      required: false,
      isvisible: true,
    },
    {
      Type: "Drop down deposit",
      data: [
        {
          id: 1,
          title: "1 Month",
        },
        {
          id: 2,
          title: "2 Months",
        },
        {
          id: 3,
          title: "3 Months",
        },
        {
          id: 4,
          title: "4 Months",
        },
        {
          id: 5,
          title: "5 Months",
        },
        {
          id: 6,
          title: "6 Months",
        },
        {
          id: 7,
          title: "7 Months",
        },
        {
          id: 8,
          title: "8 Months",
        },
        {
          id: 9,
          title: "9 Months",
        },
        {
          id: 10,
          title: "10 Months",
        },
        {
          id: 11,
          title: "11 Months",
        },
        {
          id: 12,
          title: "12 Months",
        },
      ],
      title: "Security Deposit",
      label: "Security Deposit",
      icon: "<Arrowicon />",
      inputID: 80214,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Area",
      data: [],
      label: "Property Description",
      inputID: 468993,
      required: false,
      isvisible: true,
    },
  ],
  property_details: [
    {
      Type: "Text Field",
      label: "Building / Project Name  Optional ",
      inputID: 698241,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   label: 'Locality',
    //   inputID: 104826,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Ready to Move",
        },
        {
          id: 2,
          title: "Under Construction",
        },
      ],
      label: "Possession Status",
      title: "POSSESSION INFO",
      inputID: 105412,
      required: true,
      isvisible: true,
    },
    {
      Type: "calendar",
      data: [],
      label: "Available From",
      inputID: 60567,
      required: true,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   label: 'Age of Property (In years)',
    //   keyboard: 'number-pad',
    //   inputID: 475839,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "Built up Text Field",
      label: "Built Up Area",
      keyboard: "number-pad",
      label2: "Sq.ft",
      inputID: 928371,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      label: "Carpet Area",
      keyboard: "number-pad",

      label2: "Sq.ft",
      inputID: 638291,
      required: true,
      isvisible: true,
    },
    // {
    //   Type: 'Price Text Field',
    //   label: 'Expected Rent',
    //   keyboard: 'number-pad',

    //   title: 'PRICE DETAILS',
    //   inputID: 822425,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Negotiable',
    //   inputID: 719384,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   label: 'Security Deposit',
    //   keyboard: 'number-pad',
    //   inputID: 964732,
    //   required: false,
    //   isvisible: true,
    // },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Electricity charges included?',
    //   inputID: 102837,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   label: 'Lock-in-Period',
    //   inputID: 539292,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   label: 'Expected Rent Increase',
    //   keyboard: 'number-pad',

    //   inputID: 627381,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Drop down',
    //   data: [],
    //   label: 'Total Floors',
    //   icon: "<Arrowicon />",
    //   title: 'FLOORS AVAILABLE',
    //   inputID: 153437,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Drop down',
    //   data: [],
    //   label: 'Select Your Floor',
    //   icon: "<Arrowicon />",

    //   title: 'Your Floor',
    //   inputID: 151237,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Parking",
      title: "PARKING",
      inputID: 1500137,
      required: false,
      isvisible: true,
    },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "North",
        },
        {
          id: 2,
          title: "East",
        },
        {
          id: 3,
          title: "West",
        },
        {
          id: 4,
          title: "South",
        },
      ],
      label: "Facing",
      inputID: 579034,
      required: true,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Parking',
    //   inputID: 150157,
    //   required: false,
    //   isvisible: true,
    // },
  ],
  id: 15,
};

export const CommercialIndSpace = {
  additional_details: [
    {
      Type: "AMENITIES",
      data: [
        {
          id: 2,
          title: "Furnishing",
          icon: "<Furnishing />",
          activeicon: "<FurnishingW />",
        },
        {
          id: 1,
          title: "CCTV",
          icon: "<CCTV />",
          activeicon: "<CCTVW />",
        },
        {
          id: 3,
          title: "UPS",
          icon: "<Ups />",
          activeicon: "<UpsW />",
        },
        {
          id: 4,
          title: "Power backup",
          icon: "<PowerBackup />",
          activeicon: "<PowerBackupW />",
        },
        {
          id: 34,
          title: "Water Supply",
          icon: "<WaterSupply />",
          activeicon: "<WaterSupplyW />",
        },
        {
          id: 12,
          title: "Cafeteria",
          icon: "<Cafeteria />",
          activeicon: "<CafeteriaW />",
        },
        {
          id: 6,
          title: "Internet Connectivity",
          icon: "<Internet />",
          activeicon: "<InternetW />",
        },
        {
          id: 7,
          title: "Oxygen Duct",
          icon: "<Duct />",
          activeicon: "<DuctW />",
        },
        {
          id: 8,
          title: "Vastu",
          icon: "<Vastu />",
          activeicon: "<VastuW />",
        },
        {
          id: 11,
          title: "Security Personnel",
          icon: "<Security />",
          activeicon: "<SecurityW />",
        },
        {
          id: 13,
          title: "Water Storage",
          icon: "<Waterstorage />",
          activeicon: "<WaterstorageW />",
        },
        {
          id: 9,
          title: "Fire sensors",
          icon: "<FireSens />",
          activeicon: "<FireSensW />",
        },
        {
          id: 5,
          title: "Central Air Conditioning",
          icon: "<AirCondit />",
          activeicon: "<AirConditW />",
        },
        {
          id: 16,
          title: "Pantry",
          icon: "<Pantry />",
          activeicon: "<PantryW />",
        },
        {
          id: 17,
          title: "Fire NOC Certified",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
        {
          id: 14,
          title: "DG Availability",
          icon: "<DG />",
          activeicon: "<DGW />",
        },
        {
          id: 10,
          title: "Fire extinguishers",
          icon: "<FireExt />",
          activeicon: "<FireExtW />",
        },
        {
          id: 15,
          title: "Reception Area",
          icon: "<Reception />",
          activeicon: "<ReceptionW />",
        },
        {
          id: 18,
          title: "Occupancy Certificate",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
      ],
      label: "AMENITIES",
      inputID: "1230007",
      required: false,
      isvisible: true,
    },
  ],
  price_details: [
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Rent",
        },
        {
          id: 2,
          title: "Lease",
        },
      ],
      label: "Property available for",
      inputID: 78956,
      required: true,
      isvisible: true,
    },
    {
      Type: "Price Text Field1",
      data: [],
      label: "Monthly Rent",
      keyboard: "number-pad",
      maxlen: 11,
      inputID: 75428,
      required: true,
      isvisible: true,
    },
    {
      Type: "Price Text Field2",
      data: [],
      label: "Lease Amount",
      maxlen: 11,
      keyboard: "number-pad",
      inputID: 75422,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Negotiable",
      inputID: 406514,
      required: false,
      isvisible: true,
    },

    {
      Type: "Drop down deposit",
      data: [
        {
          id: 1,
          title: "1 Month",
        },
        {
          id: 2,
          title: "2 Months",
        },
        {
          id: 3,
          title: "3 Months",
        },
        {
          id: 4,
          title: "4 Months",
        },
        {
          id: 5,
          title: "5 Months",
        },
        {
          id: 6,
          title: "6 Months",
        },
        {
          id: 7,
          title: "7 Months",
        },
        {
          id: 8,
          title: "8 Months",
        },
        {
          id: 9,
          title: "9 Months",
        },
        {
          id: 10,
          title: "10 Months",
        },
        {
          id: 11,
          title: "11 Months",
        },
        {
          id: 12,
          title: "12 Months",
        },
      ],
      title: "Security Deposit",
      label: "Security Deposit",
      icon: "<Arrowicon />",
      inputID: 80214,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Area",
      data: [],
      label: "Property Description",
      inputID: 4682553,
      required: false,
      isvisible: true,
    },
  ],
  property_details: [
    {
      Type: "Text Field",
      label: "Building / Project Name  Optional ",
      inputID: 7171,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   label: 'Locality',
    //   inputID: 5010212,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Ready to Move",
        },
        {
          id: 2,
          title: "Under Construction",
        },
      ],
      label: "Possession Status",
      title: "POSSESSION INFO",
      inputID: 105412,
      required: true,
      isvisible: true,
    },
    {
      Type: "calendar",
      data: [],
      label: "Available From",
      inputID: 60938,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      label: "Plot Area",
      title: "ABOUT THE PROPERTY",
      keyboard: "number-pad",
      label2: "Sq.ft",
      inputID: 105017,
      required: true,
      isvisible: true,
    },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "North",
        },
        {
          id: 2,
          title: "East",
        },
        {
          id: 3,
          title: "West",
        },
        {
          id: 4,
          title: "South",
        },
      ],
      label: "Facing",
      inputID: 507934,
      required: true,
      isvisible: true,
    },

    {
      Type: "Form Button",
      label: "Next, add additional details",
      NextButton: "PriceDetails",
    },
  ],
  id: 16,
};

export const CommercialSellRetailnShow = {
  additional_details: [
    {
      Type: "AMENITIES",
      data: [
        {
          id: 2,
          title: "Furnishing",
          icon: "<Furnishing />",
          activeicon: "<FurnishingW />",
        },
        {
          id: 1,
          title: "CCTV",
          icon: "<CCTV />",
          activeicon: "<CCTVW />",
        },
        {
          id: 3,
          title: "UPS",
          icon: "<Ups />",
          activeicon: "<UpsW />",
        },
        {
          id: 4,
          title: "Power backup",
          icon: "<PowerBackup />",
          activeicon: "<PowerBackupW />",
        },
        {
          id: 34,
          title: "Water Supply",
          icon: "<WaterSupply />",
          activeicon: "<WaterSupplyW />",
        },
        {
          id: 12,
          title: "Cafeteria",
          icon: "<Cafeteria />",
          activeicon: "<CafeteriaW />",
        },
        {
          id: 6,
          title: "Internet Connectivity",
          icon: "<Internet />",
          activeicon: "<InternetW />",
        },
        {
          id: 7,
          title: "Oxygen Duct",
          icon: "<Duct />",
          activeicon: "<DuctW />",
        },
        {
          id: 8,
          title: "Vastu",
          icon: "<Vastu />",
          activeicon: "<VastuW />",
        },
        {
          id: 11,
          title: "Security Personnel",
          icon: "<Security />",
          activeicon: "<SecurityW />",
        },
        {
          id: 13,
          title: "Water Storage",
          icon: "<Waterstorage />",
          activeicon: "<WaterstorageW />",
        },
        {
          id: 9,
          title: "Fire sensors",
          icon: "<FireSens />",
          activeicon: "<FireSensW />",
        },
        {
          id: 5,
          title: "Central Air Conditioning",
          icon: "<AirCondit />",
          activeicon: "<AirConditW />",
        },
        {
          id: 16,
          title: "Pantry",
          icon: "<Pantry />",
          activeicon: "<PantryW />",
        },
        {
          id: 17,
          title: "Fire NOC Certified",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
        {
          id: 14,
          title: "DG Availability",
          icon: "<DG />",
          activeicon: "<DGW />",
        },
        {
          id: 10,
          title: "Fire extinguishers",
          icon: "<FireExt />",
          activeicon: "<FireExtW />",
        },
        {
          id: 15,
          title: "Reception Area",
          icon: "<Reception />",
          activeicon: "<ReceptionW />",
        },
        {
          id: 18,
          title: "Occupancy Certificate",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
      ],
      label: "AMENITIES",
      inputID: "443327",
      required: false,
      isvisible: true,
    },
  ],
  price_details: [
    {
      Type: "Price Text Field",
      data: [],
      label: "Price",
      keyboard: "number-pad",
      inputID: 128,
      maxlen: 16,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type2",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Negotiable",
      inputID: 406505,
      required: false,
      isvisible: true,
    },
    {
      Type: "Text Area",
      data: [],
      label: "Property Description",
      inputID: 4116823,
      required: false,
      isvisible: true,
    },
  ],
  property_details: [
    {
      Type: "Text Field",
      data: [],
      label: "Building / Project Name  Optional ",
      inputID: 847316,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Locality',
    //   inputID: 934201,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Ready to Move",
        },
        {
          id: 2,
          title: "Under Construction",
        },
      ],
      label: "Possession Status",
      title: "POSSESSION INFO",
      inputID: 4622410,
      required: true,
      isvisible: true,
    },
    {
      Type: "calendar",
      data: [],
      label: "Available From",
      inputID: 60939,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type4",
      data: [
        {
          id: 1,
          title: "Jewellery",
        },
        {
          id: 2,
          title: "Gym",
        },
        {
          id: 3,
          title: "Grocery",
        },
        {
          id: 4,
          title: "Clinic",
        },
        {
          id: 5,
          title: "Footwear",
        },
        {
          id: 6,
          title: "Electronics",
        },
        {
          id: 7,
          title: "Clothing",
        },
        {
          id: 8,
          title: "Others",
        },
      ],
      label: "Suitable For",
      title: "ABOUT THE PROPERTY",
      inputID: 725490,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Built Up Area",
      keyboard: "number-pad",
      label2: "Sq.ft",
      inputID: 627431,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Carpet Area",
      keyboard: "number-pad",

      label2: "Sq.ft",
      inputID: 301945,
      required: true,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Entrance Width in feet',
    //   keyboard: 'number-pad',

    //   title: 'ft.',
    //   inputID: 106238,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Ceiling Height in feet',
    //   keyboard: 'number-pad',

    //   title: 'ft.',
    //   inputID: 490857,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   data: [],
    //   label: 'Price',
    //   keyboard: 'number-pad',
    //   title: 'PRICE DETAILS',
    //   inputID: 809256,
    //   required: true,
    //   isvisible: true,
    // },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Tax & Govt. Charge included?',
    //   inputID: 728519,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "FDrop down",
      data: [],
      label: "Total Floors",
      icon: "<Arrowicon />",
      title: "FLOORS AVAILABLE",
      inputID: 498150,
      required: true,
      isvisible: true,
    },
    {
      Type: "Drop down1",
      data: [],
      label: "Select Your Floor",
      icon: "<Arrowicon />",
      inputID: 496800,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Parking",
      inputID: 735196,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Parking',
    //   inputID: 619540,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Washroom",
      inputID: 408715,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Private Washroom',
    //   inputID: 790671,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "North",
        },
        {
          id: 2,
          title: "East",
        },
        {
          id: 3,
          title: "West",
        },
        {
          id: 4,
          title: "South",
        },
      ],
      label: "Facing",
      inputID: 57934,
      required: true,
      isvisible: true,
    },
  ],
  id: 18,
};
export const CommercialSellOff = {
  additional_details: [
    {
      Type: "AMENITIES",
      data: [
        {
          id: 2,
          title: "Furnishing",
          icon: "<Furnishing />",
          activeicon: "<FurnishingW />",
        },
        {
          id: 1,
          title: "CCTV",
          icon: "<CCTV />",
          activeicon: "<CCTVW />",
        },
        {
          id: 3,
          title: "UPS",
          icon: "<Ups />",
          activeicon: "<UpsW />",
        },
        {
          id: 4,
          title: "Power backup",
          icon: "<PowerBackup />",
          activeicon: "<PowerBackupW />",
        },
        {
          id: 34,
          title: "Water Supply",
          icon: "<WaterSupply />",
          activeicon: "<WaterSupplyW />",
        },
        {
          id: 12,
          title: "Cafeteria",
          icon: "<Cafeteria />",
          activeicon: "<CafeteriaW />",
        },
        {
          id: 6,
          title: "Internet Connectivity",
          icon: "<Internet />",
          activeicon: "<InternetW />",
        },
        {
          id: 7,
          title: "Oxygen Duct",
          icon: "<Duct />",
          activeicon: "<DuctW />",
        },
        {
          id: 8,
          title: "Vastu",
          icon: "<Vastu />",
          activeicon: "<VastuW />",
        },
        {
          id: 11,
          title: "Security Personnel",
          icon: "<Security />",
          activeicon: "<SecurityW />",
        },
        {
          id: 13,
          title: "Water Storage",
          icon: "<Waterstorage />",
          activeicon: "<WaterstorageW />",
        },
        {
          id: 9,
          title: "Fire sensors",
          icon: "<FireSens />",
          activeicon: "<FireSensW />",
        },
        {
          id: 5,
          title: "Central Air Conditioning",
          icon: "<AirCondit />",
          activeicon: "<AirConditW />",
        },
        {
          id: 16,
          title: "Pantry",
          icon: "<Pantry />",
          activeicon: "<PantryW />",
        },
        {
          id: 17,
          title: "Fire NOC Certified",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
        {
          id: 14,
          title: "DG Availability",
          icon: "<DG />",
          activeicon: "<DGW />",
        },
        {
          id: 10,
          title: "Fire extinguishers",
          icon: "<FireExt />",
          activeicon: "<FireExtW />",
        },
        {
          id: 15,
          title: "Reception Area",
          icon: "<Reception />",
          activeicon: "<ReceptionW />",
        },
        {
          id: 18,
          title: "Occupancy Certificate",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
      ],
      label: "AMENITIES",
      inputID: "05010",
      required: false,
      isvisible: true,
    },
  ],
  price_details: [
    {
      Type: "Price Text Field",
      data: [],
      label: "Price",
      keyboard: "number-pad",
      inputID: 128,
      maxlen: 16,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type2",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Negotiable",
      inputID: 406505,
      required: false,
      isvisible: true,
    },
    {
      Type: "Text Area",
      data: [],
      label: "Property Description",
      inputID: 46823777,
      required: false,
      isvisible: true,
    },
  ],
  property_details: [
    {
      Type: "Text Field",
      data: [],
      label: "Building / Project Name  Optional ",
      inputID: 123456,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Locality',
    //   inputID: 654321,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Ready to Move",
        },
        {
          id: 2,
          title: "Under Construction",
        },
      ],
      label: "Possession Status",
      inputID: 46224100,
      required: true,
      isvisible: true,
    },

    {
      Type: "calendar",
      data: [],
      label: "Available From",
      inputID: 60939,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Ready to Use",
        },
        {
          id: 2,
          title: "Bare Shell",
        },
      ],
      label: "Property Condition",
      title: "ABOUT THE PROPERTY",
      inputID: 9890834,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Built Up Area",
      keyboard: "number-pad",
      label2: "Sq.ft",
      inputID: 345678,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Carpet Area",
      keyboard: "number-pad",
      label2: "Sq.ft",
      inputID: 3209370,
      required: true,
      isvisible: true,
    },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'DG & UPS charges included?',
    //   inputID: 432109,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Electricity Charges included?',
    //   inputID: 210987,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "FDrop down",
      data: [],
      label: "Total Floors",
      icon: "<Arrowicon />",
      title: "FLOORS AVAILABLE",
      inputID: 498150,
      required: true,
      isvisible: true,
    },
    {
      Type: "Drop down1",
      data: [],
      label: "Select Your Floor",
      icon: "<Arrowicon />",
      inputID: 496800,
      required: true,
      isvisible: true,
    },
    // {
    //   Type: 'Drop down',
    //   data: [],
    //   label: 'Select Your Floor',
    //   icon: "<Arrowicon />",
    //   inputID: 543210,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Number of staircase',
    //   keyboard: 'number-pad',
    //   label2: 'LIFT & STAIRCASES',
    //   inputID: 543560,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Passengers Lifts',
    //   keyboard: 'number-pad',
    //   inputID: 500860,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Service Lifts',
    //   keyboard: 'number-pad',
    //   inputID: 860100,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Parking",
      inputID: 876500,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Parking',
    //   inputID: 8001110,
    //   required: false,
    //   isvisible: true,
    // },

    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Number of cabins',
    //   keyboard: 'number-pad',
    //   inputID: 301230,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Number of Meeting Rooms',
    //   keyboard: 'number-pad',

    //   inputID: 12003450,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Washroom",
      inputID: 321098,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Washroom',
    //   title: 'PARKING',
    //   inputID: 876543,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Lifts",
      inputID: 845988,
      required: false,
      isvisible: true,
    },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Meeting Room",
      inputID: 5003843,
      required: false,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "min  number of seats",
      keyboard: "number-pad",
      inputID: 300010,
      required: false,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "max  number of seats",
      keyboard: "number-pad",
      inputID: 3987610,
      required: false,
      isvisible: true,
    },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "North",
        },
        {
          id: 2,
          title: "East",
        },
        {
          id: 3,
          title: "West",
        },
        {
          id: 4,
          title: "South",
        },
      ],
      label: "Facing",
      inputID: 57934,
      required: true,
      isvisible: true,
    },

    {
      Type: "Form Button",
      label: "Next, add additional details",
      NextButton: "PriceDetails",
    },
  ],
  id: 14,
};

export const CommercialSellWare = {
  additional_details: [
    {
      Type: "AMENITIES",
      data: [
        {
          id: 2,
          title: "Furnishing",
          icon: "<Furnishing />",
          activeicon: "<FurnishingW />",
        },
        {
          id: 1,
          title: "CCTV",
          icon: "<CCTV />",
          activeicon: "<CCTVW />",
        },
        {
          id: 3,
          title: "UPS",
          icon: "<Ups />",
          activeicon: "<UpsW />",
        },
        {
          id: 4,
          title: "Power backup",
          icon: "<PowerBackup />",
          activeicon: "<PowerBackupW />",
        },
        {
          id: 34,
          title: "Water Supply",
          icon: "<WaterSupply />",
          activeicon: "<WaterSupplyW />",
        },
        {
          id: 12,
          title: "Cafeteria",
          icon: "<Cafeteria />",
          activeicon: "<CafeteriaW />",
        },
        {
          id: 6,
          title: "Internet Connectivity",
          icon: "<Internet />",
          activeicon: "<InternetW />",
        },
        {
          id: 7,
          title: "Oxygen Duct",
          icon: "<Duct />",
          activeicon: "<DuctW />",
        },
        {
          id: 8,
          title: "Vastu",
          icon: "<Vastu />",
          activeicon: "<VastuW />",
        },
        {
          id: 11,
          title: "Security Personnel",
          icon: "<Security />",
          activeicon: "<SecurityW />",
        },
        {
          id: 13,
          title: "Water Storage",
          icon: "<Waterstorage />",
          activeicon: "<WaterstorageW />",
        },
        {
          id: 9,
          title: "Fire sensors",
          icon: "<FireSens />",
          activeicon: "<FireSensW />",
        },
        {
          id: 5,
          title: "Central Air Conditioning",
          icon: "<AirCondit />",
          activeicon: "<AirConditW />",
        },
        {
          id: 16,
          title: "Pantry",
          icon: "<Pantry />",
          activeicon: "<PantryW />",
        },
        {
          id: 17,
          title: "Fire NOC Certified",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
        {
          id: 14,
          title: "DG Availability",
          icon: "<DG />",
          activeicon: "<DGW />",
        },
        {
          id: 10,
          title: "Fire extinguishers",
          icon: "<FireExt />",
          activeicon: "<FireExtW />",
        },
        {
          id: 15,
          title: "Reception Area",
          icon: "<Reception />",
          activeicon: "<ReceptionW />",
        },
        {
          id: 18,
          title: "Occupancy Certificate",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
      ],
      label: "AMENITIES",
      inputID: "98765011",
      required: false,
      isvisible: true,
    },
  ],
  price_details: [
    {
      Type: "Price Text Field",
      data: [],
      label: "Price",
      keyboard: "number-pad",
      inputID: 128,
      maxlen: 16,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type2",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Negotiable",
      inputID: 406505,
      required: false,
      isvisible: true,
    },
    {
      Type: "Text Area",
      data: [],
      label: "Property Description",
      inputID: 4680023,
      required: false,
      isvisible: true,
    },
  ],
  property_details: [
    {
      Type: "Text Field",
      data: [],
      label: "Building / Project Name  Optional ",
      inputID: 320917,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Locality',
    //   inputID: 149026,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Ready to Move",
        },
        {
          id: 2,
          title: "Under Construction",
        },
      ],
      label: "Possession Status",
      title: "POSSESSION INFO",
      inputID: 273409,
      required: true,
      isvisible: true,
    },
    {
      Type: "calendar",
      data: [],
      label: "Available From",
      inputID: 60937,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Built Up Area",
      title: "ABOUT THE PROPERTY",
      keyboard: "number-pad",
      label2: "Sq.ft",
      inputID: 560192,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      data: [],
      label: "Carpet Area",
      keyboard: "number-pad",
      label2: "Sq.ft",
      inputID: 320937,
      required: true,
      isvisible: true,
    },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Tax & Govt charges included?',
    //   inputID: 791034,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Water Charges included?',
    //   inputID: 345678,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Drop down',
    //   data: [],
    //   label: 'Total Floors',
    //   icon: "<Arrowicon />",
    //   title: 'FLOORS AVAILABLE',
    //   inputID: 850914,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Drop down',
    //   data: [],
    //   label: 'Select Your Floor',
    //   inputID: 603219,
    //   icon: "<Arrowicon />",
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "Box Type",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Parking",
      title: "PARKING",
      inputID: 450963,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Private Washroom',
    //   inputID: 907621,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "North",
        },
        {
          id: 2,
          title: "East",
        },
        {
          id: 3,
          title: "West",
        },
        {
          id: 4,
          title: "South",
        },
      ],
      label: "Facing",
      inputID: 57934,
      required: true,
      isvisible: true,
    },

    {
      Type: "Form Button",
      label: "Next, add additional details",
      NextButton: "PriceDetails",
    },
  ],
  id: 20,
};

export const CommercialSellIndSp = {
  additional_details: [
    {
      Type: "AMENITIES",
      data: [
        {
          id: 2,
          title: "Furnishing",
          icon: "<Furnishing />",
          activeicon: "<FurnishingW />",
        },
        {
          id: 1,
          title: "CCTV",
          icon: "<CCTV />",
          activeicon: "<CCTVW />",
        },
        {
          id: 3,
          title: "UPS",
          icon: "<Ups />",
          activeicon: "<UpsW />",
        },
        {
          id: 4,
          title: "Power backup",
          icon: "<PowerBackup />",
          activeicon: "<PowerBackupW />",
        },
        {
          id: 34,
          title: "Water Supply",
          icon: "<WaterSupply />",
          activeicon: "<WaterSupplyW />",
        },
        {
          id: 12,
          title: "Cafeteria",
          icon: "<Cafeteria />",
          activeicon: "<CafeteriaW />",
        },
        {
          id: 6,
          title: "Internet Connectivity",
          icon: "<Internet />",
          activeicon: "<InternetW />",
        },
        {
          id: 7,
          title: "Oxygen Duct",
          icon: "<Duct />",
          activeicon: "<DuctW />",
        },
        {
          id: 8,
          title: "Vastu",
          icon: "<Vastu />",
          activeicon: "<VastuW />",
        },
        {
          id: 11,
          title: "Security Personnel",
          icon: "<Security />",
          activeicon: "<SecurityW />",
        },
        {
          id: 13,
          title: "Water Storage",
          icon: "<Waterstorage />",
          activeicon: "<WaterstorageW />",
        },
        {
          id: 9,
          title: "Fire sensors",
          icon: "<FireSens />",
          activeicon: "<FireSensW />",
        },
        {
          id: 5,
          title: "Central Air Conditioning",
          icon: "<AirCondit />",
          activeicon: "<AirConditW />",
        },
        {
          id: 16,
          title: "Pantry",
          icon: "<Pantry />",
          activeicon: "<PantryW />",
        },
        {
          id: 17,
          title: "Fire NOC Certified",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
        {
          id: 14,
          title: "DG Availability",
          icon: "<DG />",
          activeicon: "<DGW />",
        },
        {
          id: 10,
          title: "Fire extinguishers",
          icon: "<FireExt />",
          activeicon: "<FireExtW />",
        },
        {
          id: 15,
          title: "Reception Area",
          icon: "<Reception />",
          activeicon: "<ReceptionW />",
        },
        {
          id: 18,
          title: "Occupancy Certificate",
          icon: "<FireNoc />",
          activeicon: "<FireNocW />",
        },
      ],
      label: "AMENITIES",
      inputID: "909262",
      required: false,
      isvisible: true,
    },
  ],
  price_details: [
    {
      Type: "Price Text Field",
      data: [],
      label: "Price",
      keyboard: "number-pad",
      inputID: 128,
      maxlen: 16,
      required: true,
      isvisible: true,
    },
    {
      Type: "Box Type2",
      data: [
        {
          id: 1,
          title: "Yes",
        },
        {
          id: 2,
          title: "No",
        },
      ],
      label: "Negotiable",
      inputID: 406505,
      required: false,
      isvisible: true,
    },
    {
      Type: "Text Area",
      data: [],
      label: "Property Description",
      inputID: 468778823,
      required: false,
      isvisible: true,
    },
  ],
  property_details: [
    {
      Type: "Text Field",
      label: "Building / Project Name  Optional ",
      inputID: 7171,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   label: 'Locality',
    //   inputID: 77707,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "Ready to Move",
        },
        {
          id: 2,
          title: "Under Construction",
        },
      ],
      label: "Possession Status",

      inputID: 462201,
      required: true,
      isvisible: true,
    },
    {
      Type: "calendar",
      data: [],
      label: "Available From",
      inputID: 60937,
      required: true,
      isvisible: true,
    },
    {
      Type: "Text Field",
      label: "Plot Area",
      title: "ABOUT THE PROPERTY",
      keyboard: "number-pad",
      label2: "Sq.ft",
      inputID: 105017,
      required: true,
      isvisible: true,
    },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Tax & Govt charges included?',
    //   inputID: 50105,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: "Custom Box Type",
      data: [
        {
          id: 1,
          title: "North",
        },
        {
          id: 2,
          title: "East",
        },
        {
          id: 3,
          title: "West",
        },
        {
          id: 4,
          title: "South",
        },
      ],
      label: "Facing",
      inputID: 57934,
      required: true,
      isvisible: true,
    },

    {
      Type: "Form Button",
      label: "Next, add additional details",
      NextButton: "PriceDetails",
    },
  ],
  id: 23,
};
