import React from "react";
import { UserReviewProvider } from "../../context/UserReviewContext";
import UserReviewDashboard from "./components/UserReviewDashboard";
import UserDetails from "./components/UserDetails";

const PropertyReviews = (showUserDetails) => {
  return (
    <UserReviewProvider>
      <div className="flex">
        <div className="w-[70%] bg-[#F5F4F8] border-r-[1px] border-r-[#404040] hide-scrollbar h-[calc(100vh-64px)] overflow-auto">
          <UserReviewDashboard />
        </div>
        <div className="w-[30%] bg-[#F5F4F8] hide-scrollbar h-[calc(100vh-64px)] overflow-auto">
        {showUserDetails && <UserDetails />}
        </div>
      </div>
    </UserReviewProvider>
  );
};

export default PropertyReviews;
