import { useEffect, useState } from "react";
import axios from "axios";
import userProfile from "../../assets/userProfile.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import EditComponent from "../../icons/EditComponent";
import Plus from "../../icons/Plus";
import Form from "../Form/Form";
import { selectFormData, deleteFormDataAsync } from "../../store/formSlice";
import { selectUserPermissions } from "../../store/authSlice";
import axiosInstance from "../../Interceptor/axiosInstance";
import { Alert, Snackbar } from "@mui/material";

const useStyles = makeStyles(() => ({
  tableCell: {
    padding: "12px",
    fontSize: "0.875rem",
    textAlign: "left",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 700,
    lineHeight: 1.43,
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    letterSpacing: "0.01071em",
    verticalAlign: "inherit",
  },
  active: {
    color: "green",
  },
  inactive: {
    color: "red",
  },
  pagination: {
    "& .MuiPaginationItem-outlined": {
      border: "none", // Remove border
    },
    "& .Mui-selected": {
      backgroundColor: "#02a7e4", // Change background color of selected page
      color: "white", // Change text color of selected page
    },
    "& .MuiPaginationItem-page:hover": {
      backgroundColor: "#02a7e4",
      color: "white", // Remove background color on hover
    },
  },
}));

const TableComponent = () => {
  const userRolePermission = useSelector(selectUserPermissions);

  const hasCreatePermission = userRolePermission.some(
    (permission) =>
      permission.Permission_label === "Employee Management" &&
      permission.create === "true"
  );
  const hasEditPermission = userRolePermission.some(
    (permission) =>
      permission.Permission_label === "Employee Management" &&
      permission.edit === "true"
  );
  const hasDeletePermission = userRolePermission.some(
    (permission) =>
      permission.Permission_label === "Employee Management" &&
      permission.delete === "true"
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [userData, setUserData] = useState([]);
  const [editUserData, setEditUserData] = useState(null);
  const [userDataFromResponse, setUserDataResponse] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedData, setSelectedData] = useState({});
  const [open, setOpen] = useState(false);

  const handleClose = (reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const formData = useSelector(selectFormData);
  const Id = formData[0]?.id;

  const classes = useStyles();

  const startIndex = (page - 1) * rowsPerPage;

  const dispatch = useDispatch();
  const fetchUserData = async () => {
    let users = [];
    try {
      const response = await axiosInstance.get("/api/admin/getusers");

      const userDataFromResponse = response.data;

      if (Array.isArray(userDataFromResponse?.message)) {
        users = userDataFromResponse?.message?.filter(
          (item) =>
            item?.role !== "Administrative" &&
            item?.username !== "username" &&
            item?.emp_id !== "emp_id"
        );

        setUserData(users);
        setLoading(false);
      } else {
        console.error(
          "Response data is not in the expected format:",
          userDataFromResponse
        );
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [formData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleEditClick = (uniqueId) => {
    const userDataToDisplay = userData.find(
      (item) => item.adminUserDetail.emp_id === uniqueId
    );

    if (userDataToDisplay) {
      console.log("Edit button clicked for User ID:", uniqueId);
      console.log("User Data:", userDataToDisplay); // Logs full user data
      setShowForm(true);
      setEditUserData(userDataToDisplay);
    } else {
      console.error("User data not found for editing");
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const toggleFormVisibility = () => {
    console.log("Create User button clicked"); // Logs when the button is clicked

    setShowForm(!showForm);
    setEditUserData(null);
    if (!showForm) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleDeleteClick = async (uniqueId) => {
    if (uniqueId) {
      try {
        const response = await dispatch(deleteFormDataAsync(uniqueId));

        if (response.payload) {
          setUserData((prevData) =>
            prevData.filter((item) => item.adminUserDetail.emp_id !== uniqueId)
          );
        } else {
          console.error("Delete operation failed:", response.error.message);
        }
        setAnchorEl(null); // Close the menu after deletion
      } catch (error) {
        console.error("Error deleting user data:", error);
      }
    } else {
      console.error("User ID is undefined");
    }
  };

  const handleEmpStatusUpdate = async (uniqueId, status) => {
    if (uniqueId) {
      try {
        const response = await axiosInstance.put(
          `/api/admin/employee/status/update`,
          {
            emp_id: uniqueId,
            user_active: !status,
          }
        );
        fetchUserData();
      } catch (error) {
        console.error("Error updating employee status:", error);
      }
    } else {
      console.error("User ID is undefined");
    }
  };

  const filteredData = userData?.filter((item) => {
    const lowerCaseSearchTerm = searchTerm?.toLowerCase();
    return (
      item?.adminUserDetail?.username
        ?.toLowerCase()
        ?.includes(lowerCaseSearchTerm) ||
      item?.adminUserDetail?.emp_id
        ?.toLowerCase()
        ?.includes(lowerCaseSearchTerm) ||
      item?.role?.Roles?.toLowerCase()?.includes(lowerCaseSearchTerm)
    );
  });

  const sortData = (data) => {
    const sorted = data?.sort((a, b) =>
      a?.adminUserDetail?.username.localeCompare(b?.adminUserDetail?.username)
    );
    return sorted;
  };

  return (
    <>
      <h3 className="mt-2 font-roboto text-xl font-bold">
        Employee Management
      </h3>
      <br />
      {showForm && (
        <Form
          setOpen={setOpen}
          onClose={() => setShowForm(false)}
          userDataFromResponse={editUserData}
          uniqueId={editUserData?.adminUserDetail.emp_id}
        />
      )}
      <div className="flex flex-wrap mt-2">
        <input
          id="outlined-basic"
          type="text"
          placeholder="Search by username, unique id, role"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={(e) => e.target.select()}
          className="mb-5 w-full sm:w-56 lg:w-[60%] custom-lg:w-6/12 h-10 box-border mt-2 sm:mr-5 font-roboto  bg-white border border-gray-300 rounded pl-2 outline-none"
        />
        <button className="mt-2 w-full sm:w-40 lg:w-36 rounded bg-[#02a7e4] text-white py-2 px-3 h-10 border border-[#02a7e4] font-roboto text-sm  sm:mr-auto lg:text-base">
          Advance search
        </button>
        <p className="font-roboto custom-lg:text-lg font-bold py-2 px-3 mt-2 w-full sm:w-auto lg:text-base">
          Create user
        </p>
        {hasCreatePermission && (
          <button
            onClick={toggleFormVisibility}
            className="bg-white border border-white h-8 mt-[10px] rounded py-2 px-2 w-full sm:w-auto lg:text-base"
          >
            <Plus />
          </button>
        )}
      </div>
      <TableContainer
        component={Paper}
        className="mt-1.5 p-0 pl-1.0 pr-1.0 font-roboto w-[1560px] h-[550px] overflow-y-auto custom-scrollbar rounded-lg"
      >
        <Table className="min-w-[300px]">
          <TableHead className="font-bold text-lg">
            <TableRow>
              <TableCell className={classes.tableCell}>NO.</TableCell>
              <TableCell className={classes.tableCell}>PROFILE</TableCell>
              <TableCell className={classes.tableCell}>USERNAME</TableCell>
              <TableCell className={classes.tableCell}>UNIQUE ID</TableCell>
              <TableCell className={classes.tableCell}>ROLE</TableCell>
              <TableCell className={classes.tableCell}>STATUS</TableCell>
              <TableCell className={classes.tableCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(filteredData.length > 0
              ? sortData(filteredData)
              : sortData(userData)
            ).map((item, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                <TableCell className={classes.tableCell}>
                  <img
                    className="w-10 h-10 rounded-full"
                    src={
                      item?.adminUserDetail?.profile_pic
                        ? item?.adminUserDetail?.profile_pic
                        : userProfile
                    }
                    alt="Profile Picture"
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item?.adminUserDetail?.username}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item?.adminUserDetail?.emp_id}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item?.role?.Roles}
                </TableCell>
                <TableCell
                  className={`${classes.tableCell} ${
                    item.adminUserDetail?.user_active
                      ? classes.active
                      : classes.inactive
                  }`}
                >
                  {`${
                    item.adminUserDetail?.user_active ? "Active" : "Inactive"
                  }`}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <div className="relative flex justify-center">
                    {item.adminUserDetail?.username
                      ?.toLowerCase()
                      ?.includes("vattara") &&
                    item?.role?.Roles?.toLowerCase()?.includes(
                      "admin"
                    ) ? null : (
                      <IconButton
                        onClick={(event) => {
                          setSelectedData(item);
                          if (hasEditPermission || hasDeletePermission) {
                            setAnchorEl(event.currentTarget);
                          }
                        }}
                      >
                        <EditComponent />
                      </IconButton>
                    )}
                    {hasEditPermission || hasDeletePermission ? (
                      <Menu
                        id="edit-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        className="absolute left-11" // Adjust positioning as needed
                      >
                        {hasEditPermission && (
                          <MenuItem
                            onClick={() => {
                              handleEditClick(
                                selectedData.adminUserDetail.emp_id
                              );
                              handleCloseMenu();
                            }}
                          >
                            Edit
                          </MenuItem>
                        )}
                        {/* {hasDeletePermission && (
                            <MenuItem
                              onClick={() => {
                                handleDeleteClick(
                                  selectedData.adminUserDetail.emp_id
                                );
                                handleCloseMenu();
                              }}
                              className="text-red-500"
                            >
                              Delete
                            </MenuItem>
                          )} */}
                        {hasEditPermission && (
                          <MenuItem
                            onClick={() => {
                              handleEmpStatusUpdate(
                                selectedData.adminUserDetail.emp_id,
                                selectedData?.adminUserDetail?.user_active
                              );
                              handleCloseMenu();
                            }}
                          >
                            {`${
                              selectedData?.adminUserDetail?.user_active
                                ? "Deactivate"
                                : "Activate"
                            }`}
                          </MenuItem>
                        )}
                      </Menu>
                    ) : null}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleClose}
          severity={open === "success" ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {open === "success"
            ? "Employee Details has been successfully saved."
            : open}
        </Alert>
      </Snackbar>
      {/* <div className="flex items-center justify-center mt-5">
        <Pagination
          count={Math.ceil(userData.length / rowsPerPage)}
          variant="outlined"
          shape="rounded"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          page={page}
          onChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.pagination}
          style={{
            color: "white",
          }}
        />
      </div> */}
    </>
  );
};

export default TableComponent;
