import React, { useContext } from "react";
import { ReportedPropsContext } from "../../../context/ReportedPropsContext";
import Details from "../../PostApproval/components/Details";
import userProfile from "../../../assets/userplaceholder.jpg";
import formatToINR from "../../../utils/formatToInr";

const ReportDetails = () => {
  const { showDetails, selectedProperty, reportDetails } =
    useContext(ReportedPropsContext);

  const residentialSellVacantLand = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        { label: "Looking to", value: selectedProperty?.looking_to },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Sub Locality", value: selectedProperty?.sublocality },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Plot Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        { label: "Length", value: selectedProperty?.length },
        { label: "Width", value: selectedProperty?.width },
        { label: "Property Status", value: selectedProperty?.approval_status },
        { label: "Patta Available", value: selectedProperty?.patta_available },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },
    {
      title: "Price Details",
      details: [
        { label: "Plot Price", value: selectedProperty?.price },
        {
          label: "Price/Sq.ft",
          value: formatToINR(selectedProperty?.PriceperSqft),
        },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const residentialSell = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        { label: "Looking to", value: selectedProperty?.looking_to },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Sub Locality", value: selectedProperty?.sublocality },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        { label: "BHK", value: selectedProperty?.bhk },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        ...(selectedProperty?.property_type === "Independent House" ||
        selectedProperty?.property_type === "Villa"
          ? [
              {
                label: "Plot Area",
                value: selectedProperty?.plotArea + " Sq.ft",
              },
            ]
          : []),
        { label: "Furnish Type", value: selectedProperty?.furnish_type },
        { label: "Furnishings", value: selectedProperty?.flat_furnishing },
        { label: "Amenities", value: selectedProperty?.society_amenities },
      ],
    },
    {
      title: "Price Details",
      details: [
        { label: "Price", value: selectedProperty?.price },
        {
          label: "Price/Sq.ft",
          value: formatToINR(selectedProperty?.PriceperSqft),
        },
        {
          label: "Construction Status",
          value: selectedProperty?.possession_status,
        },
        { label: "Negotiable", value: selectedProperty?.negotiable },
      ],
    },
    {
      title: "Additional Details",
      details: [
        {
          label: "Bathroom",
          value: selectedProperty?.additional_details?.bathroom,
        },
        {
          label: "Balcony",
          value: selectedProperty?.additional_details?.balcony,
        },
        {
          label: "Parking",
          value: selectedProperty?.additional_details?.parking,
        },
        {
          label: "Carpet Area",
          value: selectedProperty?.additional_details?.carpet_area + " Sq.ft",
        },
        {
          label: "Facing",
          value: selectedProperty?.additional_details?.facing,
        },
        {
          label: "Servant Room",
          value: selectedProperty?.additional_details?.servant_room,
        },
        {
          label: "Eligible For Home Loan?",
          value: selectedProperty?.additional_details?.eligible_for_loan,
        },
        {
          label: "Property Description",
          value: selectedProperty?.additional_details?.property_description,
        },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const residentialRent = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        {
          label: "Looking to",
          value: selectedProperty?.property_available_for,
        },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Sub Locality", value: selectedProperty?.sublocality },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        { label: "BHK", value: selectedProperty?.bhk },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        {
          label: "Property available for",
          value: selectedProperty?.property_available_for,
        },
        { label: "Furnish Type", value: selectedProperty?.furnish_type },
        { label: "Furnishings", value: selectedProperty?.flat_furnishing },
        { label: "Amenities", value: selectedProperty?.society_amenities },
      ],
    },
    {
      title: "Price Details",
      details: [
        ...(selectedProperty?.property_available_for === "Lease"
          ? [
              {
                label: "Lease Amount",
                value: selectedProperty?.enter_lease_amount,
              },
              {
                label: "Available From",
                value: selectedProperty?.available_from,
              },
              { label: "Negotiable", value: selectedProperty?.negotiable },
            ]
          : [
              { label: "Monthly Rent", value: selectedProperty?.monthly_rent },
              {
                label: "Available From",
                value: selectedProperty?.available_from,
              },
              {
                label: "Security Deposit",
                value: selectedProperty?.security_deposit,
              },
              { label: "Negotiable", value: selectedProperty?.negotiable },
            ]),
      ],
    },
    {
      title: "Additional Details",
      details: [
        {
          label: "Maintenance Charges",
          value: selectedProperty?.additional_details?.maintenance_charges,
        },
        {
          label: "Preferred Tenant Type",
          value: selectedProperty?.additional_details?.preferred_tenant_type,
        },
        {
          label: "Bathroom",
          value: selectedProperty?.additional_details?.bathroom,
        },
        {
          label: "Balcony",
          value: selectedProperty?.additional_details?.balcony,
        },
        {
          label: "Parking",
          value: selectedProperty?.additional_details?.parking,
        },
        // { label: "Preferred Tenant Type", value: selectedProperty?. },
        {
          label: "Carpet Area",
          value: selectedProperty?.additional_details?.carpet_area + " Sq.ft",
        },
        // { label: "Maintenance Charges", value: selectedProperty?. },
        {
          label: "Facing",
          value: selectedProperty?.additional_details?.facing,
        },
        {
          label: "Servant Room",
          value: selectedProperty?.additional_details?.servant_room,
        },
        {
          label: "Property Description",
          value: selectedProperty?.additional_details?.property_description,
        },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const CommercialSellOfficeSpace = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        { label: "Looking to", value: selectedProperty?.looking_to },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Sub Locality", value: selectedProperty?.sublocality },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
        // { label: "map", value: selectedProperty?. },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        {
          label: "Property Condition",
          value: selectedProperty?.property_condition,
        },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        {
          label: "Carpet Area",
          value: selectedProperty?.carpet_area + " Sq.ft",
        },
        { label: "Total Floors", value: selectedProperty?.total_floors },
        { label: "Your Floors", value: selectedProperty?.select_your_floor },
        { label: "Lift", value: selectedProperty?.lifts },
        { label: "Parking", value: selectedProperty?.parking },
        { label: "Washroom", value: selectedProperty?.washroom },
        { label: "Meeting Room", value: selectedProperty?.meeting_room },
        {
          label: "Min. Number of Seats",
          value: selectedProperty?.min_num_seat,
        },
        {
          label: "Max. Number of Seats",
          value: selectedProperty?.max_num_seat,
        },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },

    {
      title: "Price Details",
      details: [
        { label: "Price", value: selectedProperty?.price },
        {
          label: "Price/Sq.ft",
          value: formatToINR(selectedProperty?.PriceperSqft),
        },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },

    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const CommercialSellWareHouse = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        { label: "Looking to", value: selectedProperty?.looking_to },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Sub Locality", value: selectedProperty?.sublocality },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },

    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        {
          label: "Carpet Area",
          value: selectedProperty?.carpet_area + " Sq.ft",
        },
        { label: "Parking", value: selectedProperty?.parking },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },

    {
      title: "Price Details",
      details: [
        { label: "Price", value: selectedProperty?.price },
        {
          label: "Price/Sq.ft",
          value: formatToINR(selectedProperty?.PriceperSqft),
        },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },

    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const CommercialSellIndustrialSpace = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        { label: "Looking to", value: selectedProperty?.looking_to },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Sub Locality", value: selectedProperty?.sublocality },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
        // { label: "map", value: selectedProperty?. },
      ],
    },

    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        {
          label: "Plot Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },

    {
      title: "Price Details",
      details: [
        { label: "Price", value: selectedProperty?.price },
        {
          label: "Price/Sq.ft",
          value: formatToINR(selectedProperty?.PriceperSqft),
        },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },

    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const CommercialSellRetailShopShowroom = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        { label: "Looking to", value: selectedProperty?.looking_to },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Sub Locality", value: selectedProperty?.sublocality },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
        // { label: "map", value: selectedProperty?. },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        { label: "Suitable For", value: selectedProperty?.Suitable_for },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        // { label: "Ceiling height", value: selectedProperty?. },
        { label: "Total Floors", value: selectedProperty?.total_floors },
        { label: "Your Floors", value: selectedProperty?.select_your_floor },
        { label: "Parking", value: selectedProperty?.parking },
        { label: "Washroom", value: selectedProperty?.washroom },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },
    {
      title: "Price Details",
      details: [
        { label: "Price", value: selectedProperty?.price },
        {
          label: "Price/Sq.ft",
          value: formatToINR(selectedProperty?.PriceperSqft),
        },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },
    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const commercial_rent_officeSpace = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        {
          label: "Looking to",
          value: selectedProperty?.property_available_for,
        },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Sub Locality", value: selectedProperty?.sublocality },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Property available for",
          value: selectedProperty?.property_available_for,
        },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        {
          label: "Property Condition",
          value: selectedProperty?.property_condition,
        },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        {
          label: "Carpet Area",
          value: selectedProperty?.carpet_area + " Sq.ft",
        },
        { label: "Total Floors", value: selectedProperty?.total_floors },
        { label: "Your Floors", value: selectedProperty?.select_your_floor },
        { label: "Lift", value: selectedProperty?.lifts },
        { label: "Parking", value: selectedProperty?.parking },
        { label: "Washroom", value: selectedProperty?.washroom },
        { label: "Meeting Room", value: selectedProperty?.meeting_room },
        {
          label: "Min. Number of Seats",
          value: selectedProperty?.min_num_seat,
        },
        {
          label: "Max. Number of Seats",
          value: selectedProperty?.max_num_seat,
        },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },

    {
      title: "Price Details",
      details: [
        selectedProperty?.property_available_for === "Rent"
          ? { label: "Expected Rent", value: selectedProperty?.monthly_rent }
          : { label: "Lease Amount", value: selectedProperty?.lease_amount },
        // lablel:"Lease Amount" for lease property
        { label: "Negotiable", value: selectedProperty?.negotiable },
        selectedProperty?.property_available_for === "Rent" && {
          label: "Security Deposit",
          value: selectedProperty?.security_deposit,
        },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },

    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const commercial_rent_warehouse = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        {
          label: "Looking to",
          value: selectedProperty?.property_available_for,
        },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Sub Locality", value: selectedProperty?.sublocality },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },

    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Property available for",
          value: selectedProperty?.property_available_for,
        },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        {
          label: "Carpet Area",
          value: selectedProperty?.carpet_area + " Sq.ft",
        },
        { label: "Parking", value: selectedProperty?.parking },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },
    {
      title: "Price Details",
      details: [
        selectedProperty?.property_available_for === "Rent"
          ? { label: "Expected Rent", value: selectedProperty?.monthly_rent }
          : { label: "Lease Amount", value: selectedProperty?.lease_amount },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        selectedProperty?.property_available_for === "Rent" && {
          label: "Security Deposit",
          value: selectedProperty?.security_deposit,
        },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },
    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const commercial_rent_industrialSpace = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        {
          label: "Looking to",
          value: selectedProperty?.property_available_for,
        },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Sub Locality", value: selectedProperty?.sublocality },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Property available for",
          value: selectedProperty?.property_available_for,
        },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        {
          label: "Plot Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },
    {
      title: "Price Details",
      details: [
        selectedProperty?.property_available_for === "Rent"
          ? { label: "Expected Rent", value: selectedProperty?.monthly_rent }
          : { label: "Lease Amount", value: selectedProperty?.lease_amount },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        selectedProperty?.property_available_for === "Rent" && {
          label: "Security Deposit",
          value: selectedProperty?.security_deposit,
        },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },
    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const commercial_rent_retailShop = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        {
          label: "Looking to",
          value: selectedProperty?.property_available_for,
        },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Sub Locality", value: selectedProperty?.sublocality },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Property available for",
          value: selectedProperty?.property_available_for,
        },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        { label: "Suitable For", value: selectedProperty?.Suitable_for },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        {
          label: "Carpet Area",
          value: selectedProperty?.carpet_area + " Sq.ft",
        },
        { label: "Total Floors", value: selectedProperty?.total_floors },
        { label: "Your Floors", value: selectedProperty?.select_your_floor },
        { label: "Parking", value: selectedProperty?.parking },
        { label: "Washroom", value: selectedProperty?.washroom },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },
    {
      title: "Price Details",
      details: [
        selectedProperty?.property_available_for === "Rent"
          ? { label: "Expected Rent", value: selectedProperty?.monthly_rent }
          : { label: "Lease Amount", value: selectedProperty?.lease_amount },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        selectedProperty?.property_available_for === "Rent" && {
          label: "Security Deposit",
          value: selectedProperty?.security_deposite,
        },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },
    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const getDetails = () => {
    if (selectedProperty?.res_or_com === "Residential") {
      if (selectedProperty?.looking_to === "Sale") {
        if (
          selectedProperty?.property_type === "Vacant Land" ||
          selectedProperty?.property_type === "Plot / Land"
        ) {
          return residentialSellVacantLand;
        } else if (
          selectedProperty?.property_type === "Apartment" ||
          selectedProperty?.property_type === "Independent House" ||
          selectedProperty?.property_type === "Villa"
        ) {
          return residentialSell;
        }
      } else if (
        selectedProperty?.property_available_for === "Rent" ||
        selectedProperty?.property_available_for === "Lease"
      ) {
        return residentialRent;
      }
    } else if (selectedProperty?.res_or_com === "Commercial") {
      if (selectedProperty?.looking_to === "Sale") {
        if (selectedProperty?.property_type === "Office Space") {
          return CommercialSellOfficeSpace;
        } else if (selectedProperty?.property_type === "Industrial Space") {
          return CommercialSellIndustrialSpace;
        } else if (
          selectedProperty?.property_type === "Retail Shop" ||
          selectedProperty?.property_type === "Showroom"
        ) {
          return CommercialSellRetailShopShowroom;
        } else if (selectedProperty?.property_type === "Warehouse") {
          return CommercialSellWareHouse;
        }
      } else if (
        selectedProperty?.property_available_for === "Rent" ||
        selectedProperty?.property_available_for === "Lease"
      ) {
        if (selectedProperty?.property_type === "Office Space") {
          return commercial_rent_officeSpace;
        } else if (selectedProperty?.property_type === "Industrial Space") {
          return commercial_rent_industrialSpace;
        } else if (
          selectedProperty?.property_type === "Retail Shop" ||
          selectedProperty?.property_type === "Showroom"
        ) {
          return commercial_rent_retailShop;
        } else if (selectedProperty?.property_type === "Warehouse") {
          return commercial_rent_warehouse;
        }
      }
    }
    return [];
  };

  const details = getDetails();

  return (
    <div
      className={`${
        reportDetails ? "opacity-100" : "opacity-0"
      } transition-opacity flex flex-col gap-2`}
    >
      <div className="text-xs text-[#404040] px-5 py-3 bg-[#E4E0EE] font-bold rounded-[8px]">
        Report Reason :{" "}
        <span className="text-[#202020]"> {reportDetails?.Report_issuse}</span>
      </div>
      <div className="text-xs px-5 py-3 bg-white rounded-[8px]">
        <h2 className="font-bold text-[#202020] pb-4 border-b-[0.5px] border-b-[#8E8E8E]">
          Reported Property Listed By
        </h2>
        <div className="flex items-center justify-between py-4">
          <div className="flex items-center gap-2">
            <img
              className="w-[45px] h-[45px] rounded-full"
              src={
                selectedProperty?.tbl_user_reg?.company_logo
                  ? selectedProperty?.tbl_user_reg?.company_logo
                  : userProfile
              }
              alt="user-profile"
            />
            <div className="flex flex-col">
              <h4 className="text-sm font-semibold">
                {selectedProperty?.tbl_user_reg?.name}
              </h4>
              <p>{selectedProperty?.tbl_user_reg?.category}</p>
              <p>{selectedProperty?.tbl_user_reg?.company_name}</p>
            </div>
          </div>

          <div className="w-[0.3px] bg-[#8E8E8E] h-[50px]"></div>
          <div>
            <div className="flex flex-col gap-1">
              <p className=" font-semiBold">
                {selectedProperty?.tbl_user_reg?.email}
              </p>
              <p className="text-sm font-semibold text-[#202020]">
                {selectedProperty?.tbl_user_reg?.mobile_no}
              </p>
            </div>
          </div>
        </div>
        <div className="text-[#404040] text-xs w-full">
          <table className="w-full">
            <tbody className="">
              <tr>
                <td>Property ID</td>
                <td>:</td>
                <td className="font-bold px-8">
                  {selectedProperty?.propertyID}
                </td>
              </tr>
              <tr>
                <td>Date Posted</td>
                <td>:</td>
                <td className="px-8">{selectedProperty?.createdAt}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="text-xs p-5 bg-white rounded-[8px]">
        <h2 className="font-bold text-[#404040] pb-4 border-b-[0.5px] border-b-[#8E8E8E]">
          Reported Property Details :{" "}
          <span className="text-[#202020]">
            {selectedProperty?.building_name}
          </span>
        </h2>
        <div className={` text-xs ${showDetails ? "block" : "hidden"}`}>
          <Details details={details} />
        </div>
      </div>
    </div>
  );
};

export default ReportDetails;
