import React, { useContext } from "react";
import { VattaraPropsContext } from "../../../context/VattaraPropsContext";
import { IoMdArrowDropdown, IoMdRefresh } from "react-icons/io";
import Calendar from "react-calendar";
import { IoSearchSharp } from "react-icons/io5";

const Filters = () => {
  const {
    showDropDown,
    setShowDropDown,
    handleSearchInput,
    handleDateChange,
    handleDateFilter,
    selectedDateForFilter,
    handleReset,
  } = useContext(VattaraPropsContext);
  return (
    <div className="flex gap-4 mt-2 text-sm">
      <div className="flex relative items-center gap-4 my-2 text-xs">
        <IoMdRefresh
          onClick={handleReset}
            className="refresh-icon text-3xl cursor-pointer bg-white rounded-md p-1 hover:text-[#02a7e4] transition-all"
        />
        <div
          onClick={() =>
            showDropDown === "DateDropDown"
              ? setShowDropDown("")
              : setShowDropDown("DateDropDown")
          }
          className="cursor-pointer  bg-[#FFFFFF] w-[170px] px-2 py-2 rounded-[8px] shadow-sm flex items-center justify-between"
        >
          {`Posted Date ${
            selectedDateForFilter !== null || undefined || "" ? " - " : ""
          } ${selectedDateForFilter !== null ? selectedDateForFilter : ""}`}
          <span className="text-lg">
            <IoMdArrowDropdown />
          </span>
        </div>

        <div
          className={`absolute ${
            showDropDown === "DateDropDown" ? "block" : "hidden"
          } left-12 top-10 w-[250px] z-10 bg-white rounded-[8px] shadow-md transition-all duration-500`}
        >
          <Calendar onChange={(e) => handleDateChange(e)} />
          <div className="flex justify-end items-center px-4 py-2 gap-4 text-[#02a7e4] font-medium">
            <button
              onClick={() => setShowDropDown("")}
              className="hover:font-bold"
            >
              Cancel
            </button>
            <button onClick={handleDateFilter} className="hover:font-bold">
              Ok
            </button>
          </div>
        </div>
        <div className="bg-[#FFFFFF] rounded-[8px] shadow-sm flex items-center text-[#84919A]">
          <div className="text-xs  flex items-center gap-2 px-2">
            <span className="text-lg">
              <IoSearchSharp />
            </span>
            <input
              onChange={(e) => handleSearchInput(e)}
              className="p-2 w-[400px] focus:outline-none"
              type="search"
              placeholder="Search by prop ID, prop type or prop district"
            />
          </div>
          {/* <button
            onClick={handleSearch}
            className="px-4 py-1 mr-2 border-[1px] border-[#DDE2E4] rounded-[6px] text-[#252C32]"
          >
            Search
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Filters;
