import React, { useContext, useEffect } from "react";
import VattaraProperties from "./components/VattaraProperties";
import VerifiedProperties from "./components/VerifiedProperties";
import socket from "../../utils/socket";
import { VattaraPropsContext } from "../../context/VattaraPropsContext";

const VattaraVerifiedProps = () => {
  const { setProperties, setOriginalList, setPropCount } =
    useContext(VattaraPropsContext);
  const handleMergeData = (type, data) => {
    if (type === "Pending") {
      return;
    }
    setProperties((prev) => [data, ...prev]);
    setOriginalList((prev) => [data, ...prev]);
    setPropCount((prev) => prev + 1);
  };

  useEffect(() => {
    socket.on("postProperty", (data) => {
      const newData = data?.Data;
      handleMergeData(newData?.adminPost, newData);
    });

    return () => {
      socket.off("postProperty");
    };
  }, []);
  return (
    <div className="bg-[#F5F4F8] py-4 font-Roboto relative">
      <h3 className="font-Roboto font-bold text-[20px]">
        Manage Vattara Properties
      </h3>
      <p className="font-Inter font-bold text-[#7B7D8C] text-xs py-3">
        Home -{`>`} In-App Customization -{`>`} Vattara Properties
      </p>

      <VerifiedProperties />
      <VattaraProperties />
    </div>
  );
};

export default VattaraVerifiedProps;
