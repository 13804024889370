import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Menu,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import { Alert, Pagination } from "@material-ui/lab";
import EditComponent from "../../icons/EditComponent";
import RoleEditComponent from "../../icons/RoleEdit";
import KingComponent from "../../icons/King";
import MemberComponent from "../../icons/Membercomponent";
import {
  // selectAccessToken,
  selectUserPermissions,
} from "../../store/authSlice";
import { useSelector } from "react-redux";
import CustomAlertModal1 from "../../CustomAlert/CustomAlertModal1";
import axiosInstance from "../../Interceptor/axiosInstance";

const useStyles = makeStyles(() => ({
  menu: {
    position: "absolute",
    left: "45px",
  },
  table: {
    minWidth: 300,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: "700",
    fontFamily: "Roboto",
  },
  cell: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "700",
    padding: "5px 13px",
  },
  pagination: {
    "& .MuiPaginationItem-outlined": {
      border: "none",
    },
    "& .Mui-selected": {
      backgroundColor: "#02a7e4",
      color: "white",
    },
    "& .MuiPaginationItem-page:hover": {
      backgroundColor: "#02a7e4",
      color: "white",
    },
  },
  paper: {
    marginTop: "20px",
    padding: "0px",
    paddingInlineEnd: "20px",
    fontFamily: "Roboto",
    width: "79%",
    height: "auto",
    top: "722px",
    left: "357px",
    background: "#F5F4F8",
    border: "1px solid #F5F4F8",
    borderRadius: "8px",
    boxShadow: "none",
  },
}));

const DeleteModal = ({ close, onDelete, role }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="w-1/3 bg-white rounded-lg p-6 flex flex-col items-center justify-center">
        <p className="text-lg font-bold mb-4">
          Do you want to delete this role?
        </p>
        <div className="flex gap-4">
          <button
            onClick={close}
            className="py-2 px-4 bg-[#02a7e4] text-white rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={() => onDelete(role)}
            className="py-2 px-4 bg-gray-300  rounded-md"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const RolesManagement = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [roles, setRoles] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [alertModalVisible, setAlertModalVisible] = useState(false);
  const [open, setOpen] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const handleClose = (reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen("");
  }; // Add state for alert modal

  const userRolePermission = useSelector(selectUserPermissions);
  // const accessToken = useSelector(selectAccessToken);
  const navigate = useNavigate();
  const classes = useStyles();
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedRoles = filteredRoles.slice(startIndex, endIndex);

  useEffect(() => {
    axiosInstance
      .get("/api/admin/getRole")
      .then((response) => {
        setRoles(response.data.message);
        setFilteredRoles(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  }, []);

  const handleCreateRole = () => {
    axiosInstance
      .post("/api/admin/rolecreate", {
        Role: "New Role",
      })
      .then((response) => {
        const newRoles = response.data.message;
        setRoles(newRoles);
        setFilteredRoles(newRoles);
        setOpen("success");
        const newRole = response?.data?.message?.filter(
          (role) => role?.Roles === "New Role"
        );
        navigate("/edit-role", {
          state: { responseData: { Role: newRole[0].Roles } },
        });
      })
      .catch(() => {
        setOpen("error");
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleEditRole = (roleName) => {
    // Check user permissions for editing roles
    const canEditRole = userRolePermission.some(
      (permission) =>
        permission.Permission_label === "Role Management" &&
        permission.edit === "true"
    );

    // Always allow editing of roles, regardless of the role name
    if (canEditRole) {
      navigate("/edit-role", { state: { responseData: { Role: roleName } } });
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    const filtered = roles.filter((role) =>
      role.Roles.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRoles(filtered);
  };

  const handleMenuOpen = (event, role) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedRole(role);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedRole(null);
  };

  const handleDeleteRole = (role) => {
    if (role.Roles === "Member") {
      setDeleteModal(false);
      setAlertModalVisible(true); // Show alert modal if the role is "Member"
      return;
    }

    if (
      userRolePermission.some(
        (permission) =>
          permission.Permission_label === "Role Management" &&
          permission.delete === "true"
      )
    ) {
      axiosInstance
        .delete(`/api/admin/delete/role`, {
          data: { ExistingRole: role.Roles },
        })
        .then((response) => {
          const updatedRoles = roles.filter((r) => r.Roles !== role.Roles);
          setRoles(updatedRoles);
          setFilteredRoles(updatedRoles);
          handleCloseModal();
          setOpen("deleted");
        })
        .catch((error) => {
          console.error("There was an error deleting the role:", error);
          setOpen("!deleted");
        });
    }
  };

  const handleOpenModal = () => {
    setMenuAnchorEl(null);
    setDeleteModal(true);
  };

  const handleCloseModal = () => {
    setDeleteModal(false);
    setSelectedRole(null);
  };

  const closeAlertModal = () => {
    setAlertModalVisible(false);
  };

  return (
    <section className="relative">
      <div>
        <p className="mt-2 font-roboto text-xl font-bold">Role Management</p>
        <div className="flex flex-row">
          <input
            id="outlined-basic"
            type="text"
            placeholder="Search by role"
            value={searchTerm}
            onChange={handleSearch}
            onFocus={(e) => e.target.select()}
            className="mb-5 w-2/3 h-10 box-border mt-6 mr-5 font-roboto font-normal bg-gray-300 border border-gray-300 rounded-md pl-2 outline-none"
          />
          {userRolePermission.some(
            (permission) =>
              permission.Permission_label === "Role Management" &&
              permission.create === "true"
          ) && (
            <button
              onClick={handleCreateRole}
              className="mt-6 rounded-md bg-[#02a7e4] text-white py-2 px-3 w-36 h-10 border border-[#02a7e4] font-roboto text-base font-normal"
            >
              Create Role
            </button>
          )}
        </div>
        {deleteModal && (
          <DeleteModal
            close={handleCloseModal}
            onDelete={handleDeleteRole}
            role={selectedRole}
          />
        )}

        <TableContainer
          style={{ background: "white", height: "550px", overflowY: "auto" }}
          component={Paper}
          className={`custom-scrollbar ${classes.paper}`}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={`${classes.tableHeader} border-b border-black relative left-10`}
                >
                  ROLES
                </TableCell>
                <TableCell
                  className={`${classes.tableHeader} border-b border-black relative left-10`}
                >
                  MEMBERS
                </TableCell>
                <TableCell
                  className={`${classes.tableHeader} border-b border-black `}
                ></TableCell>
                <TableCell
                  className={`${classes.tableHeader} border-b border-black `}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRoles.map((role, index) => (
                <TableRow key={index}>
                  <TableCell
                    className={`${classes.cell} relative left-16 bottom-3`}
                  >
                    <KingComponent className="mr-0 relative right-8 top-5  " />
                    <span>{role.Roles}</span>
                  </TableCell>
                  <TableCell className={`${classes.cell} relative left-20`}>
                    <span className="relative right-3 top-2">
                      {role.RolesMemberCount}
                    </span>{" "}
                    <MemberComponent className="relative bottom-3 left-2" />
                  </TableCell>

                  <TableCell className={classes.cell}>
                    {userRolePermission.some(
                      (permission) =>
                        permission.Permission_label === "Role Management" &&
                        permission.edit === "true" &&
                        role.Roles !== "Admin" &&
                        role.Roles !== "Member"
                    ) && (
                      <IconButton
                        onClick={() => handleEditRole(role.Roles)}
                        className="relative custom-lg:left-72 xl:left-[220px] lg:left-[90px] custom-xl:left-[350px] sm:left-[70px] "
                      >
                        <RoleEditComponent />
                      </IconButton>
                    )}
                  </TableCell>

                  <TableCell className={classes.cell}>
                    {userRolePermission.some(
                      (permission) =>
                        permission.Permission_label === "Role Management" &&
                        permission.delete === "true" &&
                        role.Roles !== "Admin" &&
                        role.Roles !== "Member"
                    ) && (
                      <>
                        <IconButton
                          onClick={(e) => handleMenuOpen(e, role)}
                          className="relative custom-lg:left-32 lg:left-[35px] xl:left-[90px] custom-xl:left-[150px] sm:left-[10px] xxl:left-[90px] custom-xxl:left-[80px]"
                        >
                          <EditComponent />
                        </IconButton>
                        <Menu
                          anchorEl={menuAnchorEl}
                          keepMounted
                          open={Boolean(menuAnchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            onClick={handleOpenModal}
                            className="text-red-500"
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <div className="flex items-center justify-center mt-5">
          <Pagination
            count={Math.ceil(roles.length / rowsPerPage)}
            variant="outlined"
            shape="rounded"
            component="div"
            page={page}
            onChange={handleChangePage}
            className={`${classes.pagination} relative right-20 text-white`}
          />
        </div> */}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleClose}
          severity={
            open === "success" || open === "deleted"
              ? "success"
              : open === "error" || open === "!deleted"
              ? "error"
              : ""
          }
          sx={{ width: "100%" }}
        >
          {open === "success" && `Role created successfully`}
          {open === "error" &&
            `A new role already exists. Please rename new role and try again.`}
          {open === "deleted" && `Role deleted successfully.`}
          {open === "!deleted" && `Error in deleting the role.`}
        </Alert>
      </Snackbar>
      <CustomAlertModal1 show={alertModalVisible} onClose={closeAlertModal} />
    </section>
  );
};

export default RolesManagement;
