import { useContext, useEffect, useRef } from "react";
import { PostPropertyContext } from "../../../context/postPropertyContext";
import socket from "../../../utils/socket";
import Shimmer from "../../Shimmer";
import SinglePropertyCard from "./SinglePropertyCard";

const PropertyListing = ({ setOpen }) => {
  const {
    properties,
    shimmerState,
    listCount,
    setProperties,
    setOriginalList,
    setFilteredData,
    setListCount,
    currentPropertyTypeTab,
    wholeData,
    setShowDetails,
    setWholeData,
    setSelectedProperty,
  } = useContext(PostPropertyContext);

  const currentPropertyTypeRef = useRef(currentPropertyTypeTab);

  useEffect(() => {
    currentPropertyTypeRef.current = currentPropertyTypeTab;
  }, [currentPropertyTypeTab]);

  const handleMergeData = (type, data) => {
    setOpen(type);
    if (type === "Approved") {
      return;
    }
    if (currentPropertyTypeRef.current === "Pending") {
      setProperties((prev) => [data, ...prev]);
      setOriginalList((prev) => [data, ...prev]);
      setFilteredData((prev) => [data, ...prev]);
      setWholeData((prev) => [data, ...prev]);
      setListCount((prev) => prev + 1);
      setShowDetails(false);
      setSelectedProperty(null);
    }
  };

  useEffect(() => {
    socket.on("postProperty", (data) => {
      const newData = data?.Data;
      handleMergeData(newData?.adminPost, newData);
    });

    socket.on("PropertyApproval", (data) => {
      const newData = data?.Data;
      if (
        newData?.post_approval === currentPropertyTypeRef.current ||
        currentPropertyTypeRef.current === "All Properties"
      ) {
        if (wholeData) {
          const updatedProperty = wholeData?.find(
            (prop) => prop?.post_id === newData?.post_id
          );
          if (updatedProperty) {
            const updatedPropertyWithStatus = {
              ...updatedProperty,
              post_approval: newData?.post_approval,
            };
            setProperties((prev) => [updatedPropertyWithStatus, ...prev]);
            setOriginalList((prev) => [updatedPropertyWithStatus, ...prev]);
            setFilteredData((prev) => [updatedPropertyWithStatus, ...prev]);
            setWholeData((prev) => [updatedPropertyWithStatus, ...prev]);
            setListCount((prev) => prev + 1);
            setShowDetails(false);
            setSelectedProperty(null);
          }
        }
      } else {
        setProperties((prev) =>
          prev?.filter((prop) => prop?.post_id != newData?.post_id)
        );
        setOriginalList((prev) =>
          prev?.filter((prop) => prop?.post_id != newData?.post_id)
        );
        setFilteredData((prev) =>
          prev?.filter((prop) => prop?.post_id != newData?.post_id)
        );
        setListCount((prev) => prev - 1);
        setShowDetails(false);
        setSelectedProperty(null);
      }
    });

    return () => {
      socket.off("postProperty");
      socket.off("PropertyApproval");
    };
  }, []);

  return (
    <>
      <div className="px-2 flex flex-col gap-2">
        {shimmerState !== true && (
          <h4 className="font-medium font-Inter text-[15px] px-2 pb-2 underline">
            {listCount} Properties Listed
          </h4>
        )}

        {shimmerState === true ? (
          <Shimmer />
        ) : (
          properties?.map((property, i) => (
            <SinglePropertyCard
              key={property?.propertyID}
              index={i}
              property={property}
            />
          ))
        )}
      </div>
    </>
  );
};

export default PropertyListing;
