import { createContext, useEffect, useState } from "react";

const ServiceListingContext = createContext();

const ServiceListingProvider = ({ children }) => {
  const [servicesList, setServicesList] = useState();
  const [currentServiceTab, setCurrentServiceTab] = useState("Pending");
  const [originalList, setOriginalList] = useState();
  const [showDropDown, setShowDropDown] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedDateForFilter, setSelectedDateForFilter] = useState(null);
  const [userAndServiceDetail, setUserAndServiceDetail] = useState();
  const [shimmerState, setShimmerState] = useState(false);
  const [listcount, setListcount] = useState(0);
  const [categorySelected, setCategorySelected] = useState(null);
  const [filteredData, setFilteredData] = useState();
  const [wholeData, setWholeData] = useState();

  const handleTabChange = () => {
    setUserAndServiceDetail();
  };

  const fetchData = async () => {
    setShimmerState(true);
    const response = await fetch(
      "https://vattaram.in/services/getAll/services"
    );

    const data = await response.json();

    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split("-");
      return new Date(`20${year}-${month}-${day}`);
    };

    let servicesList = [];
    if (currentServiceTab === "Pending") {
      servicesList = data.message.filter(
        (item) => item?.list_approval === "Pending"
      );
    } else if (currentServiceTab === "Verified") {
      servicesList = data.message.filter(
        (item) => item?.list_approval === "Verified"
      );
    } else if (currentServiceTab === "Rejected") {
      servicesList = data.message.filter(
        (item) => item?.list_approval === "Rejected"
      );
    }
    servicesList.sort((a, b) => {
      const dateA = parseDate(a.createdAt);
      const dateB = parseDate(b.createdAt);
      return dateB - dateA; // Sorting in descending order
    });
    setServicesList(servicesList);
    setOriginalList(servicesList);
    setFilteredData(servicesList);
    setWholeData(servicesList);
    setListcount(servicesList.length);

    setShimmerState(false);
  };

  useEffect(() => {
    fetchData();
    handleTabChange();
  }, [currentServiceTab]);

  const handleSearchInput = (e) => {
    const searchText = e.target.value;
    const filteredList = originalList.filter(
      (item) =>
        item?.tbl_user_reg?.name
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item?.mobile_no.toString().includes(searchText) ||
        item?.service_category
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item?.business_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setServicesList(filteredList);
    setListcount(filteredList.length);
  };

  const handleReset = () => {
    setServicesList(originalList);
    setListcount(originalList.length);
    setSearchText("");
    setSelectedDateForFilter(null);
    setCategorySelected(null);
  };

  // const handleSearch = () => {
  //   const filteredList = originalList.filter(
  //     (item) =>
  //       item?.tbl_user_reg?.name
  //         .toLowerCase()
  //         .includes(searchText.toLowerCase()) ||
  //       item?.mobile_no.toString().includes(searchText) ||
  //       item?.service_category
  //         .toLowerCase()
  //         .includes(searchText.toLowerCase()) ||
  //       item?.business_name.toLowerCase().includes(searchText.toLowerCase())
  //   );
  //   setServicesList(filteredList);
  //   setListcount(filteredList.length);
  // };

  const handleDateChange = (e) => {
    const date = new Date(e);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    setSelectedDateForFilter(formattedDate);
  };
  const handleDateFilter = () => {
    const filteredData = originalList.filter((data) =>
      data.createdAt.includes(selectedDateForFilter)
    );
    setServicesList(filteredData);
    setListcount(filteredData.length);
    setFilteredData(filteredData);
    setShowDropDown("");
  };

  const handleCategoryTypeFilter = (category) => {
    setCategorySelected(category);
    const filteredDataByCategory = filteredData.filter((data) =>
      category.toLowerCase().includes(data.service_category.toLowerCase())
    );
    setServicesList(filteredDataByCategory);
    setListcount(filteredDataByCategory.length);
    setShowDropDown("");
  };

  return (
    <ServiceListingContext.Provider
      value={{
        servicesList,
        setServicesList,
        originalList,
        setOriginalList,
        showDropDown,
        setShowDropDown,
        searchText,
        setSearchText,
        handleSearchInput,
        selectedDateForFilter,
        setSelectedDateForFilter,
        handleDateChange,
        handleDateFilter,
        currentServiceTab,
        setCurrentServiceTab,
        userAndServiceDetail,
        setUserAndServiceDetail,
        fetchData,
        handleCategoryTypeFilter,
        shimmerState,
        setShimmerState,
        listcount,
        setListcount,
        handleReset,
        categorySelected,
        setOriginalList,
        setFilteredData,
        setListcount,
        wholeData,
        setWholeData,
      }}
    >
      {children}
    </ServiceListingContext.Provider>
  );
};

export { ServiceListingContext, ServiceListingProvider };
