import { useContext, useEffect, useRef, useState } from "react";
import dummyImg from "../../../assets/dummy.webp";
import { AdContext } from "../../../context/AdContext";
import axiosInstance from "../../../Interceptor/axiosInstance";
import { IoIosMore } from "react-icons/io";
import { useSelector } from "react-redux";
import { selectUserPermissions } from "../../../store/authSlice";
// import { IoMdRefresh } from "react-icons/io";

const AdListing = () => {
  const { adList, setAdList, viewTab, setCurrentAdUpload } =
    useContext(AdContext);
  const [showEditModal, setShowEditModal] = useState();
  const [deleteAccess, setDeleteAccess] = useState();
  const [editAccess, setEditAccess] = useState();
  const optionsRef = useRef(null);
  const expectedPermission = "Ad Posting";
  const userRolePermission = useSelector(selectUserPermissions);

  useEffect(() => {
    const hasEditAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.edit === "true"
    );
    const hasDeleteAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.delete === "true"
    );

    setEditAccess(hasEditAccess);
    setDeleteAccess(hasDeleteAccess);
  }, [userRolePermission]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowEditModal(null);
      }
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    };
  }, []);

  const handleEdit = (item) => {
    setShowEditModal(false);
    setCurrentAdUpload({
      Title: item.Title,
      link: item.link,
      image: item.image,
      id: item.id,
      list_ads:
        viewTab === "Property List View Ads"
          ? "Property List View Ads"
          : "Property Contact View Ads",
    });
  };

  const handleDelete = async (item) => {
    try {
      const response = await axiosInstance.delete(
        `/api/ads/deleteAds/${item.id}`
      );
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const result = await response.json();
    } catch (error) {}
    setShowEditModal(false);
    const filtered = adList && adList.filter((ad) => ad !== item);
    setAdList(filtered);
  };

  return (
    <div className="mt-4 font-Roboto">
      <div className="w-full h-[1px] bg-[#404040]"></div>
      {/* <IoMdRefresh className="text-2xl cursor-pointer mt-2" /> */}
      <div className="mt-4 flex flex-wrap items-center justify-start gap-4">
        {adList &&
          adList.map((item, i) => (
            <div
              key={i}
              className="w-[400px] relative flex items-start gap-4 p-4 bg-white"
            >
              <img
                src={item?.image || dummyImg}
                alt=""
                className="w-[130px] h-[90px] object-cover"
              />
              <div className="max-w-[200px] flex flex-col gap-2">
                <h4 className="text-[13px] text-[#18181B] font-bold">
                  {item.Title}
                </h4>
                <span className="text-[#8E8E8E] text-[9px] break-all">
                  {item.link}
                </span>
              </div>
              <div
                onClick={
                  showEditModal === undefined
                    ? () => setShowEditModal(i)
                    : () => setShowEditModal()
                }
                className="absolute top-3 cursor-pointer right-4 text-2xl text-[#A1A1AA]"
              >
                <IoIosMore />
              </div>
              {showEditModal === i && (
                <div
                  ref={optionsRef}
                  className="absolute -right-11 z-10 top-7 text-sm bg-white rounded-lg px-6 py-3 shadow-md flex flex-col justify-center items-center gap-2"
                >
                  {editAccess !== undefined && editAccess ? (
                    <button
                      onClick={() => handleEdit(item)}
                      className="hover:underline "
                    >
                      Edit
                    </button>
                  ) : null}
                  {deleteAccess !== undefined && deleteAccess ? (
                    <button
                      onClick={
                        deleteAccess !== undefined && deleteAccess
                          ? () => handleDelete(item)
                          : null
                      }
                      className="hover:underline text-[#C91C1C]"
                    >
                      Delete
                    </button>
                  ) : null}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default AdListing;
